import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import "./voucher.css";
import { IconButton, Avatar } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer } from "react-toastify";
import custom_toast from "../alerts/custom_toast";
import Red_toast from "../alerts/red_toast";
import Spinner from "react-bootstrap/Spinner";
import Alert_before_delete from "../../Container/alertContainer";
import Voucherform from "./voucherform";
import UpdateVoucherform from "./updatevoucherform";
import ApartmentIcon from "@material-ui/icons/Apartment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";
import { Link } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import Select from "../alerts/select";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";

export default function SupplierVoucher(props) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const all_products = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [showmodel, setshowmodel] = useState(false);
  const [data, setdata] = useState("");
  const [showmodelupdate, setshowmodelupdate] = useState(false);
  const [all_customers, setall_customers] = useState([]);
  const [customer, setcustomer] = useState({ value: "all", label: "All" });
  const [showdate, setshowdate] = useState(false);
  const [callagain, setcallagain] = useState(false);
  const [start_date, setstart_date] = useState(
    addMonths(new Date(), -1).toISOString().substring(0, 10)
  );
  const [end_date, setend_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [date_range, setdate_range] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: "false",
    },
  ]);
  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");
  const [isloading, setisloading] = useState(false);
  const [loadagain, setloadagain] = useState(false);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "voucher_supplier" });
    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/parties/?branch_id=${selected_branch.id}&party_type=Supplier`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const make_option = json.map((item) => {
          return { value: item.id, label: item.name };
        });

        setall_customers(make_option);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, [selected_branch]);

  useEffect(() => {
    setisloading(true);
    dispatch({ type: "Set_table_history", data: [] });
    const fetch_payments = async () => {
      var url = `${route}/api/voucher/?branch_id=${selected_branch.id}&reference=PV&start_date=${start_date}&end_date=${end_date}`;
      if (
        date_range[0].endDate.getFullYear() -
          date_range[0].startDate.getFullYear() ===
        10
      ) {
        url = `${route}/api/voucher/?branch_id=${selected_branch.id}&reference=PV`;
      }
      if (customer.value !== "all") {
        url = `${url}&party_id=${customer.value}`;
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        dispatch({ type: "Set_table_history", data: json });
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
        setisloading(false);
      }
    };

    if (user) {
      fetch_payments();
    }
  }, [selected_branch, loadagain, callagain]);

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const handleconfirm = (row) => {
    dispatch({ type: "Delete_table_history", data: { id: row } });
    custom_toast("Delete");
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex">
        <IconButton
          className="border border-primary rounded me-2"
          onClick={() => {
            localStorage.setItem("data", JSON.stringify(row));

            window.open("/receipt/Payment", "_blank");
          }}
        >
          <PrintRoundedIcon className="m-1" color="primary" fontSize="medium" />
        </IconButton>
        <IconButton
          className="border border-danger rounded me-2"
          onClick={() => {
            setrow_id(row.id);
            seturl_to_delete(`${route}/api/voucher/${row.id}/`);
            setdelete_user(true);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="medium" />
        </IconButton>

        <IconButton
          className=""
          style={{ border: "1px solid #003049", borderRadius: "5px" }}
          onClick={() => {
            setdata(row);
            setshowmodelupdate(true);
          }}
        >
          <EditOutlinedIcon
            className="m-1"
            style={{ color: "#003049" }}
            fontSize="medium"
          />
        </IconButton>
      </span>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      headerFormatter: headerstyle,
      csvExport: false,
    },

    { dataField: "date", text: t("date"), headerFormatter: headerstyle },
    {
      dataField: "voucher_number",
      text: t("voucherno"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "party_name",
      text: t("side_bar_suppliers"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "bank",
      text: t("bank"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "cheque_number",
      text: t("chequeno"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "cash",
      text: t("cash"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "amount",
      text: t("amount"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "description",
      text: t("description"),
      sort: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "action",
      text: t("action"),
      formatter: Action,
      headerFormatter: headerstyle,
      csvExport: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: all_products.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function reverseText(text) {
    return text.split(" ").reverse().join(" ");
  }

  const makepdf = () => {
    const body = all_products.map((item, index) => {
      return [
        index + 1,
        item.date,
        item.party_name,
        item.bank,
        item.cheque_number,
        item.cash,
        item.amount,
        item.description,
      ];
    });
    body.splice(0, 0, [
      "#",
      "Date",
      "Suppplier",
      "Bank",
      "Cheque No",
      "Cash",
      "Amount",
      "Description",
    ]);

    const documentDefinition = {
      content: [
        { text: "Payment Vouchr", style: "header" },
        { text: `Project Name: ${selected_branch.name}`, style: "body" },
        {
          text: `Date : ${date_range[0].startDate
            .toLocaleString("en-GB")
            .substring(0, 10)} - ${date_range[0].endDate
            .toLocaleString("en-GB")
            .substring(0, 10)}`,
          style: "body",
        },
        {
          canvas: [
            { type: "line", x1: 0, y1: 10, x2: 760, y2: 10, lineWidth: 1 },
          ],
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [30, "*", "*", "*", 80, 80, "*", "*"],
            body: body,
          },
          style: "tableStyle",
        },
      ],
      defaultStyle: {
        font: "ArabicFont",
      },
      styles: {
        tableStyle: {
          width: "100%", // Set the width of the table to 100%
          marginTop: 20,
          font: "ArabicFont",
        },

        header: {
          fontSize: 22,
          bold: true,
          alignment: "center",
        },
        body: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          marginBottom: 10,
        },
      },

      pageOrientation: "landscape",
    };
    return documentDefinition;
  };

  const download = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).download("paymentsvoucher.pdf");
  };

  const print = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).print();
  };

  const rowstyle = { height: "10px" };

  useEffect(() => {
    setcallagain(!callagain);
  }, [customer, date_range]);

  const handleSelect = (item) => {
    const get_date = item.selection;
    setdate_range([item.selection]);
    setstart_date(get_date.startDate.toISOString().substring(0, 10));
    setend_date(get_date.endDate.toISOString().substring(0, 10));
    if (
      get_date.startDate.toISOString().substring(0, 10) !==
      get_date.endDate.toISOString().substring(0, 10)
    ) {
      setshow(!show);
    }
  };

  const handleselectiochange = (e) => {
    setshow(!show);
    setTarget(e.target);
  };

  const trigger_loadagain = () => {
    setloadagain(!loadagain);
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <>
      {current_user?.permissions?.includes("view_voucher_supplier") ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("supplier_voucher")}
          </h1>
          <div className="card">
            <div className="card-header bg-white d-flex justify-content-between align-items-center">
              <Link to="/voucher_customer">
                {t("switch_to_customervoucher")}
              </Link>
              <div>
                <Button
                  type="button"
                  className="mb-2"
                  variant="outline-success"
                  onClick={() => setshowmodel(!showmodel)}
                >
                  {t("add_voucher")}
                </Button>
              </div>
            </div>

            <div className="card-body pt-0">
              <ToolkitProvider
                keyField="id"
                data={all_products}
                columns={columns}
                search
                exportCSV
              >
                {(props) => (
                  <div>
                    <div className="col-sm-4 mt-3 d-flex">
                      <div className="col-sm-6 me-3">
                        {date_range[0].endDate.getFullYear() -
                          date_range[0].startDate.getFullYear() ===
                        10 ? (
                          <TextField
                            ref={ref}
                            type="button"
                            className="form-control  mb-3"
                            label={t("date")}
                            value="From Start"
                            onClick={handleselectiochange}
                            size="small"
                          />
                        ) : (
                          <TextField
                            ref={ref}
                            type="button"
                            className="form-control  mb-3 "
                            label={t("date")}
                            value={`${date_range[0].startDate
                              .toLocaleString("en-GB")
                              .substring(0, 10)} - ${date_range[0].endDate
                              .toLocaleString("en-GB")
                              .substring(0, 10)}`}
                            onClick={handleselectiochange}
                            size="small"
                          />
                        )}
                        <Overlay
                          show={show}
                          target={target}
                          placement="bottom-start"
                          container={ref}
                        >
                          <Popover id="popover-contained" className="pop_over">
                            <Popover.Body>
                              <div>
                                <DateRangePicker
                                  onChange={handleSelect}
                                  showSelectionPreview={true}
                                  showCalendarPreview={false}
                                  dragSelectionEnabled={true}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  ranges={date_range}
                                  direction="horizontal"
                                  preventSnapRefocus={true}
                                  calendarFocus="backwards"
                                  staticRanges={[
                                    ...defaultStaticRanges,
                                    {
                                      label: "Last Year",
                                      range: () => ({
                                        startDate: startOfYear(
                                          addYears(new Date(), -1)
                                        ),
                                        endDate: endOfYear(
                                          addYears(new Date(), -1)
                                        ),
                                      }),
                                      isSelected(range) {
                                        const definedRange = this.range();
                                        return (
                                          isSameDay(
                                            range.startDate,
                                            definedRange.startDate
                                          ) &&
                                          isSameDay(
                                            range.endDate,
                                            definedRange.endDate
                                          )
                                        );
                                      },
                                    },
                                    {
                                      label: "This Year",
                                      range: () => ({
                                        startDate: startOfYear(new Date()),
                                        endDate: endOfDay(new Date()),
                                      }),
                                      isSelected(range) {
                                        const definedRange = this.range();
                                        return (
                                          isSameDay(
                                            range.startDate,
                                            definedRange.startDate
                                          ) &&
                                          isSameDay(
                                            range.endDate,
                                            definedRange.endDate
                                          )
                                        );
                                      },
                                    },
                                    {
                                      label: "From Start",
                                      range: () => ({
                                        startDate: startOfYear(
                                          addYears(new Date(), -10)
                                        ),
                                        endDate: endOfDay(new Date()),
                                      }),
                                      isSelected(range) {
                                        const definedRange = this.range();
                                        return (
                                          isSameDay(
                                            range.startDate,
                                            definedRange.startDate
                                          ) &&
                                          isSameDay(
                                            range.endDate,
                                            definedRange.endDate
                                          )
                                        );
                                      },
                                    },
                                  ]}
                                />
                              </div>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </div>

                      <div className="form-control  selector ">
                        <Select
                          options={[
                            { value: "all", label: "All" },
                            ...all_customers,
                          ]}
                          placeholder={t("side_bar_suppliers")}
                          value={customer}
                          funct={(e) => {
                            setcustomer(e);
                          }}
                        ></Select>
                      </div>
                    </div>
                    <div className="d-sm-flex justify-content-between align-items-center mt-2">
                      <div>
                        <ExportCSVButton
                          {...props.csvProps}
                          className="csvbutton  border bg-secondary text-light me-2 mb-2"
                        >
                          Export CSV
                        </ExportCSVButton>
                        <Button
                          type="button"
                          className="p-1 ps-3 pe-3 me-2 mb-2"
                          variant="outline-primary"
                          onClick={download}
                        >
                          <PictureAsPdfIcon /> PDF
                        </Button>
                        <Button
                          type="button"
                          className="p-1 ps-3 pe-3 mb-2"
                          variant="outline-success"
                          onClick={print}
                        >
                          <PrintIcon /> Print
                        </Button>
                      </div>
                      <SearchBar {...props.searchProps} />
                    </div>
                    {isloading && (
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    )}

                    <hr />
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      rowStyle={rowstyle}
                      striped
                      bootstrap4
                      condensed
                      wrapperClasses="table-responsive"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>

          {showmodel && (
            <Voucherform
              show={showmodel}
              onHide={() => setshowmodel(false)}
              user={user}
              route={`${route}/api/voucher/`}
              callback={dispatch}
              selected_branch={selected_branch}
              customers={all_customers}
              text={t("side_bar_suppliers")}
              current_user={current_user}
              setloadagain={trigger_loadagain}
              type={"supplier"}
            />
          )}
          {showmodelupdate && (
            <UpdateVoucherform
              show={showmodelupdate}
              onHide={() => setshowmodelupdate(false)}
              data={data}
              user={user}
              route={`${route}/api/voucher/`}
              fun={custom_toast}
              callback={dispatch}
              selected_branch={selected_branch}
              customers={all_customers}
              text={t("side_bar_suppliers")}
              setloadagain={trigger_loadagain}
              type={"supplier"}
            />
          )}
          {delete_user && (
            <Alert_before_delete
              show={delete_user}
              onHide={() => setdelete_user(false)}
              url={url_to_delete}
              dis_fun={handleconfirm}
              row_id={row_id}
            />
          )}
          <ToastContainer
            autoClose={1000}
            hideProgressBar={true}
            theme="dark"
          />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}
