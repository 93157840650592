import React, { useState, useEffect, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import "./report.css";
import { useReactToPrint } from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer } from "react-toastify";
import Red_toast from "../alerts/red_toast";
import Spinner from "react-bootstrap/Spinner";
import Select from "../alerts/select";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";
import { useTranslation } from "react-i18next";

export default function Summary_Report(props) {
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const { t } = useTranslation();
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const [show_company, setshow_company] = useState(false);
  const dispatch = props.Settable_history;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [details, setdetails] = useState([]);

  const [all_supplier, setall_supplier] = useState([]);
  const [supplier, setsupplier] = useState({ value: "all", label: "All" });
  const [all_customers, setall_customers] = useState([]);
  const [customer, setcustomer] = useState({ value: "all", label: "All" });
  const [all_saleman, setall_saleman] = useState([]);
  const [saleman, setsaleman] = useState({ value: "all", label: "All" });
  const [all_payment_type, setall_payment_type] = useState([]);
  const [payment_type, setpayment_type] = useState({
    value: "all",
    label: "All",
  });
  const [company, setcompany] = useState("");

  const [customer_label, setcustomer_label] = useState("All");
  const [supplier_label, setsupplier_label] = useState("All");
  const [saleman_label, setsaleman_label] = useState("All");
  const [payment_label, setpayment_label] = useState("All");

  const [start_date, setstart_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [end_date, setend_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [details_purchase, setdetails_purchase] = useState([]);
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [date_range, setdate_range] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: "false",
    },
  ]);

  const [isloading, setisloading] = useState(false);
  const [showreport, setshowreport] = useState(false);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "summary_report" });
    const fetchCompany = async () => {
      const response = await fetch(
        `${route}/api/branch/${selected_branch.id}/`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setcompany(json);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    const fetchSupplier = async () => {
      const response = await fetch(
        `${route}/api/parties/?branch_id=${selected_branch.id}&party_type=Supplier`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const make_option = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        make_option.splice(0, 0, { value: "all", label: "All" });
        setall_supplier(make_option);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/parties/?branch_id=${selected_branch.id}&party_type=Customer`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const make_option = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        make_option.splice(0, 0, { value: "all", label: "All" });
        setall_customers(make_option);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    const fetchSaleman = async () => {
      const response = await fetch(
        `${route}/api/salespersons/?branch_id=${selected_branch.id}&type=sale_person`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const make_option = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        make_option.splice(0, 0, { value: "all", label: "All" });
        setall_saleman(make_option);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    const fetchPayment = async () => {
      const response = await fetch(
        `${route}/api/payment-types/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const make_option = json.map((item) => {
          return { value: item.name, label: item.name };
        });
        make_option.splice(0, 0, { value: "all", label: "All" });
        setall_payment_type(make_option);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchWorkouts();
      fetchSupplier();
      fetchCompany();
      fetchSaleman();
      fetchPayment();
    }
  }, [selected_branch]);

  const headerstyleforone = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const headerstyle = (column, colIndex) => {
    const englishHeader = column.text.english;
    const arabicHeader = column.text.arabic;

    return (
      <div>
        <div>{englishHeader}</div>
        <div>{arabicHeader}</div>
      </div>
    );
  };

  const fix_formatter = (cell, row) => {
    return <div>{parseFloat(cell).toFixed(2)}</div>;
  };

  const columns = [
    {
      dataField: "id",
      text: { english: "#", arabic: " الرقم سري" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "date",
      text: { english: "Date", arabic: "تاريخ" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "invoice",
      text: { english: "Inoice No.", arabic: "رقم الفاتورة" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "customer",
      text: { english: "Customer", arabic: "اسم العميل" },
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "customer_vat_number",
      text: { english: "Cust. VAT no", arabic: "رقم الضربية العميل" },
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "total_quantity",
      text: { english: "Total Quantity", arabic: "الكمية الإجمالية" },
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "sub_total",
      text: { english: "Subtotal", arabic: "المجموع الفرعي" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "discount",
      text: { english: "Discount", arabic: "الخصم" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "vat_amount",
      text: { english: "VAT Amount", arabic: "ضريبة القيمة" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "total",
      text: { english: "Total", arabic: "المجموع" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "payment_type",
      text: { english: "Payment Type", arabic: "نوعية المشتريات" },
      sort: true,
      headerFormatter: headerstyle,
    },
  ];
  const columns_2 = [
    {
      dataField: "id",
      text: { english: "#", arabic: " الرقم سري" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "date",
      text: { english: "Date", arabic: "تاريخ" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "invoice",
      text: { english: "Inoice No.", arabic: "رقم الفاتورة" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "supplier",
      text: { english: "Supplier", arabic: "اسم المورد" },
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "supplier_vat_number",
      text: { english: "Supp. VAT no", arabic: "رقم الضربية المورد" },
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "total_quantity",
      text: { english: "Total Quantity", arabic: "الكمية الإجمالية" },
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "sub_total",
      text: { english: "Subtotal", arabic: "المجموع الفرعي" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "discount",
      text: { english: "Discount", arabic: "الخصم" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "vat_amount",
      text: { english: "VAT Amount", arabic: "ضريبة القيمة" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "total",
      text: { english: "Total", arabic: "المجموع" },
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "payment_type",
      text: { english: "Payment Type", arabic: "نوعية المشتريات" },
      sort: true,
      headerFormatter: headerstyle,
    },
  ];

  const rowstyle = { height: "10px" };

  const handleSelect = (item) => {
    const get_date = item.selection;
    setdate_range([item.selection]);
    setstart_date(get_date.startDate.toISOString().substring(0, 10));
    setend_date(get_date.endDate.toISOString().substring(0, 10));
    if (
      get_date.startDate.toISOString().substring(0, 10) !==
      get_date.endDate.toISOString().substring(0, 10)
    ) {
      setshow(!show);
    }
  };

  const handleselectiochange = (e) => {
    setshow(!show);
    setTarget(e.target);
  };

  const componentRef = useRef();

  useEffect(() => {
    if (show_company) {
      handleprint();
    }
  }, [show_company]);

  const print = (e) => {
    e.preventDefault();
    setshow_company(true);
  };

  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print_class",
    pageStyle: "@page { size: A4 landscape; }",
    onAfterPrint: () => {
      setshow_company(false);
    },
  });

  const handlegenerate = async (e) => {
    e.preventDefault();
    setisloading(true);
    setshowreport(true);
    var url = `${route}/api/reports/combined-vat/?branch_id=${selected_branch.id}&start_date=${start_date}&end_date=${end_date}`;

    if (
      date_range[0].endDate.getFullYear() -
        date_range[0].startDate.getFullYear() ===
      10
    ) {
      url = `${route}/api/reports/combined-vat/?branch_id=${selected_branch.id}`;
    }
    if (supplier.value !== "all") {
      url = `${url}&supplier_id=${supplier.value}`;
    }
    if (customer.value !== "all") {
      url = `${url}&customer_id=${customer.value}`;
    }
    if (saleman.value !== "all") {
      url = `${url}&sale_person_id=${saleman.value}`;
    }
    if (payment_type.value !== "all") {
      url = `${url}&payment_type=${payment_type.value}`;
    }

    const response = await fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.access}` },
    });
    const json = await response.json();

    if (response.ok) {
      setisloading(false);
      const optimize = json.sale_data.map((item, index) => {
        item["id"] = index + 1;
        return item;
      });

      optimize.push({
        date: "",
        invoice: "",
        customer_vat_number: "",
        customer: "",
        id: "",
        total_quantity: "Total",
        sub_total: json.sale_sums.total_sub_total,
        vat_amount: json.sale_sums.total_vat_amount,
        discount: json.sale_sums.total_discount,
        total: json.sale_sums.total_total,
        payment_type: "",
      });
      setdetails(optimize);

      const optimize_2 = json.purchase_data.map((item, index) => {
        item["id"] = index + 1;
        return item;
      });

      optimize_2.push({
        date: "",
        invoice: "",
        customer_vat_number: "",
        customer: "",
        id: "",
        total_quantity: "Total",
        sub_total: json.purchase_sums.total_sub_total,
        vat_amount: json.purchase_sums.total_vat_amount,
        discount: json.purchase_sums.total_discount,
        total: json.purchase_sums.total_total,
        payment_type: "",
      });
      setdetails_purchase(optimize_2);
    }
    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }
  };

  const handlecustomer = (e) => {
    setcustomer(e);
    const obj = all_customers.filter((item) => {
      return item.value === e.value;
    });
    setcustomer_label(obj[0].label);
  };

  const handlesupplier = (e) => {
    setsupplier(e);
    const obj = all_supplier.filter((item) => {
      return item.value === e.value;
    });
    setsupplier_label(obj[0].label);
  };

  const handlesaleman = (e) => {
    setsaleman(e);
    const obj = all_saleman.filter((item) => {
      return item.value === e.value;
    });
    setsaleman_label(obj[0].label);
  };

  const handlepayment = (e) => {
    setpayment_type(e);
    const obj = all_payment_type.filter((item) => {
      return item.value === e.value;
    });
    setpayment_label(obj[0].label);
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (rowIndex === details.length - 1) {
      classes = "last_row";
    }

    return classes;
  };

  return (
    <>
      {current_user?.permissions?.includes("view_summary_report") ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("side_bar_summary")}
          </h1>
          <div className="card">
            <div className="card-body pt-0">
              <ToolkitProvider
                keyField="id"
                data={details}
                columns={columns}
                search
                exportCSV
              >
                {(props) => (
                  <div>
                    <div className=" col-sm-12 d-sm-flex justify-content-start align-items-start mt-3">
                      <div className="col-sm-2  me-3">
                        {date_range[0].endDate.getFullYear() -
                          date_range[0].startDate.getFullYear() ===
                        10 ? (
                          <TextField
                            ref={ref}
                            type="button"
                            className="form-control  mb-3"
                            label={t("date")}
                            value="From Start"
                            onClick={handleselectiochange}
                            size="small"
                          />
                        ) : (
                          <TextField
                            ref={ref}
                            type="button"
                            className="form-control  mb-3 "
                            label={t("date")}
                            value={`${date_range[0].startDate
                              .toLocaleString("en-GB")
                              .substring(0, 10)} - ${date_range[0].endDate
                              .toLocaleString("en-GB")
                              .substring(0, 10)}`}
                            onClick={handleselectiochange}
                            size="small"
                          />
                        )}
                        <Overlay
                          show={show}
                          target={target}
                          placement="bottom-start"
                          container={ref}
                        >
                          <Popover id="popover-contained" className="pop_over">
                            <Popover.Body>
                              <div>
                                <DateRangePicker
                                  onChange={handleSelect}
                                  showSelectionPreview={true}
                                  showCalendarPreview={false}
                                  dragSelectionEnabled={true}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  ranges={date_range}
                                  direction="horizontal"
                                  preventSnapRefocus={true}
                                  calendarFocus="backwards"
                                  staticRanges={[
                                    ...defaultStaticRanges,
                                    {
                                      label: "Last Year",
                                      range: () => ({
                                        startDate: startOfYear(
                                          addYears(new Date(), -1)
                                        ),
                                        endDate: endOfYear(
                                          addYears(new Date(), -1)
                                        ),
                                      }),
                                      isSelected(range) {
                                        const definedRange = this.range();
                                        return (
                                          isSameDay(
                                            range.startDate,
                                            definedRange.startDate
                                          ) &&
                                          isSameDay(
                                            range.endDate,
                                            definedRange.endDate
                                          )
                                        );
                                      },
                                    },
                                    {
                                      label: "This Year",
                                      range: () => ({
                                        startDate: startOfYear(new Date()),
                                        endDate: endOfDay(new Date()),
                                      }),
                                      isSelected(range) {
                                        const definedRange = this.range();
                                        return (
                                          isSameDay(
                                            range.startDate,
                                            definedRange.startDate
                                          ) &&
                                          isSameDay(
                                            range.endDate,
                                            definedRange.endDate
                                          )
                                        );
                                      },
                                    },
                                    {
                                      label: "From Start",
                                      range: () => ({
                                        startDate: startOfYear(
                                          addYears(new Date(), -10)
                                        ),
                                        endDate: endOfDay(new Date()),
                                      }),
                                      isSelected(range) {
                                        const definedRange = this.range();
                                        return (
                                          isSameDay(
                                            range.startDate,
                                            definedRange.startDate
                                          ) &&
                                          isSameDay(
                                            range.endDate,
                                            definedRange.endDate
                                          )
                                        );
                                      },
                                    },
                                  ]}
                                />
                              </div>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </div>

                      <div className="me-3 col-sm-2   selector  mb-3">
                        <Select
                          options={all_supplier}
                          placeholder={t("side_bar_suppliers")}
                          value={supplier}
                          funct={handlesupplier}
                        ></Select>
                      </div>

                      <div className=" me-3 col-sm-2   selector  mb-3">
                        <Select
                          options={all_customers}
                          placeholder={t("side_bar_customers")}
                          value={customer}
                          funct={handlecustomer}
                        ></Select>
                      </div>

                      <div className=" me-3 col-sm-2   selector  mb-3">
                        <Select
                          options={all_saleman}
                          placeholder={t("side_bar_salepersons")}
                          value={saleman}
                          funct={handlesaleman}
                        ></Select>
                      </div>

                      <div className=" me-3 col-sm-2   selector  mb-3">
                        <Select
                          options={all_payment_type}
                          placeholder={t("side_bar_paymnettype")}
                          value={payment_type}
                          funct={handlepayment}
                        ></Select>
                      </div>

                      <Button
                        type="button"
                        className="mb-3 me-3"
                        variant="outline-success"
                        size="small"
                        onClick={handlegenerate}
                      >
                        {t("generate")}
                      </Button>
                    </div>
                    {isloading && (
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    )}

                    {showreport && (
                      <>
                        <div className="d-sm-flex justify-content-between align-items-center mt-2">
                          <div>
                            <ExportCSVButton
                              {...props.csvProps}
                              className="csvbutton me-3 border bg-secondary text-light"
                            >
                              Export CSV
                            </ExportCSVButton>
                            <Button
                              type="button"
                              className="mt-3 mb-3 me-3"
                              variant="outline-success"
                              size="small"
                              onClick={print}
                            >
                              {t("print")}
                            </Button>
                          </div>
                          <SearchBar {...props.searchProps} />
                        </div>

                        <hr />
                        <div ref={componentRef}>
                          {show_company && (
                            <div>
                              <h2 className="text-center">
                                {company.name} / {company.arabic_name}
                              </h2>
                              <h3 className="text-center">
                                VAT No: {company.vat_number} /{" "}
                                {company.vat_number_arabic}
                              </h3>
                              <hr />
                              <h3 className="text-center">
                                Combine VAT Report
                              </h3>
                              <div className="p-3 d-flex justify-content-between">
                                <h6>
                                  <strong className="me-3">Date:</strong>
                                  {date_range[0].startDate
                                    .toLocaleString("en-GB")
                                    .substring(0, 10)}
                                  -
                                  {date_range[0].endDate
                                    .toLocaleString("en-GB")
                                    .substring(0, 10)}
                                  <br></br>
                                  <strong className="me-3">Customer:</strong>
                                  {customer_label}
                                  <br />
                                  <strong className="me-3">
                                    Payment Type:
                                  </strong>
                                  {payment_label}
                                  <br />
                                </h6>
                                <h6>
                                  <strong className="me-3">Supplier:</strong>
                                  {supplier_label}
                                  <br />
                                  <strong className="me-3">Sale Person:</strong>
                                  {saleman_label}
                                  <br />
                                </h6>
                              </div>
                            </div>
                          )}
                          <h4 className="text-center text-success">
                            {t("side_bar_sale")}
                          </h4>
                          <div style={{ zoom: ".8" }}>
                            <BootstrapTable
                              {...props.baseProps}
                              rowStyle={rowstyle}
                              striped
                              bootstrap4
                              condensed
                              rowClasses={rowClasses}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                          <hr />
                          <h4 className="text-center text-success">
                            {t("side_bar_purchase")}
                          </h4>
                          <BootstrapTable
                            keyField="id"
                            data={details_purchase}
                            columns={columns_2}
                            rowClasses={rowClasses}
                            rowStyle={rowstyle}
                            striped
                            bootstrap4
                            condensed
                            wrapperClasses="table-responsive"
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>

          <ToastContainer
            autoClose={1000}
            hideProgressBar={true}
            theme="dark"
          />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}
