import {SetCurrentUser,Set_Branch_first,Set_Branch } from "../constants"

const branch = JSON.parse(localStorage.getItem("selected_branch"));

if (branch){
    var user_branch=branch
}else{
    var user_branch=null
}

const initialstates={
    current_user:null,
    selected_branch:user_branch
    

}
export default function Setcurrentinfo(state=initialstates,action){
   
    switch (action.type){

        case SetCurrentUser:
            return {
                selected_branch:state.selected_branch,
                current_user:action.payload
            }

        case Set_Branch:
            const branch=action.payload.current_user?.branch_list?.filter((item)=>
            item.name===action.payload.value)
            localStorage.setItem('selected_branch' , JSON.stringify(branch[0]))
            return {
                selected_branch:branch[0],
                current_user:state.current_user
            };
            
        case Set_Branch_first:
            return {
                selected_branch:action.payload,
                current_user:state.current_user
            }
        default:
            return {
                ...state
            }
            
    }
}