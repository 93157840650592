import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./voucher.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Red_toast from "../alerts/red_toast";
import Select from "../alerts/select";

import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";

function Voucherform({
  show,
  onHide,
  user,
  route,
  callback,
  selected_branch,
  customers,
  text,
  current_user,
  setloadagain,
  type,
}) {
  const { t } = useTranslation();
  const [party, setparty] = useState("");
  const [amount, setamount] = useState("");
  const [description, setdescription] = useState("");

  const [voucherno, setvoucherno] = useState("");
  const [bank, setbank] = useState("");
  const [cash, setcash] = useState("");
  const [chequeno, setchequeno] = useState("");

  const [isloading, setisloading] = useState(false);
  const [checkprint, setcheckprint] = useState(true);
  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);

  const [date, setdate] = useState(curdate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selected_branch && !isloading) {
      setisloading(true);
      const formData = new FormData();

      formData.append("date", date);
      formData.append("description", description);
      formData.append("amount", amount);
      formData.append("party", party.value);

      formData.append("bank", bank);
      formData.append("cash", cash);
      formData.append("cheque_number", chequeno);
      formData.append("voucher_number", voucherno);
      if (type === "supplier") {
        formData.append("reference", "PV");
      } else {
        formData.append("reference", "RV");
      }

      formData.append("branch", selected_branch.id);
      formData.append("user", current_user.id);

      const response = await fetch(`${route}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok) {
        setisloading(false);
        success_toast();
        setparty("");
        setamount("");
        setdescription("");
        setvoucherno("");

        setbank("");
        setcash("");
        setchequeno("");
        setdate(curdate);
        setloadagain();
      }
    }
  };

  const handleprint = async (e) => {
    e.preventDefault();

    if (selected_branch && !isloading) {
      const formData = new FormData();

      formData.append("date", date);
      formData.append("description", description);
      formData.append("amount", amount);
      formData.append("party", party.value);

      formData.append("bank", bank);
      formData.append("cash", cash);
      formData.append("cheque_number", chequeno);
      formData.append("voucher_number", voucherno);
      if (type === "supplier") {
        formData.append("reference", "PV");
      } else {
        formData.append("reference", "RV");
      }

      formData.append("branch", selected_branch.id);
      formData.append("user", current_user.id);

      const response = await fetch(`${route}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok) {
        setisloading(false);
        success_toast();
        setparty("");
        setamount("");
        setdescription("");
        setvoucherno("");
        setbank("");
        setcash("");
        setchequeno("");
        setdate(curdate);
        setloadagain();
        setcheckprint(true);
        localStorage.setItem("data", JSON.stringify(json));
        if (type === "supplier") {
          window.open("/receipt/Payment", "_blank");
        } else {
          window.open("/receipt/Receipt", "_blank");
        }
      }
    }
  };

  useEffect(() => {
    if (party && amount && voucherno) {
      setcheckprint(false);
    }
  }, [party, amount, voucherno]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          {t("add_voucher")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <Select
                options={customers}
                placeholder={text}
                value={party}
                funct={(e) => {
                  setparty(e);
                }}
                required={true}
              ></Select>
            </div>
            <div className="col-md-6">
              <TextField
                type="date"
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("date")}
                value={date}
                onChange={(e) => setdate(e.target.value)}
                size="small"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("Voucher No")}
                value={voucherno}
                onChange={(e) => setvoucherno(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type="number"
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("amount")}
                value={amount}
                onChange={(e) => setamount(e.target.value)}
                size="small"
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("bank")}
                value={bank}
                onChange={(e) => setbank(e.target.value)}
                size="small"
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("cash")}
                value={cash}
                onChange={(e) => setcash(e.target.value)}
                size="small"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("chequeno")}
                value={chequeno}
                onChange={(e) => setchequeno(e.target.value)}
                size="small"
              />
            </div>
            <div className="col-md-6">
              <TextField
                multiline
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("description")}
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                size="small"
              />
            </div>
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
            <Button
              className="me-2"
              variant="outline-success"
              onClick={handleprint}
              disabled={checkprint}
            >
              <PrintRoundedIcon /> {t("print")}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Voucherform;
