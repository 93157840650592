import React, { useEffect, useState, useRef } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./product.css";

import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Red_toast from "../alerts/red_toast";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Select from "../alerts/select";

import { Avatar } from "@material-ui/core";

import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";

import { useTranslation } from "react-i18next";

function Productform({
  show,
  onHide,
  user,
  route,
  callback,
  selected_branch,
  check,
}) {
  const inputFile = useRef(null);
  const [File, setFile] = useState("");
  const [Fileurl, setFileurl] = useState("");
  const { t } = useTranslation();
  const [category, setcategory] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [name, setname] = useState("");
  const [code, setcode] = useState("");
  const [barcode, setbarcode] = useState("");
  const [quantity, setquantity] = useState("");
  const [price, setprice] = useState("");
  const [isloading, setisloading] = useState(false);
  const [all_categories, setall_categories] = useState([]);
  const [all_subcategories, setall_subcategories] = useState([]);

  useEffect(() => {
    setisloading(true);

    const fetchsubcategories = async () => {
      const response = await fetch(
        `${route}/api/subcategories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const conversion_sub = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setall_subcategories(conversion_sub);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
        setisloading(false);
      }
    };

    const fetchcategories = async () => {
      const response = await fetch(
        `${route}/api/categories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const conversion = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setall_categories(conversion);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
        setisloading(false);
      }
    };

    if (user) {
      fetchcategories();
      fetchsubcategories();
    }
  }, [user, selected_branch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selected_branch) {
      setisloading(true);
      const formData = new FormData();

      formData.append("name", name);
      formData.append("code", code);
      formData.append("barcode", barcode);
      formData.append("price", price);
      formData.append("quantity", quantity);
      formData.append("branch", selected_branch.id);
      formData.append("picture", File);
      if (category) {
        formData.append("category", category.value);
      } else {
        formData.append("category", category);
      }
      if (subcategory) {
        formData.append("sub_category", subcategory.value);
      } else {
        formData.append("sub_category", subcategory);
      }
      const response = await fetch(`${route}/api/products/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok && check === "false") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        setname("");
        setcode("");
        setbarcode("");
        setprice("");
        setquantity("");
        setcategory("");
        setsubcategory("");
        setFile("");
        setFileurl("");
      } else if (response.ok && check === "true") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        onHide();
      }
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];

    setFile(file);

    const reader = new FileReader();
    reader.onload = () => {
      setFileurl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          {t("add_product")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-2 d-flex flex-column align-items-center">
              <Avatar
                src={Fileurl}
                className="mb-3"
                style={{ width: "100px", height: "100px" }}
                alt="image"
                variant="rounded"
              />
              <input
                onChange={handleimageselection}
                id="select-file"
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
              />
              <Button onClick={onButtonClick} shadow>
                {t("choose_file")}
              </Button>
            </div>

            <div className="col-md-5">
              <Select
                options={all_categories}
                placeholder={t("side_bar_category")}
                value={category}
                funct={(e) => setcategory(e)}
                required={true}
              ></Select>
              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("name")}
                value={name}
                onChange={(e) => setname(e.target.value)}
                size="small"
              />
              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("barcode")}
                value={barcode}
                onChange={(e) => setbarcode(e.target.value)}
                size="small"
              />
              <TextField
                type="number"
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("price")}
                value={price}
                onChange={(e) => setprice(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-5">
              <Select
                options={all_subcategories}
                placeholder={t("side_bar_subcategory")}
                value={subcategory}
                funct={(e) => setsubcategory(e)}
              ></Select>

              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("code")}
                value={code}
                onChange={(e) => setcode(e.target.value)}
                size="small"
              />

              <TextField
                type="number"
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("quantity")}
                value={quantity}
                onChange={(e) => setquantity(e.target.value)}
                size="small"
                required
              />
            </div>
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Productform;
