import React, { useState, useEffect } from "react";

import { IconButton, Avatar } from "@material-ui/core";
import cellEditFactory from "react-bootstrap-table2-editor";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./expense.css";
import SaveIcon from "@material-ui/icons/Save";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentIcon from "@material-ui/icons/Payment";
import TextField from "@mui/material/TextField";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Red_toast from "../alerts/red_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import custom_toast from "../alerts/custom_toast";
import Alert_before_delete from "../../Container/alertContainer";
import { useTranslation } from "react-i18next";
function Typeform({
  show,
  onHide,
  user,
  route,
  selected_branch,
  typetriger,
  settypetriger,
}) {
  const [isloading, setisloading] = useState(false);
  const { t } = useTranslation();
  const [name, setname] = useState("");
  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [data, setdata] = useState([]);
  const [getagain, setgetagain] = useState(false);

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/expensetypes/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setdata(json);
      }
    };

    fetchWorkouts();
  }, [selected_branch, getagain]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setisloading(true);
    const response = await fetch(`${route}/api/expensetypes/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
      body: JSON.stringify({
        name,
        branch: selected_branch.id,
      }),
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }

    if (response.ok) {
      setisloading(false);
      setname("");
      setdata([...data, json]);
      settypetriger(!typetriger);
      success_toast();
    }
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const handleconfirm = (row) => {
    custom_toast("Delete");
    setgetagain(!getagain);
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex ">
        <IconButton
          className="border border-danger rounded me-2"
          onClick={() => {
            setrow_id(row.id);
            seturl_to_delete(`${route}/api/expensetypes/${row.id}/`);
            setdelete_user(true);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="small" />
        </IconButton>
      </span>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "name",
      text: t("expensetype"),
      sort: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "action",
      text: t("action"),
      formatter: Action,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: 30,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const rowstyle = { height: "10px" };

  return (
    <Modal
      show={show}
      onHide={onHide}
      style={{ zoom: ".8" }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <PaymentIcon className="me-2" />
          {t("expensetype")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center mt-2">
            <TextField
              className="me-2"
              id="outlined-basic"
              label={t("name")}
              value={name}
              onChange={(e) => {
                setname(e.target.value);
              }}
              size="small"
            />
            <Save_button isloading={isloading} />
          </div>
        </form>
        <hr />

        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between ">
                <ExportCSVButton
                  {...props.csvProps}
                  className="csvbutton border bg-secondary text-light"
                >
                  Export CSV
                </ExportCSVButton>
                <SearchBar {...props.searchProps} />
              </div>

              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                rowStyle={rowstyle}
                striped
                bootstrap4
                condensed
                rowClasses="custom-row-class"
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </Modal.Body>
      {delete_user && (
        <Alert_before_delete
          show={delete_user}
          onHide={() => setdelete_user(false)}
          url={url_to_delete}
          dis_fun={handleconfirm}
          row_id={row_id}
        />
      )}
    </Modal>
  );
}

export default Typeform;
