import React, { useState, useEffect, useRef } from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import PaymentIcon from "@material-ui/icons/Payment";
import { Avatar } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import Red_toast from "../alerts/red_toast";
import Update_button from "../buttons/update_button";
import { useTranslation } from "react-i18next";
import Select from "../alerts/select";
function Supplierupdate({
  show,
  onHide,
  data,
  user,
  route,
  fun,
  callback,
  selected_branch,
}) {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const [picture, setpicture] = useState("");
  const [Fileurl, setFileurl] = useState(data.picture);
  const [name, setname] = useState(data.name);
  const [email, setemail] = useState(data.email);
  const [contact, setcontact] = useState(data.contact);
  const [vat_number, setvat_number] = useState(data.vat_number);
  const [address, setaddress] = useState(data.address);
  const [isloading, setisloading] = useState(false);
  const [city, setcity] = useState({ value: data.city, label: data.city_name });
  const [allcity, setallcity] = useState([]);

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/city/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setallcity(
          json.map((item) => {
            return { value: item.id, label: item.name };
          })
        );
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, [selected_branch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisloading(true);
    const formData = new FormData();

    formData.append("name", name);
    formData.append("contact", contact);
    formData.append("city", city?.value);
    formData.append("email", email);
    formData.append("vat_number", vat_number);
    formData.append("address", address);
    if (picture) {
      formData.append("picture", picture);
    }
    const response = await fetch(`${route}/api/parties/${data.id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }

    if (response.ok) {
      setisloading(false);
      callback({ type: "Update_table_history", data: json });
      onHide();
      fun("Update");
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];

    setpicture(file);

    const reader = new FileReader();
    reader.onload = () => {
      setFileurl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <PaymentIcon className="me-2" />
          {t("edit_supplier")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <Avatar
                src={Fileurl}
                className="mb-3"
                style={{ width: "100px", height: "100px" }}
                alt="image"
              />
              <input
                onChange={handleimageselection}
                id="select-file"
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
              />
              <Button onClick={onButtonClick} shadow>
                {t("choose_file")}
              </Button>
            </div>

            <div className="col-md-8">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("name")}
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
                size="small"
                required
              />
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("email")}
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                size="small"
              />

              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("phone")}
                value={contact}
                onChange={(e) => {
                  setcontact(e.target.value);
                }}
                size="small"
              />
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("vat_number")}
                value={vat_number}
                onChange={(e) => {
                  setvat_number(e.target.value);
                }}
                size="small"
              />

              <Select
                options={allcity}
                placeholder={t("city")}
                value={city}
                funct={(e) => {
                  setcity(e);
                }}
              />

              <TextField
                multiline
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("address")}
                value={address}
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>

          <hr></hr>
          <div className="d-flex flex-row-reverse mt-2 me-2">
            <Update_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Supplierupdate;
