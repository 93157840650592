import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../sale/sale.css";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Select from "react-select";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faListOl } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Tooltip from "@material-ui/core/Tooltip";
import Customerform from "../customers/customerform";
import Productform from "../products/productform";
import Saleform from "../sale/saleform";
import Notesmodel from "../sale/notemodel";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import Paymentmodel from "../sale/payment_model";
import Red_toast from "../alerts/red_toast";
import DeliverymanModel from "../sale/deliverymanModel";
import InfoIcon from "@material-ui/icons/Info";
import { useTranslation } from "react-i18next";

function SaleReturn(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const check_status = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const id_data = props.state.Setsavedata.save_data;

  const settable_data_id = props.Setsavedata;
  const table_data = props.state.Setproducthistory.product_history;
  const settable_data = props.Setproduct_history;
  const [deliveryman, setdeliveryman] = useState("");
  const [all_deliveryman, setall_deliveryman] = useState([]);
  const [saleman, setsaleman] = useState("");
  const [all_salesman, setall_salesman] = useState([]);
  const [all_product_option, setall_product_option] = useState([]);
  const [all_product, setall_product] = useState([]);
  const [all_suppliers, setall_suppliers] = useState([]);
  const [placeholder, setplaceholder] = useState(t("search_by_name"));
  const [name_color, setname_color] = useState("blue");
  const [barcode_color, setbarcode_color] = useState("lightgray");
  const [code_color, setcode_color] = useState("lightgray");
  const [text, settext] = useState("");
  const [url, seturl] = useState("");
  const [payment_status, setpayment_status] = useState(false);
  const [amount_received, setamount_received] = useState(0);
  const [call_products_again, setcall_products_again] = useState(false);
  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [invoice, setinvoice] = useState("");
  const [date, setdate] = useState(curdate);
  const [counter, setcounter] = useState(1);
  const [supplier, setsupplier] = useState("");
  const [payment_type, setpayment_type] = useState("");
  const [payment_type_option, setpayment_type_option] = useState([]);
  const [subtotal, setsubtotal] = useState(0);
  const [vat, setvat] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [extra_discount, setextra_discount] = useState(0);
  const [total, settotal] = useState(0);
  const [vat_perc, setvat_perc] = useState(0);
  const [notes, setnotes] = useState("");
  const [showmodelsuplier, setshowmodelsuplier] = useState(false);
  const [showmodelproduct, setshowmodelproduct] = useState(false);
  const [showmodel, setshowmodel] = useState(false);
  const [notemodel, setnotemodel] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [showpayment_model, setshowpayment_model] = useState(false);
  const [print, setprint] = useState(false);
  const [getlatest_invoice, setgetlatest_invoice] = useState(false);
  const [product, setproduct] = useState("");
  const [suppliers_data, setsuppliers_data] = useState([]);
  const [salesman_data, setsalesman_data] = useState([]);
  const [deliveryman_data, setdeliveryman_data] = useState([]);
  const [data, setdata] = useState("");
  const [text_product, settext_product] = useState("");
  const [reference_number, setreference_number] = useState("");

  const [showmodeldelivery, setshowmodeldelivery] = useState(false);
  const [delivery_no, setdelivery_no] = useState("");
  const [delivery_date, setdelivery_date] = useState(curdate);
  const [po_number, setpo_number] = useState("");
  const [po_date, setpo_date] = useState(curdate);
  const [payterm_terms, setpayterm_terms] = useState("");
  const [inco_terms, setinco_terms] = useState("");
  const [showpayment_model_2, setshowpayment_model_2] = useState(false);
  const [dotmatrix, setdotmatrix] = useState(false);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "salereturn" });
  }, []);

  useEffect(() => {
    const fetchlatest_invoice = async () => {
      const response = await fetch(
        `${route}/api/sales/${selected_branch.id}/latest-invoice/`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setinvoice(json.invoice_number);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    fetchlatest_invoice();
  }, [selected_branch, getlatest_invoice]);

  useEffect(() => {
    if (invoice && supplier && payment_type && table_data.length > 0) {
      setprint(true);
    } else {
      setprint(false);
    }
  }, [invoice, supplier, payment_type, table_data]);

  useEffect(() => {
    settable_data({ type: "Set_product_history", data: [] });
    const fetchProducts = async () => {
      const response = await fetch(
        `${route}/api/products/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setall_product(json);
        const pro = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_product_option(pro);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [selected_branch, check_status, call_products_again]);

  useEffect(() => {
    const fetchPayment_type = async () => {
      const response = await fetch(
        `${route}/api/payment-types/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const pro = json.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
        setpayment_type_option(pro);
        setpayment_type(pro[0]);
      }
    };

    const fetchcompany = async () => {
      const response = await fetch(
        `${route}/api/branch/${selected_branch.id}/`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setvat_perc(json.vat_percentage);
      }
    };

    if (user) {
      fetchPayment_type();
      fetchcompany();
    }
  }, [selected_branch]);

  useEffect(() => {
    const fetchSuppliers = async () => {
      const response = await fetch(
        `${route}/api/parties/?branch_id=${selected_branch.id}&party_type=Customer`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setsuppliers_data(json);
        const supp = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_suppliers(supp);
        if (id_data.length !== 2) {
          setsupplier(supp.slice(-1)[0]);
        }
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchSuppliers();
    }
  }, [selected_branch, check_status]);

  useEffect(() => {
    const fetchDeliveryman = async () => {
      const response = await fetch(
        `${route}/api/salespersons/?branch_id=${selected_branch.id}&type=delivery_man`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setdeliveryman_data(json);
        const supp = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_deliveryman(supp);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    const fetchsaleman = async () => {
      const response = await fetch(
        `${route}/api/salespersons/?branch_id=${selected_branch.id}&type=sale_person`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setsalesman_data(json);
        const supp = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_salesman(supp);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchDeliveryman();
      fetchsaleman();
    }
  }, [selected_branch]);

  useEffect(() => {
    var vat_total = 0;
    var subtotal_total = 0;
    var discount_total = 0;
    var total_total = 0;
    table_data.forEach((item) => {
      vat_total += Number(item.vat_amount);
      subtotal_total += Number(item.sub_total);
      discount_total += Number(item.discount);
      total_total += Number(item.total);
    });
    setsubtotal(subtotal_total.toFixed(2));
    setvat(vat_total.toFixed(2));
    setdiscount(discount_total.toFixed(2));
    settotal((total_total - extra_discount).toFixed(2));
  }, [table_data]);

  useEffect(() => {
    var repeat_total = 0;
    table_data.forEach((item) => {
      repeat_total += Number(item.total);
    });
    settotal(repeat_total - extra_discount);
  }, [extra_discount]);

  const initilize_handleSubmit = (e) => {
    e.preventDefault();
    if (table_data.length > 0) {
      setshowpayment_model(!showpayment_model);
    }
  };

  const initilize_handleSubmit_print = (e) => {
    e.preventDefault();
    if (table_data.length > 0) {
      setshowpayment_model_2(!showpayment_model);
    }
  };
  const initilize_handleSubmit_printdotmatrix = (e) => {
    e.preventDefault();
    if (table_data.length > 0) {
      setshowpayment_model_2(!showpayment_model);
      setdotmatrix(true);
    }
  };

  const handleSubmit = async () => {
    if (selected_branch) {
      const data_details = table_data.map((item) => {
        delete item["prod_id"];
        delete item["name"];
        delete item["code"];
        return item;
      });
      setisloading(true);
      if (deliveryman) {
        var check_deliveryman = deliveryman.value;
      } else {
        check_deliveryman = null;
      }

      const formdata = {};
      formdata["sale_person"] = saleman.value;
      formdata["user"] = current_user.id;
      formdata["branch"] = selected_branch.id;
      formdata["customer"] = supplier.value;
      formdata["remarks"] = notes;
      formdata["date"] = date;
      formdata["reference_number"] = reference_number;
      formdata["invoice"] = invoice;
      formdata["sub_total"] = subtotal;
      formdata["vat_amount"] = vat;
      formdata["discount"] = discount;
      formdata["extra_disc"] = extra_discount;
      formdata["total"] = total;
      formdata["vat_percentage"] = vat_perc;
      formdata["payment_type"] = payment_type.value;
      formdata["amount_received"] = amount_received;
      formdata["reference"] = "SR";
      formdata["details"] = data_details;
      formdata["delivery_details"] = {
        delivery_number: delivery_no,
        delivery_date: delivery_date,
        PO_number: po_number,
        PO_date: po_date,
        payment_terms: payterm_terms,
        inco_terms: inco_terms,
        delivery_man: check_deliveryman,
      };

      const response = await fetch(`${route}/api/sale-return/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify(formdata),
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        settable_data({ type: "Set_product_history", data: [] });
        if (json.error === "out of stock") {
          Red_toast(`${json.product} out of Stock`);
          setcall_products_again(!call_products_again);
        } else {
          var error = Object.keys(json);
          if (error.length > 0) {
            Red_toast(`${json[error[0]]}`);
          }
        }
      }

      if (response.ok) {
        setisloading(false);
        success_toast();
        settable_data({ type: "Set_product_history", data: [] });
        setinvoice("");
        setproduct("");
        setsupplier("");
        setsubtotal(0);
        setnotes("");
        setdelivery_no("");
        setpo_number("");
        setreference_number("");
        setpayterm_terms("");
        setinco_terms("");
        setpo_date(curdate);
        setdelivery_date(curdate);
        setvat(0);
        setdiscount(0);
        setextra_discount(0);
        settotal(0);
        setdeliveryman("");
        setprint(false);
        setgetlatest_invoice(!getlatest_invoice);
        setcounter(1);

        all_product.map((item) => {
          const get_item = table_data.filter((prod) => {
            return prod.product === item.id;
          });
          if (get_item.length > 0) {
            item["quantity"] = item.quantity - get_item[0].quantity;
          }
        });
      }
    }
  };

  const handleNewSale = (e) => {
    e.preventDefault();
    settable_data({ type: "Set_product_history", data: [] });

    setproduct("");
    setsupplier("");
    setsubtotal(0);
    setreference_number("");
    setvat(0);
    setdiscount(0);
    setextra_discount(0);
    settotal(0);
    setdeliveryman("");
    setprint(false);
    setcounter(1);
    setnotes("");
    setdelivery_no("");
    setpo_number("");
    setpayterm_terms("");
    setinco_terms("");
    setpo_date(curdate);
    setdelivery_date(curdate);
  };

  const handlePrint = async () => {
    if (selected_branch) {
      const data_details = table_data.map((item) => {
        delete item["prod_id"];
        delete item["name"];
        delete item["code"];
        return item;
      });

      if (deliveryman) {
        var check_deliveryman = deliveryman.value;
      } else {
        check_deliveryman = null;
      }

      const formdata = {};
      formdata["sale_person"] = saleman.value;
      formdata["user"] = current_user.id;
      formdata["branch"] = selected_branch.id;
      formdata["customer"] = supplier.value;
      formdata["remarks"] = notes;
      formdata["date"] = date;
      formdata["invoice"] = invoice;
      formdata["sub_total"] = subtotal;
      formdata["vat_amount"] = vat;
      formdata["discount"] = discount;
      formdata["extra_disc"] = extra_discount;
      formdata["total"] = total;
      formdata["vat_percentage"] = vat_perc;
      formdata["payment_type"] = payment_type.value;
      formdata["reference"] = "SR";
      formdata["amount_received"] = amount_received;
      formdata["details"] = data_details;
      formdata["reference_number"] = reference_number;
      formdata["delivery_details"] = {
        delivery_number: delivery_no,
        delivery_date: delivery_date,
        PO_number: po_number,
        PO_date: po_date,
        payment_terms: payterm_terms,
        inco_terms: inco_terms,
        delivery_man: check_deliveryman,
      };
      const response = await fetch(`${route}/api/sale-return/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify(formdata),
      });
      const json = await response.json();

      if (!response.ok) {
        settable_data({ type: "Set_product_history", data: [] });
        if (json.error === "out of stock") {
          Red_toast(`${json.product} out of Stock`);
          setcall_products_again(!call_products_again);
        } else {
          var error = Object.keys(json);
          if (error.length > 0) {
            Red_toast(`${json[error[0]]}`);
          }
        }
      }

      if (response.ok) {
        localStorage.setItem("data", JSON.stringify(json));
        settable_data({ type: "Set_product_history", data: [] });
        setgetlatest_invoice(!getlatest_invoice);
        setinvoice("");
        setsupplier("");
        setsubtotal(0);
        setnotes("");
        setdelivery_no("");
        setreference_number("");
        setpo_number("");
        setpayterm_terms("");
        setinco_terms("");
        setpo_date(curdate);
        setdelivery_date(curdate);
        setvat(0);
        setdiscount(0);
        setextra_discount(0);
        settotal(0);
        setdeliveryman("");
        setproduct("");
        setprint(false);
        setcounter(1);
        if (dotmatrix) {
          window.open("/invoice_4/sales", "_blank");
        } else if (selected_branch.invoice_type === "Version 01") {
          window.open("/invoice/sales", "_blank");
        } else if (selected_branch.invoice_type === "Version 02") {
          window.open("/invoice_2/sales", "_blank");
        } else if (selected_branch.invoice_type === "Version 03") {
          window.open("/invoice_3/sales", "_blank");
        } else if (selected_branch.invoice_type === "Version 04") {
          window.open("/invoice_5/sales", "_blank");
        } else if (selected_branch.invoice_type === "Version 05") {
          window.open("/invoice_6/sales", "_blank");
        }

        all_product.map((item) => {
          const get_item = table_data.filter((prod) => {
            return prod.product === item.id;
          });
          if (get_item.length > 0) {
            item["quantity"] = item.quantity - get_item[0].quantity;
          }
        });
        setdotmatrix(false);
      }
    }
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="action text-center">
        <IconButton
          onClick={() => {
            settable_data({ type: "Delete_product_history", data: row });
          }}
        >
          <DeleteRoundedIcon color="error" fontSize="medium" />
        </IconButton>
      </div>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const name_column_formater = (cell, row) => {
    return <div style={{ width: "18vw" }}>{cell}</div>;
  };

  const fix_formatter = (cell, row) => {
    return <div>{parseFloat(cell).toFixed(2)}</div>;
  };

  const columns = [
    {
      dataField: "row_number",
      text: "#",
      headerFormatter: headerstyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: "code",
      text: t("code"),
      headerFormatter: headerstyle,
      editable: false,
    },
    {
      dataField: "name",
      text: t("name"),
      sort: true,
      headerFormatter: headerstyle,
      formatter: name_column_formater,
      editable: false,
    },
    {
      dataField: "quantity",
      text: t("qty"),
      formatter: fix_formatter,
      headerFormatter: headerstyle,
    },
    {
      dataField: "price",
      text: t("price"),
      formatter: fix_formatter,
      headerFormatter: headerstyle,
    },
    {
      dataField: "sub_total",
      text: t("subtotal"),
      formatter: fix_formatter,
      headerFormatter: headerstyle,
      editable: false,
    },
    {
      dataField: "vat_percentage",
      text: t("vat%"),
      formatter: fix_formatter,
      headerFormatter: headerstyle,
    },
    {
      dataField: "vat_amount",
      text: t("vat_amount"),
      formatter: fix_formatter,
      headerFormatter: headerstyle,
      editable: false,
    },
    {
      dataField: "discount",
      text: t("discount"),
      formatter: fix_formatter,
      headerFormatter: headerstyle,
    },
    {
      dataField: "total",
      text: t("total"),
      formatter: fix_formatter,
      headerFormatter: headerstyle,
      editable: false,
    },
    {
      dataField: "Edit",
      text: t("action"),
      formatter: linkFollow,
      headerFormatter: headerstyle,
      editable: false,
    },
  ];

  const handlesearch_by_barcode = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: item.barcode,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_barcode"));
    setbarcode_color("blue");
    setname_color("lightgray");
    setcode_color("lightgray");
  };
  const handlesearch_by_code = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: `${item.code} | ${item.name} `,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_code"));
    setbarcode_color("lightgray");
    setname_color("lightgray");
    setcode_color("blue");
  };

  const handlesearch_by_name = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_name"));
    setbarcode_color("lightgray");
    setname_color("blue");
    setcode_color("lightgray");
  };

  const handlebarcodeinput = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      console.log(e.target.value);
      const filter_data = all_product.filter((item) => {
        if (item.barcode === e.target.value) {
          return item;
        }
      });
      if (filter_data.length > 0) {
        handleproduct_selection({
          value: filter_data[0].id,
          label: filter_data[0].name,
        });
      }
      settext_product("");
    }
  };

  const handleproduct_selection = (selected_option) => {
    const item_present = table_data.filter((item) => {
      return item.product === selected_option.value;
    });

    if (item_present.length === 0) {
      var filter_data = all_product.filter((item) => {
        if (item.id === selected_option.value) {
          return item;
        }
      });

      // if (filter_data[0].quantity>0){
      filter_data = filter_data.map((item) => {
        return {
          product: item.id,
          prod_id: counter,
          name: item.name,
          code: item.code,
          quantity: 1,
          price: item.price,
          sub_total: item.price,
          vat_percentage: vat_perc,
          vat_amount: (item.price / 100) * vat_perc,
          discount: 0,
          total: item.price + (item.price / 100) * vat_perc,
        };
      });
      settable_data({ type: "Create_product_history", data: filter_data[0] });
      setcounter(counter + 1);
      // else{
      //     Red_toast(`${filter_data[0].name} out of Stock!`)
      // }
    } else {
      // if (item_present[0].quantity>0){
      const item = item_present[0];
      item.quantity = item.quantity + 1;
      item.sub_total = item.quantity * item.price;
      item.vat_amount = (item.sub_total / 100) * item.vat_percentage;
      item.total = item.sub_total + item.vat_amount - item.discount;
      settable_data({ type: "Update_product_history", data: item });

      // else{
      //     Red_toast(`${item_present[0].name} out of Stock!`)
      // }
    }
  };

  const handlecellchange = (oldValue, newValue, row, column) => {
    if (column.dataField === "quantity") {
      var new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: newValue,
              price: item.price,
              sub_total: item.price * newValue,
              vat_percentage: item.vat_percentage,
              vat_amount: ((item.price * newValue) / 100) * item.vat_percentage,
              discount: item.discount,
              total:
                item.price * newValue +
                ((item.price * newValue) / 100) * item.vat_percentage -
                item.discount,
            };
      });
      settable_data({ type: "Set_product_history", data: new_data });
    } else if (column.dataField === "vat_percentage") {
      new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: item.quantity,
              price: item.price,
              sub_total: item.sub_total,
              vat_percentage: newValue,
              vat_amount: (item.sub_total / 100) * newValue,
              discount: item.discount,
              total:
                item.sub_total +
                (item.sub_total / 100) * newValue -
                item.discount,
            };
      });
      settable_data({ type: "Set_product_history", data: new_data });
    } else if (column.dataField === "discount") {
      new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: item.quantity,
              price: item.price,
              sub_total: item.sub_total,
              vat_percentage: item.vat_percentage,
              vat_amount: item.vat_amount,
              discount: newValue,
              total: item.sub_total + item.vat_amount - newValue,
            };
      });

      settable_data({ type: "Set_product_history", data: new_data });
    } else if (column.dataField === "price") {
      new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: item.quantity,
              price: newValue,
              sub_total: newValue * item.quantity,
              vat_percentage: item.vat_percentage,
              vat_amount:
                ((newValue * item.quantity) / 100) * item.vat_percentage,
              discount: item.discount,
              total:
                newValue * item.quantity +
                ((newValue * item.quantity) / 100) * item.vat_percentage -
                item.discount,
            };
      });

      settable_data({ type: "Set_product_history", data: new_data });
    }
  };

  const handlevat_percentage = (e) => {
    const vat_value = e.target.value;
    setvat_perc(vat_value);
    if (table_data) {
      const new_data = table_data.map((item) => {
        return {
          product: item.product,
          prod_id: item.prod_id,
          name: item.name,
          code: item.code,
          quantity: item.quantity,
          price: item.price,
          sub_total: item.sub_total,
          vat_percentage: vat_value,
          vat_amount: (item.sub_total / 100) * vat_value,
          discount: item.discount,
          total:
            item.sub_total + (item.sub_total / 100) * vat_value - item.discount,
        };
      });
      settable_data({ type: "Set_product_history", data: new_data });
    }
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <>
      {current_user?.permissions?.includes("add_salereturn") ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("side_bar_salereturn")}
          </h1>
          <ToolkitProvider
            keyField="prod_id"
            data={table_data}
            columns={columns}
            search
            exportCSV
          >
            {(props) => (
              <div className="card">
                <form onSubmit={initilize_handleSubmit}>
                  <div className="card-header  d-flex justify-content-end">
                    <Button
                      className="me-2"
                      type="button"
                      variant="outline-dark"
                      onClick={handleNewSale}
                    >
                      <AddIcon /> {t("new")}{" "}
                    </Button>

                    <Button type="submit" variant="outline-primary">
                      <SaveIcon /> {t("save")}
                    </Button>

                    {print ? (
                      <Dropdown as={ButtonGroup}>
                        <Button
                          className="ms-2"
                          variant="outline-success"
                          onClick={initilize_handleSubmit_print}
                        >
                          <PrintRoundedIcon /> {t("print")}
                        </Button>

                        <Dropdown.Toggle
                          split
                          variant="outline-success"
                          id="dropdown-split-basic"
                        />

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={initilize_handleSubmit_printdotmatrix}
                          >
                            Dot Matrix
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Dropdown as={ButtonGroup}>
                        <Button
                          className="ms-2"
                          variant="outline-success"
                          disabled
                        >
                          <PrintRoundedIcon /> {t("print")}
                        </Button>

                        <Dropdown.Toggle
                          split
                          variant="outline-success"
                          id="dropdown-split-basic"
                          disabled
                        />

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Dot Matrix
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>

                  <div className="card-body ">
                    <div className="row d-sm-flex align-items-start mt-1">
                      <div className="col-6 mb-2 col-md-2">
                        <TextField
                          type="date"
                          className="form-control   mb-3"
                          id="outlined-basic"
                          label={t("date")}
                          InputLabelProps={{ shrink: true }}
                          defaultValue={date}
                          value={date}
                          onChange={(e) => {
                            setdate(e.target.value);
                          }}
                          size="small"
                        />
                      </div>

                      <div className="col-6 mb-2 col-md-2">
                        <TextField
                          className="form-control   mb-3"
                          id="outlined-basic"
                          label={t("invoice")}
                          value={invoice}
                          size="small"
                          required
                        />
                      </div>

                      <div className="col-6 mb-2 col-md-3">
                        <InputGroup>
                          <Select
                            className={
                              supplier
                                ? selected_branch.language === "English"
                                  ? "form-control selector customer"
                                  : "form-control selector customer_ar"
                                : "form-control selector"
                            }
                            styles={selectStyles}
                            options={all_suppliers}
                            placeholder={t("side_bar_customers")}
                            value={supplier}
                            onChange={(e) => {
                              setsupplier(e);
                            }}
                            required
                          ></Select>

                          <IconButton
                            className="p-0 ps-1 pe-1"
                            style={{
                              backgroundColor: "#0d6efd",
                              borderRadius: "0",
                            }}
                            onClick={() => {
                              settext("Customers");
                              seturl(
                                `${route}/api/parties/?branch_id=${selected_branch.id}&party_type=Customer`
                              );
                              setshowmodel(!showmodel);
                              setdata(suppliers_data);
                            }}
                          >
                            <VisibilityIcon
                              style={{ color: "white", height: "fit-content" }}
                              fontSize="medium"
                            />
                          </IconButton>
                          <IconButton
                            className="p-0 ps-1 pe-1"
                            style={{
                              backgroundColor: "slategray",
                              borderRadius: "0",
                            }}
                            onClick={() =>
                              setshowmodelsuplier(!showmodelsuplier)
                            }
                          >
                            <AddIcon
                              style={{ color: "white", height: "fit-content" }}
                              fontSize="medium"
                            />
                          </IconButton>
                        </InputGroup>
                      </div>

                      <div className="col-6 mb-2 col-md-2">
                        <InputGroup>
                          <Select
                            className={
                              saleman
                                ? selected_branch.language === "English"
                                  ? "form-control selector saleman"
                                  : "form-control selector saleman_ar"
                                : "form-control selector"
                            }
                            styles={selectStyles}
                            options={all_salesman}
                            placeholder={t("side_bar_salepersons")}
                            value={saleman}
                            onChange={(e) => {
                              setsaleman(e);
                            }}
                          ></Select>

                          <IconButton
                            className="p-0 ps-1 pe-1"
                            style={{
                              backgroundColor: "#0d6efd",
                              borderRadius: "0",
                            }}
                            onClick={() => {
                              settext("Sale Persons");
                              seturl(
                                `${route}/api/salespersons/?branch_id=${selected_branch.id}&type=sale_person`
                              );
                              setshowmodel(!showmodel);
                              setdata(salesman_data);
                            }}
                          >
                            <VisibilityIcon
                              style={{ color: "white", height: "fit-content" }}
                              fontSize="medium"
                            />
                          </IconButton>
                        </InputGroup>
                      </div>

                      <div className="col-6 mb-2 col-md-2">
                        <InputGroup>
                          <Select
                            className={
                              deliveryman
                                ? selected_branch.language === "English"
                                  ? "form-control selector deliveryman"
                                  : "form-control selector deliveryman_ar"
                                : "form-control selector"
                            }
                            styles={selectStyles}
                            options={all_deliveryman}
                            placeholder={t("side_bar_deliveryen")}
                            value={deliveryman}
                            onChange={(e) => {
                              setdeliveryman(e);
                            }}
                          ></Select>

                          <IconButton
                            className="p-0 ps-1 pe-1"
                            style={{
                              backgroundColor: "#0d6efd",
                              borderRadius: "0",
                            }}
                            onClick={() => {
                              settext("Delivery Persons");
                              seturl(
                                `${route}/api/salespersons/?branch_id=${selected_branch.id}&type=delivery_man`
                              );
                              setshowmodel(!showmodel);
                              setdata(deliveryman_data);
                            }}
                          >
                            <VisibilityIcon
                              style={{ color: "white", height: "fit-content" }}
                              fontSize="medium"
                            />
                          </IconButton>
                          <IconButton
                            className="p-0 ps-1 pe-1"
                            style={{
                              backgroundColor: "slategray",
                              borderRadius: "0",
                            }}
                            onClick={() =>
                              setshowmodeldelivery(!showmodeldelivery)
                            }
                          >
                            <InfoIcon
                              style={{ color: "white", height: "fit-content" }}
                              fontSize="medium"
                            />
                          </IconButton>
                        </InputGroup>
                      </div>

                      <div className="col-2 mb-2 col-md-1 mb-3">
                        <Button
                          type="button"
                          variant="outline-dark"
                          onClick={() => setnotemodel(!notemodel)}
                        >
                          {" "}
                          {t("notes")}
                        </Button>
                      </div>
                    </div>

                    <div className="row mt-2 d-sm-flex align-items-center">
                      <div className="col-md-7 d-sm-flex justify-content-end align-items-center">
                        <div className="text-center me-3">
                          <Tooltip title="Search Product by Barcode">
                            <IconButton onClick={handlesearch_by_barcode}>
                              <FontAwesomeIcon
                                color={barcode_color}
                                icon={faBarcode}
                                size="lg"
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Search Product by Code">
                            <IconButton onClick={handlesearch_by_code}>
                              <FontAwesomeIcon
                                color={code_color}
                                icon={faListOl}
                                size="lg"
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Search Product by Name">
                            <IconButton onClick={handlesearch_by_name}>
                              <FontAwesomeIcon
                                color={name_color}
                                icon={faTag}
                                size="lg"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="text-center col-md-6 mb-2">
                          <InputGroup>
                            {barcode_color === "lightgray" ? (
                              <Select
                                className="form-control selector"
                                placeholder={placeholder}
                                options={all_product_option}
                                value={product}
                                onChange={handleproduct_selection}
                              ></Select>
                            ) : (
                              <TextField
                                className="form-control"
                                id="outlined-basic"
                                label={t("search_by_barcode")}
                                onKeyDown={handlebarcodeinput}
                                value={text_product}
                                onChange={(e) =>
                                  settext_product(e.target.value)
                                }
                                size="small"
                              />
                            )}
                            <IconButton
                              className="p-0 ps-1 pe-1"
                              style={{
                                backgroundColor: "#0d6efd",
                                borderRadius: "0",
                              }}
                              onClick={() => {
                                settext(t("side_bar_product"));
                                seturl(
                                  `${route}/api/products/?branch_id=${selected_branch.id}`
                                );
                                setshowmodel(!showmodel);
                                setdata(all_product);
                              }}
                            >
                              <VisibilityIcon
                                style={{
                                  color: "white",
                                  height: "fit-content",
                                }}
                                fontSize="medium"
                              />
                            </IconButton>
                            <IconButton
                              className="p-0 ps-1 pe-1"
                              style={{
                                backgroundColor: "slategray",
                                borderRadius: "0",
                              }}
                              onClick={() =>
                                setshowmodelproduct(!showmodelproduct)
                              }
                            >
                              <AddIcon
                                style={{
                                  color: "white",
                                  height: "fit-content",
                                }}
                                fontSize="medium"
                              />
                            </IconButton>
                          </InputGroup>
                        </div>
                      </div>

                      <div className="col-6 pe-3 mb-1 col-md-2">
                        <Select
                          className={
                            payment_type
                              ? selected_branch.language === "English"
                                ? "form-control selector payment"
                                : "form-control selector payment_ar"
                              : "form-control selector"
                          }
                          styles={selectStyles}
                          options={payment_type_option}
                          placeholder={t("side_bar_paymnettype")}
                          value={payment_type}
                          onChange={(e) => {
                            setpayment_type(e);
                          }}
                          required
                        ></Select>
                      </div>
                      <div className="col-6 mb-1 col-md-2">
                        <TextField
                          className="form-control   "
                          id="outlined-basic"
                          label={t("reference_number")}
                          value={reference_number}
                          onChange={(e) => {
                            setreference_number(e.target.value);
                          }}
                          size="small"
                        />
                      </div>
                    </div>

                    <hr />
                    <div style={{ height: "45vh", overflow: "auto" }}>
                      <BootstrapTable
                        {...props.baseProps}
                        bordered={false}
                        bootstrap4
                        condensed
                        cellEdit={cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          afterSaveCell: handlecellchange,
                        })}
                        rowClasses="custom_row_class"
                      />
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end">
                    <div className="me-3">
                      <TextField
                        type="number"
                        className="form-control"
                        id="outlined-basic"
                        label={t("subtotal")}
                        value={subtotal}
                        onChange={(e) => {
                          setsubtotal(e.target.value);
                        }}
                        size="small"
                        disabled
                      />
                    </div>

                    <div className="me-3">
                      <TextField
                        type="number"
                        className="form-control"
                        id="outlined-basic"
                        label={t("vat%")}
                        value={vat_perc}
                        onChange={handlevat_percentage}
                        size="small"
                      />
                    </div>
                    <div className="me-3">
                      <TextField
                        type="number"
                        className="form-control"
                        id="outlined-basic"
                        label={t("vat_amount")}
                        value={vat}
                        onChange={(e) => {
                          setvat(e.target.value);
                        }}
                        size="small"
                        disabled
                      />
                    </div>
                    <div className="me-3">
                      <TextField
                        className="form-control "
                        id="outlined-basic"
                        label={t("discount")}
                        value={discount}
                        onChange={(e) => {
                          setdiscount(e.target.value);
                        }}
                        size="small"
                        disabled
                      />{" "}
                    </div>
                    <div className="me-3">
                      <TextField
                        type="number"
                        className="form-control "
                        id="outlined-basic"
                        label={t("extra_discount")}
                        value={extra_discount}
                        onChange={(e) => {
                          setextra_discount(e.target.value);
                        }}
                        size="small"
                      />{" "}
                    </div>
                    <div className="me-3">
                      <TextField
                        type="number"
                        className="form-control "
                        id="outlined-basic"
                        label={t("total")}
                        value={total}
                        onChange={(e) => {
                          settotal(e.target.value);
                        }}
                        size="small"
                        disabled
                      />
                    </div>
                  </div>

                  <ToastContainer
                    autoClose={1000}
                    hideProgressBar={true}
                    theme="dark"
                  />
                </form>
              </div>
            )}
          </ToolkitProvider>

          {showmodelsuplier && (
            <Customerform
              show={showmodelsuplier}
              onHide={() => setshowmodelsuplier(false)}
              user={user}
              route={route}
              callback={dispatch}
              selected_branch={selected_branch}
              check="true"
            />
          )}

          {showmodelproduct && (
            <Productform
              show={showmodelproduct}
              onHide={() => setshowmodelproduct(false)}
              user={user}
              route={route}
              callback={dispatch}
              selected_branch={selected_branch}
              check="true"
            />
          )}

          {showmodel && (
            <Saleform
              show={showmodel}
              onHide={() => setshowmodel(false)}
              user={user}
              route1={url}
              callback={settable_data}
              text={text}
              counter={counter}
              setcounter={setcounter}
              setsupplier={setsupplier}
              setdeliveryman={setdeliveryman}
              setsaleman={setsaleman}
              table_data={table_data}
              vat_perc={vat_perc}
              selected_branch={selected_branch}
              route={route}
              data_={data}
            />
          )}
          {notemodel && (
            <Notesmodel
              show={notemodel}
              onHide={() => setnotemodel(false)}
              notes={notes}
              setnotes={setnotes}
            />
          )}

          {showpayment_model && (
            <Paymentmodel
              show={showpayment_model}
              onHide={() => setshowpayment_model(false)}
              total={total}
              setamount_received={setamount_received}
              setpayment_status={setpayment_status}
              handlefun={handleSubmit}
            />
          )}

          {showpayment_model_2 && (
            <Paymentmodel
              show={showpayment_model_2}
              onHide={() => setshowpayment_model_2(false)}
              total={total}
              setamount_received={setamount_received}
              setpayment_status={setpayment_status}
              handlefun={handlePrint}
            />
          )}

          {showmodeldelivery && (
            <DeliverymanModel
              show={showmodeldelivery}
              onHide={() => setshowmodeldelivery(false)}
              delivery_no={delivery_no}
              delivery_date={delivery_date}
              po_number={po_number}
              po_date={po_date}
              payterm_terms={payterm_terms}
              inco_terms={inco_terms}
              setdelivery_no={setdelivery_no}
              setdelivery_date={setdelivery_date}
              setpo_number={setpo_number}
              setpo_date={setpo_date}
              setpayterm_terms={setpayterm_terms}
              setinco_terms={setinco_terms}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}

export default SaleReturn;
