import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import numberToWords from "number-to-words";
import "./sale_invoice.css";
import { toArabicWord } from "number-to-arabic-words/dist/index-node.js";

function Receipt() {
  const { name } = useParams();
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("selected_branch"))
  );
  const data = JSON.parse(localStorage.getItem("data"));

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  const reversedate = (date) => {
    const splitlist = date.split("-");
    return `${splitlist[2]}-${splitlist[1]}-${splitlist[0]}`;
  };

  function camelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return " " + word.toUpperCase();
      })
      .replace(/\s+/g, " ");
  }

  return (
    <div className=" w-100 " ref={componentRef}>
      {data && (
        <div className="p-3">
          <div className="row">
            <div className="col-4">
              <img
                src={company.logo}
                alt="logo"
                width={"100px"}
                height={"80px"}
              ></img>
            </div>
            <div className="col-8">
              <h5 className="text-start">{company.arabic_name}</h5>
              <h5
                className="m-0 p-2"
                style={{ backgroundColor: "black", color: "white" }}
              >
                {company.name}
              </h5>
            </div>
          </div>

          <div
            className="border border-dark p-2 mt-2"
            style={{ borderRadius: "10px" }}
          >
            <div className="row">
              <div className="col-4">
                <h6>التاريخ</h6>

                <h6>
                  <strong>Dated: </strong>
                  {reversedate(data.date)}
                </h6>
              </div>
              <div className="col-4">
                <h6 className="text-center">سندقبض</h6>
                <h6 className="text-center text-danger">
                  <span className="border-bottom border-danger">
                    {name} Voucher
                  </span>
                </h6>
              </div>
              <div className="col-4 row">
                <div className="col-6">
                  <h6>
                    <span className="text-danger">S.R.</span> ريال
                  </h6>
                  <h6 className="border border-dark p-2">{data.amount}</h6>
                </div>
                <div className="col-6">
                  <h6>
                    <span className="text-danger">H.</span> ه
                  </h6>
                  <h6 className="border border-dark p-3"></h6>
                </div>
              </div>
            </div>
            <h6>
              <span className="border border-dark p-2">
                No: {data.voucher_number}
              </span>
            </h6>

            <h6 className=" mt-3 d-flex align-items-center justify-content-between">
              <span className="text-danger">Received From Mr/Mrs:</span>

              <span style={{ borderBottom: "1px dotted black", width: "4in" }}>
                {data.party_name}
              </span>

              <span className=" text-end">: وصلنامن لسيد/السيدة</span>
            </h6>

            <h6 className=" mt-3 d-flex align-items-center justify-content-between">
              <span className="text-danger">The Sum Of S.R.</span>

              <span
                style={{ borderBottom: "1px dotted black", width: "4.8in" }}
              >
                {camelCase(numberToWords.toWords(data.amount))} Riyal Only
              </span>

              <span className=" text-end">: مبلغ وقدرة</span>
            </h6>

            <h6 className=" mt-3 d-flex align-items-center justify-content-between">
              <span className="border border-dark p-2"></span>
              <span className="text-danger">Cash</span>

              <span className="border border-dark p-2"></span>
              <span className="text-danger">Cheque</span>
              <span
                className={data.cheque_number ? "" : "pb-3"}
                style={{ borderBottom: "1px dotted black", width: "1in" }}
              >
                {data.cheque_number}
              </span>

              <span className="text-danger">Bank</span>
              <span
                className={data.bank ? "" : "pb-3"}
                style={{ borderBottom: "1px dotted black", width: "1in" }}
              >
                {data.bank}
              </span>

              <span className=" text-end">: علئ بنك</span>
              <span
                className="pb-3"
                style={{ borderBottom: "1px dotted black", width: "1in" }}
              ></span>

              <span className=" text-end">: نقدا/شيك رقم</span>
            </h6>

            <h6 className=" mt-3 d-flex align-items-center justify-content-between">
              <span className="text-danger">For</span>

              <span
                className="pb-3"
                style={{ borderBottom: "1px dotted black", width: "6.6in" }}
              ></span>

              <span className=" text-end">: وذلك عن</span>
            </h6>

            <div className="d-flex align-items-center justify-content-around mt-4">
              <div>
                <h6
                  className="text-center pb-5"
                  style={{ borderBottom: "1px dotted black" }}
                >
                  <span className="text-danger me-2">Manager</span>
                  المدير
                </h6>
              </div>
              <div>
                <h6
                  className="text-center pb-5"
                  style={{ borderBottom: "1px dotted black" }}
                >
                  <span className="text-danger me-2">Accountant</span>
                  المحاسب
                </h6>
              </div>

              <div>
                <h6
                  className="text-center pb-5"
                  style={{ borderBottom: "1px dotted black" }}
                >
                  <span className="text-danger me-2">Receiver</span>
                  المستلم
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Receipt;
