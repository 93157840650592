import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import numberToWords from "number-to-words";
import "./sale_invoice.css";
import salelogo from "./salelogo.png";
import invoicelogo from "./invoicelogo.png";
import { toArabicWord } from "number-to-arabic-words/dist/index-node.js";

function Invoice_05() {
  const { name } = useParams();
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("selected_branch"))
  );
  const response = JSON.parse(localStorage.getItem("data"));
  const [data, setdata] = useState(response);

  var quantity = 0;
  const optimize = response.details.map((item, index) => {
    item["sr"] = index + 1;
    if (name === "delivery_notes") {
      quantity = quantity + item.quantity;
    }

    return item;
  });

  const [details, setdetails] = useState(optimize);

  const name_column_formater = (cell, row) => {
    return <div style={{ width: "20vw" }}>{cell}</div>;
  };
  const headerstyle = (column, colIndex) => {
    const englishHeader = column.text.english;
    const arabicHeader = column.text.arabic;

    return (
      <div className="text-center">
        <div style={{ fontSize: 14 }}>{arabicHeader}</div>
        <div style={{ fontSize: 14, fontWeight: "normal" }}>
          {englishHeader}
        </div>
      </div>
    );
  };

  const qty_formatter = (cell, row) => {
    return (
      <div className="text-end pe-5" style={{ width: "10rem" }}>
        {cell}
      </div>
    );
  };

  const unit_formatter = (cell, row) => {
    return <div>PCs</div>;
  };
  const columns = [
    {
      dataField: "sr",
      text: { english: "Si", arabic: "رقم" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_code",
      text: { english: "Code", arabic: " الصنف" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_name",
      text: { english: "Description", arabic: "البيان" },
      formatter: name_column_formater,
      headerFormatter: headerstyle,
    },
    {
      dataField: "quantity",
      text: { english: "Quantity", arabic: "الكمية" },
      formatter: qty_formatter,
      headerFormatter: headerstyle,
    },
  ];

  const columns_1 = [
    {
      dataField: "sr",
      text: { english: "Si", arabic: "رقم" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_code",
      text: { english: "Code", arabic: "الصنف " },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_name",
      text: { english: "Description", arabic: "البيان" },
      formatter: name_column_formater,
      headerFormatter: headerstyle,
    },
    {
      dataField: "quantity",
      text: { english: "Qty", arabic: "الكمية" },
      headerFormatter: headerstyle,
    },

    {
      dataField: "unit",
      text: { english: "Unit", arabic: "الوحدة" },
      headerFormatter: headerstyle,
      formatter: unit_formatter,
    },
    {
      dataField: "price",
      text: { english: "Unit Price", arabic: " سعر الوحدة" },
      headerFormatter: headerstyle,
    },

    {
      dataField: "vat_amount",
      text: { english: "VAT%", arabic: `ضريبة` },
      headerFormatter: headerstyle,
    },

    {
      dataField: "total",
      text: { english: "Amount", arabic: ` السعر الاجمالي` },
      headerFormatter: headerstyle,
    },
  ];

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  return (
    <div className="w-100 " ref={componentRef}>
      {data && (
        <div style={{ fontFamily: "'Times New Roman', Times, serif" }}>
          <div className="row" style={{ color: "#0a90c2", opacity: "1" }}>
            <div className="col-sm-4 ms-3 text-start">
              {/* company.logo */}
              <img
                src={invoicelogo}
                alt="logo"
                width={"250px"}
                height={"80px"}
              ></img>
            </div>
            <div
              style={{ fontSize: 18 }}
              className="col-sm-6 text-end d-flex flex-column justify-content-end"
            >
              <p className="m-0 me-5 text-end">{company.arabic_name}</p>
              <p className="m-0 me-5 text-end">{company.name}</p>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <hr
              className="mb-1"
              style={{
                border: "1px solid #0a90c2",
                borderRadius: "5px",
                backgroundColor: "gray",
                opacity: "0.8",
              }}
            />
            <div
              style={{ position: "absolute", right: "0", top: "-55px" }}
              className="text-start"
            >
              <img
                src={salelogo}
                alt="logo"
                width={"150px"}
                height={"80px"}
              ></img>
            </div>
          </div>

          {name === "sales" && (
            <h5 className="text-center">
              <strong className="border-bottom border-dark border-2 ps-2 pe-2">
                Tax Invoice / فاتورة ضريبية{" "}
              </strong>
            </h5>
          )}
          {name === "purchases" && (
            <h5 className="text-center ">Purchase / الشراء</h5>
          )}
          {name === "quotation" && (
            <h5 className="text-center ">Quotation / عرض السعر</h5>
          )}
          {name === "delivery_notes" && (
            <h5 className="text-center ">Delivery Notes / مذكرات التسليم</h5>
          )}
          {name === "sale_order" && (
            <h5 className="text-center ">Sale Order / امر البيع</h5>
          )}

          {name === "sale_order" && (
            <div className="d-flex justify-content-between mt-2 p-4">
              <div className="col-7 text-left">
                <h6 className="d-flex ">
                  <span className="col-6  ">Name / الاسم:</span>
                  <span className="col-6 ">{data.customer_info.name}</span>
                </h6>
                <h6 className="d-flex ">
                  <span className="col-6">Building Number/رقم المبنى:</span>
                  <span className="col-6">
                    {data.customer_info.building_number}
                  </span>
                </h6>
                <h6 className="d-flex ">
                  <span className="col-6">Street Name/اسم الشارع:</span>
                  <span className="col-6">
                    {data.customer_info.street_number}
                  </span>
                </h6>
                <h6 className="d-flex ">
                  <span className="col-6">District/الحي:</span>
                  <span className="col-6">{data.customer_info.district}</span>
                </h6>
                <h6 className="d-flex ">
                  <span className="col-6">Additional No/رقم إضافي:</span>
                  <span className="col-6">
                    {data.customer_info.additional_number}
                  </span>
                </h6>
                <h6 className="d-flex ">
                  <span className="col-6">Vat Number/رقم الضربية:</span>
                  <span className="col-6">{data.customer_info.vat_number}</span>
                </h6>
              </div>
              <div className="col-5 text-left">
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Order no:</span>{" "}
                  <span className="me-3">{data.order_number}</span>{" "}
                  <span> : رقم الأمر</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> :تاريخ </span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Valid Date:</span>{" "}
                  <span className="me-3">{data.valid_date} </span>{" "}
                  <span> : تاريخ صالح </span>{" "}
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Delivery Date:</span>{" "}
                  <span className="me-3">{data.delivery_date} </span>{" "}
                  <span> : تاريخ التسليم </span>{" "}
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Payment Terms:</span>{" "}
                  <span className="me-3">{data.payment_terms} </span>{" "}
                  <span> : شروط الدفع </span>{" "}
                </h6>
              </div>
            </div>
          )}

          {name === "sales" && (
            <div className="d-flex justify-content-between mt-2 p-4">
              <div
                className="col-9 text-left"
                style={{ fontSize: 14, fontWeight: "bold" }}
              >
                <p className="d-flex m-1 ">
                  <span className="col-4  ">Name / الاسم:</span>
                  <span className="col-6 ">{data.customer_info.name}</span>
                </p>
                <p className="d-flex m-1">
                  <span className="col-4">Building Number/رقم المبنى:</span>
                  <span className="col-6">
                    {data.customer_info.building_number}
                  </span>
                </p>
                <p className="d-flex m-1">
                  <span className="col-4">Street Name/اسم الشارع:</span>
                  <span className="col-6">
                    {data.customer_info.street_number}
                  </span>
                </p>
                <p className="d-flex m-1">
                  <span className="col-4">District/الحي:</span>
                  <span className="col-6">{data.customer_info.district}</span>
                </p>
                <p className="d-flex m-1">
                  <span className="col-4">Additional No/رقم إضافي:</span>
                  <span className="col-6">
                    {data.customer_info.additional_number}
                  </span>
                </p>
                <p className="d-flex m-1">
                  <span className="col-4">Vat Number/رقم الضربية:</span>
                  <span className="col-6">{data.customer_info.vat_number}</span>
                </p>
              </div>
              <div
                className="col-3 text-left"
                style={{ fontSize: 16, fontWeight: "bold" }}
              >
                <p className="d-flex justify-content-between mb-4">
                  <span className="col-6">Invoice no:</span>{" "}
                  <span className="col-6">{data.invoice.split("-")[1]}</span>{" "}
                </p>
                <p className="row mb-4">
                  <span className="col-4">Date:</span>{" "}
                  <span className="col-6">{data.date}</span>{" "}
                </p>
                <p className="d-flex justify-content-between mb-4">
                  <span className="col-6">Ref No:</span>{" "}
                  <span className="col-6">{data.reference_number} </span>{" "}
                </p>
              </div>
            </div>
          )}
          {name === "purchases" && (
            <div className="d-flex justify-content-between mt-2 p-4">
              <div className=" text-left">
                <h5 className="m-0">Supplier Details : تفاصيل العميل</h5>
                <h6 className="m-0">{data.supplier_name}</h6>
              </div>
              <div className=" text-left">
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Invoice no:</span>{" "}
                  <span className="me-3">{data.invoice}</span>{" "}
                  <span> : رقم الفاتورة</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Invoice Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> :تاريخ الفاتورة</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Payment Type:</span>{" "}
                  <span className="me-3">{data.payment_type} </span>{" "}
                  <span> :شروط الدفع </span>{" "}
                </h6>
              </div>
            </div>
          )}
          {name === "quotation" && (
            <div className="d-flex justify-content-between mt-2 p-4">
              <div className="col-7 text-left">
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">Name / الاسم:</span>
                  <span className="col-6">{data.customer_info.name}</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">VAT Number / رقم الضربية:</span>
                  <span className="col-6">{data.customer_info.vat_number}</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">Contact / رقم الاتصال:</span>
                  <span className="col-6">{data.customer_info.contact}</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">Address / الاسم:</span>
                  <span className="col-6">{data.customer_info.address}</span>
                </h6>
              </div>
              <div className="col-5 text-left">
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Quotation no:</span>{" "}
                  <span className="me-3">{data.quotation_number}</span>{" "}
                  <span> : رقم الفاتورة</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> :تاريخ </span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Valid Date:</span>{" "}
                  <span className="me-3">{data.valid_date} </span>{" "}
                  <span> : تاريخ الصلاحية </span>{" "}
                </h6>
              </div>
            </div>
          )}

          {name === "delivery_notes" && (
            <div className="d-flex justify-content-between mt-2 p-4">
              <div className="col-7 text-left">
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">Name / الاسم:</span>
                  <span className="col-6">{data.customer_info.name}</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">VAT Number / رقم الضربية:</span>
                  <span className="col-6">{data.customer_info.vat_number}</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">Contact / رقم الاتصال:</span>
                  <span className="col-6">{data.customer_info.contact}</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="col-6">Address / الاسم:</span>
                  <span className="col-6">{data.customer_info.address}</span>
                </h6>
              </div>
              <div className="col-5 text-left">
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Delivery no:</span>{" "}
                  <span className="me-3">{data.delivery_number}</span>{" "}
                  <span> : رقم التوصيل</span>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span className="me-3">Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> :تاريخ </span>
                </h6>
              </div>
            </div>
          )}

          {name !== "delivery_notes" ? (
            <div className="m-4 mb-0 mt-3 upper_wrapper">
              <BootstrapTable
                keyField="id"
                data={details}
                columns={columns_1}
                bordered={false}
                bootstrap4
                condensed
                classes="table_class"
              />
            </div>
          ) : (
            <div className="m-4 mb-0 mt-3 upper_wrapper">
              <BootstrapTable
                keyField="id"
                data={details}
                columns={columns}
                bordered={false}
                bootstrap4
                condensed
                classes="table_class_1"
              />
            </div>
          )}

          <div className="m-4 mt-0  ps-2  d-flex   justify-content-between lower-main-div">
            {name === "sales" && (
              <div className="pt-3 ps-4 pb-3  me-3">
                <QRCode
                  value={data.qr_code}
                  bgColor={"#FFFFFF"}
                  fgColor={"#000000"}
                  size={130}
                />
              </div>
            )}

            <div className="pt-3 pe-1 text-start" style={{ width: "3in" }}>
              <p
                className="text-start"
                style={{ fontSize: 14, fontWeight: "bold", minHeight: "0.5in" }}
              >
                {data.remarks}
              </p>
              {data.total && (
                <p style={{ fontSize: 12, fontWeight: "bold" }}>
                  Saudi Riyal {numberToWords.toWords(data.total)}
                </p>
              )}
              {data.total && (
                <p
                  className="text-end"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {toArabicWord(data.total)}
                </p>
              )}
            </div>
            {name !== "delivery_notes" ? (
              <div>
                <div className=" d-flex">
                  <h6 className="total m-0 p-1" style={{ width: "120px" }}>
                    مجموع اجمالي
                    <br />
                    Total Gross
                  </h6>
                  <h6
                    className="total m-0 p-2 text-center"
                    style={{ width: "150px", fontWeight: "bold" }}
                  >
                    {data.sub_total && data.sub_total.toFixed(2)}
                  </h6>
                </div>
                <div className=" d-flex">
                  <h6 className="total m-0 p-1" style={{ width: "120px" }}>
                    خصم
                    <br />
                    Dicount
                  </h6>
                  <h6
                    className="total m-0 p-2 text-center"
                    style={{ width: "150px", fontWeight: "bold" }}
                  >
                    {data.discount &&
                      (
                        parseFloat(data.discount) + parseFloat(data.extra_disc)
                      ).toFixed(2)}
                  </h6>
                </div>
                <div className=" d-flex">
                  <h6 className="total m-0 p-1" style={{ width: "120px" }}>
                    مجموع الضريبة
                    <br />
                    TotalVAT
                  </h6>
                  <h6
                    className="total  m-0 p-2 text-center"
                    style={{ width: "150px", fontWeight: "bold" }}
                  >
                    {data.vat_amount && data.vat_amount.toFixed(2)}
                  </h6>
                </div>
                <div className=" d-flex">
                  <h6 className="total_1 m-0 p-1" style={{ width: "120px" }}>
                    صافي اجمالي
                    <br />
                    Grand Total
                  </h6>
                  <h6
                    className=" m-0 p-2 text-center"
                    style={{ width: "150px", fontWeight: "bold" }}
                  >
                    {data.total && data.total.toFixed(2)}
                  </h6>
                </div>
              </div>
            ) : (
              <div className=" d-flex">
                <h6 className="total_1 m-0 p-1" style={{ width: "120px" }}>
                  {" "}
                  إجمالي الكمية
                  <br />
                  Total QTY
                </h6>
                <h6
                  className=" m-0 p-2 text-center"
                  style={{ width: "150px", fontWeight: "bold" }}
                >
                  {quantity}
                </h6>
              </div>
            )}
          </div>

          <hr
            className="mb-1"
            style={{
              border: "1px solid #0a90c2",
              borderRadius: "5px",
              opacity: "0.8",
            }}
          />

          <div
            className=" mt-2 d-flex justify-content-between foot"
            style={{ color: "#0a90c2" }}
          >
            <div className="col-sm-3 m-0 ms-2 me-1 ">
              <p className="foot">{company.address}</p>
            </div>
            <div className="p-2 col-sm-5">
              <p className="m-0 text-center foot">
                VAT : {company.vat_number}{" "}
                <span className="text-dark"> | </span> C.R : {company.cr_number}
              </p>
              <p className="m-0 text-center foot">{company.email}</p>
            </div>
            <div className="col-sm-3 m-0 ms-1 me-2">
              <p className="text-end foot">{company.address_arabic}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Invoice_05;
