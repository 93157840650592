import React from "react";

function Nopage() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "30px",
        fontWeight: "bold",
        opacity: "0.7",
      }}
    >
      No Page Found
    </div>
  );
}

export default Nopage;
