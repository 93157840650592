import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";

import Layout from "../Container/homeContainer";
import User from "../Container/userContainer";
import Payement_customer from "../Container/payemntConatiner";
import Payement_salesman from "../Container/sales_paymentContainer";
import Sale_invoice from "../Container/invoiceContainer";
import Branch from "../Container/branchContainer";
import Company from "../Container/companyContainer";
import Customer from "../Container/customerContainer";
import Supplier from "../Container/supplierContainer";
import Sale_person from "../Container/sale_personContainer";
import Categories from "../Container/categoryContainer";
import Subcategories from "../Container/subcategpryContainer";
import Product from "../Container/productContainer";
import Branch_manage from "../Container/branchmanagContainer";
import Delivery_man from "../Container/delivery_manContainer";
import Supplier_Payment from "../Container/supplierpaymentContainer";
import Payment_type from "../Container/paymenttypeContainer";
import Sale from "../Container/saleContainer";
import Dashboard from "../Container/dashboardContainer";
import Purchase from "../Container/purchaseContainer";
import Purchase_Edit from "../Container/purchaseeditContainer";
import Purchase_history from "../Container/purchasehistpryContainer";
import City from "../Container/cityContainer";

import PurchaseReturn from "../Container/purchasereturnContainer";
import PurchaseReturn_Edit from "../Container/purchasereturneditContainer";
import PurchaseReturn_history from "../Container/purchasereturnhistoryConatiner";

import Sale_history from "../Container/salehistoryContainer";
import Sale_Edit from "../Container/saleeditContainer";
import Quotation from "../Container/quotationContainer";
import Quotation_Edit from "../Container/quotationeditContainer";
import Quotation_history from "../Container/quotationhistoryContainer";
import Sale_Report from "../Container/salerportContainer";
import Purchase_Report from "../Container/purchasereportContainer";
import Summary_Report from "../Container/summaryreportContainer";

import Sale_order from "../Container/saleorderContainer";
import Sale_order_Edit from "../Container/saleordereditContainer";
import Sale_order_history from "../Container/saleorderhistory";
import Sale_order_report from "../Container/salorderreportContainer";
import Login from "../Container/loginContainer";
import Invoice_2 from "../Components/invoices/invoice_2";
import Invoice_05 from "../Components/invoices/saleinvoice05";
import Invoice_3 from "../Components/invoices/invoice_3";
import Invoice04 from "../Components/invoices/saleinvoice04";
import Invoice_06 from "../Components/invoices/invoice_06";
import Receipt from "../Components/invoices/receipt";
import Delivery_notes_Edit from "../Container/deliverynoteseditContainer";
import Delivery_notes_history from "../Container/deliverynoteshistoryContainer";
import Delivery_notes from "../Container/deliverynotesContainer";
import Expenses from "../Container/expensesContainer";
import CustomerVoucher from "../Container/customervoucherContainer";
import SupplierVoucher from "../Container/suppliervoucherContainer";
import Salereturn from "../Container/salereturnContainer";
import SaleReturn_Edit from "../Container/salereturneditContainer";
import SaleReturn_History from "../Container/salereturnhistoryContainer";
import SaleQuoation from "../Container/salefquotationContainer";
import Balance_report from "../Container/balancereportContainer";
import Usermanagement from "../Pages/user_page/user_page";
import Nopage from "./nopage";

function Routing(props) {
  const user = props.state.setuser.user;
  const current_user = props.state.Setcurrentinfo.current_user;
  return (
    <BrowserRouter>
      <ProSidebarProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={!user ? <Login /> : <Navigate to={"/dashboard"} />}
            ></Route>

            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/user"
              element={user ? <User /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/balance-report"
              element={user ? <Balance_report /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/branch"
              element={user ? <Branch /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/company_management"
              element={user ? <Branch_manage /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/user_management"
              element={
                user ? (
                  <Usermanagement current_user={current_user} />
                ) : (
                  <Navigate to={"/"} />
                )
              }
            ></Route>
            <Route
              path="/customer"
              element={user ? <Customer /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/supplier"
              element={user ? <Supplier /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/city"
              element={user ? <City /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_person"
              element={user ? <Sale_person /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/delivery_man"
              element={user ? <Delivery_man /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/categories"
              element={user ? <Categories /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/subcategories"
              element={user ? <Subcategories /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/company"
              element={user ? <Company /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/products"
              element={user ? <Product /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/payment_customer"
              element={user ? <Payement_customer /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/payment_supplier"
              element={user ? <Supplier_Payment /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/payment_salesman"
              element={user ? <Payement_salesman /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/voucher_customer"
              element={user ? <CustomerVoucher /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/voucher_supplier"
              element={user ? <SupplierVoucher /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/expenses"
              element={user ? <Expenses /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/purchase"
              element={user ? <Purchase /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/Sale"
              element={user ? <Sale /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_from_quotation"
              element={user ? <SaleQuoation /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/Purchase_history"
              element={user ? <Purchase_history /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/purchase_Edit"
              element={user ? <Purchase_Edit /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/sale_return"
              element={user ? <Salereturn /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_return_edit"
              element={user ? <SaleReturn_Edit /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_return_history"
              element={user ? <SaleReturn_History /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/purchase_return"
              element={user ? <PurchaseReturn /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/Purchasereturn_history"
              element={
                user ? <PurchaseReturn_history /> : <Navigate to={"/"} />
              }
            ></Route>
            <Route
              path="/purchasereturn_Edit"
              element={user ? <PurchaseReturn_Edit /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/sale_history"
              element={user ? <Sale_history /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_edit"
              element={user ? <Sale_Edit /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/payment_type"
              element={user ? <Payment_type /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/quotation"
              element={user ? <Quotation /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/quotation_edit"
              element={user ? <Quotation_Edit /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/quotation_history"
              element={user ? <Quotation_history /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_report"
              element={user ? <Sale_Report /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/purchase_report"
              element={user ? <Purchase_Report /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/summary_report"
              element={user ? <Summary_Report /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/sale_order"
              element={user ? <Sale_order /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_order_edit"
              element={user ? <Sale_order_Edit /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_order_history"
              element={user ? <Sale_order_history /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/sale_order_report"
              element={user ? <Sale_order_report /> : <Navigate to={"/"} />}
            ></Route>

            <Route
              path="/delivery_notes"
              element={user ? <Delivery_notes /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/delivery_notes_edit"
              element={user ? <Delivery_notes_Edit /> : <Navigate to={"/"} />}
            ></Route>
            <Route
              path="/delivery_notes_history"
              element={
                user ? <Delivery_notes_history /> : <Navigate to={"/"} />
              }
            ></Route>
          </Route>
          <Route
            path="/invoice/:name"
            element={user && <Sale_invoice />}
          ></Route>
          <Route
            path="/invoice_2/:name"
            element={user && <Invoice_2 />}
          ></Route>
          <Route
            path="/invoice_3/:name"
            element={user && <Invoice_3 />}
          ></Route>
          <Route
            path="/invoice_4/:name"
            element={user && <Invoice04 />}
          ></Route>
          <Route
            path="/invoice_5/:name"
            element={user && <Invoice_05 />}
          ></Route>
          <Route
            path="/invoice_6/:name"
            element={user && <Invoice_06 />}
          ></Route>
          <Route path="/receipt/:name" element={user && <Receipt />}></Route>
          <Route path="*" element={<Nopage />}></Route>
        </Routes>
      </ProSidebarProvider>
    </BrowserRouter>
  );
}

export default Routing;
