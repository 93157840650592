import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import numberToWords from "number-to-words";

// import "./saleinvoice1.css";
import "./sale_invoice.css";

import { toArabicWord } from "number-to-arabic-words/dist/index-node.js";

function Invoice(props) {
  const { name } = useParams();
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("selected_branch"))
  );
  const response = JSON.parse(localStorage.getItem("data"));
  const [data, setdata] = useState(response);
  var quantity = 0;
  const optimize = response.details.map((item, index) => {
    item["sr"] = index + 1;
    if (name === "delivery_notes") {
      quantity = quantity + item.quantity;
    }
    return item;
  });
  if (name === "delivery_notes") {
    optimize.push({
      sr: "",
      id: "",
      product_name: "",
      product_code: "",
      quantity: `Total : ${quantity}`,
      delivery_note: "",
      product: "",
    });
  }

  const [details, setdetails] = useState(optimize);

  const name_column_formater = (cell, row) => {
    return <div style={{ width: "20vw" }}>{cell}</div>;
  };
  const headerstyle = (column, colIndex) => {
    const englishHeader = column.text.english;
    const arabicHeader = column.text.arabic;

    return (
      <div>
        <div>{englishHeader}</div>
        <div>{arabicHeader}</div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "sr",
      text: { english: "SR", arabic: "" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_code",
      text: { english: "Item  Code", arabic: "رقم الصنف" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_name",
      text: { english: "Description", arabic: "وصف" },
      formatter: name_column_formater,
      headerFormatter: headerstyle,
    },
    {
      dataField: "quantity",
      text: { english: "QTY", arabic: "الكمية" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "price",
      text: { english: "Unit Price", arabic: " سعر الوحدة" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "sub_total",
      text: { english: "Total", arabic: ` السعر الاجمالي` },
      headerFormatter: headerstyle,
    },
  ];

  const columns_1 = [
    {
      dataField: "sr",
      text: { english: "SR", arabic: "" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_code",
      text: { english: "Item  Code", arabic: "رقم الصنف" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_name",
      text: { english: "Description", arabic: "وصف" },
      formatter: name_column_formater,
      headerFormatter: headerstyle,
    },
    {
      dataField: "quantity",
      text: { english: "QTY", arabic: "الكمية" },
      headerFormatter: headerstyle,
    },
  ];

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  return (
    <div className=" w-100 " ref={componentRef}>
      {data && (
        <div className="container_1 p-4">
          <div className="row ">
            <div className="col-sm-4 text-center">
              <h6 className="m-0">
                <strong>{company.name}</strong>
              </h6>

              <p className="m-0" style={{ fontWeight: "normal" }}>
                CR No : {company.cr_number}
              </p>
              <p className="m-0" style={{ fontWeight: "normal" }}>
                VAT No: {company.vat_number}
              </p>
              <p className="m-0" style={{ fontWeight: "normal" }}>
                Mobile No: {company.contact}
              </p>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={company.logo}
                alt="logo"
                width={"100"}
                height={"80"}
              ></img>
            </div>
            <div className="col-sm-4 text-center">
              <h6 className="m-0">
                <strong>{company.arabic_name}</strong>
              </h6>
              <p className="m-0" style={{ fontWeight: "normal" }}>
                {" "}
                التسجيل التجاري:{company.cr_number_arabic}{" "}
              </p>
              <p className="m-0" style={{ fontWeight: "normal" }}>
                رقم تسجيل ضريبة القيمة المضافة: هذا هو رقم ضريبة القيمة المضافة
                باللغة العربية
              </p>
            </div>
          </div>

          {name === "sales" && (
            <h5
              className="text-center mt-2 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              SIMPLIFIED TAX INVOICE / فاتورة ضريبية مبسطة
            </h5>
          )}
          {name === "purchases" && (
            <h5
              className="text-center mt-4 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              Purchase / الشراء
            </h5>
          )}
          {name === "quotation" && (
            <h5
              className="text-center mt-4 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              Quotation / عرض السعر
            </h5>
          )}
          {name === "sale_order" && (
            <h5
              className="text-center mt-4 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              Sale Order / امر البيع
            </h5>
          )}
          {name === "delivery_notes" && (
            <h4 className=" m-2 text-center">
              Delivery Notes - مذكرات التسليم
            </h4>
          )}

          {name === "sales" && (
            <div
              className="d-flex justify-content-between mt-1 p-2 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              <div className=" text-left">
                <h6 className="m-0">Customer Details : تفاصيل العميل</h6>
                <p className="m-0">{data.customer_info.name}</p>
                <p className="m-0">VAT No: {data.customer_info.vat_number}</p>
                <p className="m-0">COntact: {data.customer_info.contact}</p>
                <p className="m-0">Address: {data.customer_info.address}</p>
              </div>
              <div className=" text-left">
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Invoive no:</span>{" "}
                  <span className="me-3">{data.invoice}</span>{" "}
                  <span> : رقم الفاتورة</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Invoive Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> :تاريخ الفاتورة</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Payment Type:</span>{" "}
                  <span className="me-3">{data.payment_type} </span>{" "}
                  <span> :شروط الدفع </span>{" "}
                </p>
              </div>
            </div>
          )}

          {name === "purchases" && (
            <div
              className="d-flex justify-content-between mt-1 p-2 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              <div className=" text-left">
                <h6 className="m-0">Supplier Details : تفاصيل العميل</h6>
                <p className="m-0">{data.supplier_name}</p>
              </div>
              <div className=" text-left">
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Invoive no:</span>{" "}
                  <span className="me-3">{data.invoice}</span>{" "}
                  <span> : رقم الفاتورة</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Invoive Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> :تاريخ الفاتورة</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Payment Type:</span>{" "}
                  <span className="me-3">{data.payment_type} </span>{" "}
                  <span> :شروط الدفع </span>{" "}
                </p>
              </div>
            </div>
          )}

          {name === "quotation" && (
            <div
              className="d-flex justify-content-between mt-1 p-2 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              <div className=" text-left">
                <h6 className="m-0">Customer Details : تفاصيل العميل</h6>
                <p className="m-0">{data.customer_info.name}</p>
                <p className="m-0">VAT No: {data.customer_info.vat_number}</p>
                <p className="m-0">COntact: {data.customer_info.contact}</p>
                <p className="m-0">Address: {data.customer_info.address}</p>
              </div>
              <div className=" text-left">
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Quotation no:</span>{" "}
                  <span className="me-3">{data.quotation_number}</span>{" "}
                  <span> : رقم الفاتورة</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> :تاريخ </span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Valid Date:</span>{" "}
                  <span className="me-3">{data.valid_date} </span>{" "}
                  <span> : تاريخ الصلاحية </span>{" "}
                </p>
              </div>
            </div>
          )}

          {name === "sale_order" && (
            <div
              className="d-flex justify-content-between mt-1 p-2 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              <div className=" text-left">
                <h6 className="m-0">Customer Details : تفاصيل العميل</h6>
                <p className="m-0">{data.customer_info.name}</p>
                <p className="m-0">VAT No: {data.customer_info.vat_number}</p>
                <p className="m-0">COntact: {data.customer_info.contact}</p>
                <p className="m-0">Address: {data.customer_info.address}</p>
              </div>
              <div className=" text-left">
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">order no:</span>{" "}
                  <span className="me-3">{data.order_number}</span>{" "}
                  <span> : رقم الأمر</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Order Date:</span>{" "}
                  <span className="me-3">{data.date}</span>{" "}
                  <span> : تاريخ الطلب</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Delivery Date:</span>{" "}
                  <span className="me-3">{data.delivery_date} </span>{" "}
                  <span> : تاريخ التسليم </span>{" "}
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Payment Terms:</span>{" "}
                  <span className="me-3">{data.payment_terms} </span>{" "}
                  <span> :شروط الدفع </span>{" "}
                </p>
              </div>
            </div>
          )}

          {name !== "delivery_notes" ? (
            <div className="m-1 mt-2 " style={{ minHeight: "5in" }}>
              <BootstrapTable
                keyField="id"
                data={details}
                columns={columns}
                bordered={true}
                bootstrap4
                condensed
                classes="table_class_11"
              />
            </div>
          ) : (
            <div className="m-1 mt-2 " style={{ minHeight: "5in" }}>
              <BootstrapTable
                keyField="id"
                data={details}
                columns={columns_1}
                bordered={true}
                bootstrap4
                condensed
                classes="table_class_11"
              />
            </div>
          )}

          {name !== "delivery_notes" && (
            <div className="mt-1 d-flex justify-content-center">
              {name === "sales" && (
                <div className="me-1">
                  <QRCode
                    value={data.qr_code}
                    bgColor={"#FFFFFF"}
                    fgColor={"#000000"}
                    size={120}
                  />
                </div>
              )}
              {name === "sales" ? (
                <div
                  className="col-sm-10 p-2 d-flex justify-content-between"
                  style={{ border: "1px solid black", borderRadius: "5px" }}
                >
                  <div>
                    <p className="m-0">Total (Excluding VAT)</p>
                    <p className="m-0">Discount</p>
                    <p className="m-0">Total (Taxable Amount Excluding VAT)</p>
                    <p className="m-0">Total VAT</p>
                    <p className="m-0">Total Amount Due</p>
                  </div>
                  <div>
                    <p className="m-0">
                      اإلجمالي غير شاملة ضريبة القيمة المضافة
                    </p>
                    <p className="m-0"> مجموع الخصومات</p>
                    <p className="m-0">
                      اإلجمالي الخاضع للضريبة شاملة ضريبة القيمة المضافة
                    </p>
                    <p className="m-0"> مجموع ضريبة القيمة المضاف</p>
                    <p className="m-0"> إجمالي المبلغ المستحق</p>
                  </div>
                  <div className="text-end">
                    <p className="m-0">{data.sub_total}</p>
                    <p className="m-0">{data.discount}</p>
                    <p className="m-0">{data.sub_total}</p>
                    <p className="m-0">{data.vat_amount}</p>
                    <p className="m-0">{data.total}</p>
                  </div>
                </div>
              ) : (
                <div
                  className="col-sm-12 p-2 d-flex justify-content-between "
                  style={{ border: "1px solid black", borderRadius: "5px" }}
                >
                  <div>
                    <p className="m-0">Total (Excluding VAT)</p>
                    <p className="m-0">Discount</p>
                    <p className="m-0">
                      Total (Taxable Amount <br />
                      Excluding VAT)
                    </p>
                    <p className="m-0">Total VAT</p>
                    <p className="m-0">Total Amount Due</p>
                  </div>
                  <div>
                    <p className="m-0">
                      اإلجمالي غير شاملة ضريبة القيمة المضافة
                    </p>
                    <p className="m-0"> مجموع الخصومات</p>
                    <p className="m-0">
                      اإلجمالي الخاضع للضريبة شاملة
                      <br /> ضريبة القيمة المضافة
                    </p>
                    <p className="m-0"> مجموع ضريبة القيمة المضاف</p>
                    <p className="m-0"> إجمالي المبلغ المستحق</p>
                  </div>
                  <div className="text-end">
                    <p className="m-0">{data.sub_total}</p>
                    <p className="m-0">{data.discount}</p>
                    <p className="m-0">{data.sub_total}</p>
                    <br />
                    <p className="m-0">{data.vat_amount}</p>
                    <p className="m-0">{data.total}</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {name !== "delivery_notes" ? (
            <div className="mt-2 d-flex justify-content-center">
              <div
                className="col-sm-6 m-0 me-1 d-flex flex-column  justify-content-center"
                style={{ border: "1px solid black", borderRadius: "5px" }}
              >
                <h6 className="text-center mb-0">ملاحظات / Notes</h6>
                <p className="m-0 p-3" style={{ fontWeight: "normal" }}>
                  {data.remarks}
                </p>
              </div>
              <div
                className="p-2 col-sm-6 "
                style={{ border: "1px solid black", borderRadius: "5px" }}
              >
                <p className="mb-0">
                  <strong>SAR: </strong>
                  {numberToWords.toWords(data.total).toUpperCase()}
                </p>
                <p>
                  <strong>{toArabicWord(data.total)} : ريال سعودي</strong>
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-2 d-flex justify-content-center">
              <div
                className="col-sm-12 m-0 me-1 d-flex flex-column  justify-content-center"
                style={{ border: "1px solid black", borderRadius: "5px" }}
              >
                <h6 className="text-center mb-0">ملاحظات / Notes</h6>
                <p className="m-0 p-3" style={{ fontWeight: "normal" }}>
                  {data.remarks}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Invoice;
