import React, { useState, useRef } from "react";
import "./home.css";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Outlet } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StoreIcon from "@material-ui/icons/Store";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import Header from "../Container/headerContainer";
import Footer from "../Components/footer";

import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import HistoryIcon from "@material-ui/icons/History";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import CategoryIcon from "@material-ui/icons/Category";
import FilterListIcon from "@material-ui/icons/FilterList";
import TurnedInIcon from "@material-ui/icons/TurnedIn";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function Layout(props) {
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const menu_status = props.state.Settablehistory.menu_status;
  const current_user = props.state.Setcurrentinfo.current_user;

  const { collapseSidebar, toggleSidebar, broken, collapsed } = useProSidebar();
  const [checkcollapse, setcheckcollapse] = useState(true);

  const handlemouseleave = () => {
    if (checkcollapse && !collapsed) {
      collapseSidebar();
    }
  };

  const handlemouseenter = () => {
    if (checkcollapse && collapsed) {
      collapseSidebar();
    }
  };
  return (
    <div id="app" className="layout d-flex" style={{ zoom: ".8" }}>
      {user && (
        <Sidebar
          breakPoint="md"
          defaultCollapsed={true}
          rootStyles={{
            color: "whitesmoke",
          }}
          backgroundColor="#333"
        >
          <div
            style={{
              textAlign: "center",
              borderBottom: "1px solid #dee2e6",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar className="mt-1 mb-1 ms-3 me-4" />
            <h5>{current_user && current_user.username}</h5>
          </div>
          <Menu
            style={{ height: "100vh", overflow: "auto" }}
            className="sidebarclass"
            onMouseEnter={handlemouseenter}
            onMouseLeave={handlemouseleave}
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0 || level === 1) {
                  return {
                    color: active ? "skyblue" : undefined,
                    "&:hover": {
                      backgroundColor: "#335B8C !important",
                      color: "white !important",
                      borderRadius: "8px !important",
                      fontWeight: "bold !important",
                    },
                  };
                }
              },
            }}
          >
            {current_user?.permissions?.includes("view_dashboard") && (
              <MenuItem
                active={menu_status === "dashboard"}
                icon={<DashboardIcon />}
                component={<Link to="/dashboard" />}
              >
                {t("side_bar_dashboard")}
              </MenuItem>
            )}

            <SubMenu
              active={
                menu_status === "user" ||
                menu_status === "company" ||
                menu_status === "company_management" ||
                menu_status === "payment_type" ||
                menu_status === "user_management" ||
                menu_status === "city"
              }
              label={t("side_bar_menu")}
              icon={<MenuIcon />}
            >
              {current_user?.permissions?.includes("view_user") && (
                <MenuItem
                  active={menu_status === "user"}
                  icon={<PersonIcon />}
                  component={<Link to="/user" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_user")}{" "}
                </MenuItem>
              )}

              {current_user?.profile?.user_type !== "user" && (
                <MenuItem
                  active={menu_status === "user_management"}
                  icon={<StoreIcon />}
                  component={<Link to="/user_management" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("user_management")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_company") && (
                <MenuItem
                  active={menu_status === "company"}
                  icon={<AccountBalanceIcon />}
                  component={<Link to="/company" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_company")}{" "}
                </MenuItem>
              )}
              {current_user?.profile?.user_type !== "user" && (
                <MenuItem
                  active={menu_status === "company_management"}
                  icon={<StoreIcon />}
                  component={<Link to="/company_management" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_companymanagement")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_ payment_type") && (
                <MenuItem
                  active={menu_status === "payment_type"}
                  icon={<FontAwesomeIcon icon={faUsersGear} />}
                  component={<Link to="/payment_type" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_paymnettype")}{" "}
                </MenuItem>
              )}
              <MenuItem
                active={menu_status === "city"}
                icon={<AccountBalanceIcon />}
                component={<Link to="/city" />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
              >
                {" "}
                {t("city")}{" "}
              </MenuItem>
            </SubMenu>

            <SubMenu
              active={
                menu_status === "customer" ||
                menu_status === "supplier" ||
                menu_status === "sale_person" ||
                menu_status === "delivery_man"
              }
              label={t("side_bar_account")}
              icon={<SupervisorAccountIcon />}
            >
              {current_user?.permissions?.includes("view_customer") && (
                <MenuItem
                  active={menu_status === "customer"}
                  icon={<SupervisorAccountIcon />}
                  component={<Link to="/customer" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_customers")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_supplier") && (
                <MenuItem
                  active={menu_status === "supplier"}
                  icon={<BusinessCenterIcon />}
                  component={<Link to="/supplier" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_suppliers")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_saleman") && (
                <MenuItem
                  active={menu_status === "sale_person"}
                  icon={<FontAwesomeIcon icon={faPerson} />}
                  component={<Link to="/sale_person" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_salepersons")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_deliveryman") && (
                <MenuItem
                  active={menu_status === "delivery_man"}
                  icon={<DirectionsBikeIcon />}
                  component={<Link to="/delivery_man" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_deliveryen")}{" "}
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              active={
                menu_status === "products" ||
                menu_status === "subcategories" ||
                menu_status === "categories"
              }
              label={t("side_bar_product")}
              icon={<FontAwesomeIcon icon={faTag} size="lg" />}
            >
              {current_user?.permissions?.includes("view_categories") && (
                <MenuItem
                  active={menu_status === "categories"}
                  icon={<CategoryIcon />}
                  component={<Link to="/categories" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_category")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_subcategories") && (
                <MenuItem
                  active={menu_status === "subcategories"}
                  icon={<FilterListIcon />}
                  component={<Link to="/subcategories" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_subcategory")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_products") && (
                <MenuItem
                  active={menu_status === "products"}
                  icon={<TurnedInIcon />}
                  component={<Link to="/products" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_product")}{" "}
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              active={
                menu_status === "purchase" ||
                menu_status === "Purchase_history" ||
                menu_status === "purchasereturn" ||
                menu_status === "Purchasereturn_history"
              }
              label={t("side_bar_purchase")}
              icon={<ShoppingCartIcon />}
            >
              {current_user?.permissions?.includes("add_purchase") && (
                <MenuItem
                  active={menu_status === "purchase"}
                  icon={<ShoppingBasketIcon />}
                  component={<Link to="/purchase" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_purchase")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_purchase") && (
                <MenuItem
                  active={menu_status === "Purchase_history"}
                  icon={<HistoryIcon />}
                  component={<Link to="/Purchase_history" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_purchasehistory")}
                </MenuItem>
              )}

              {current_user?.permissions?.includes("add_purchasereturn") && (
                <MenuItem
                  active={menu_status === "purchasereturn"}
                  icon={<ShoppingBasketIcon />}
                  component={<Link to="/purchase_return" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_purchasereturn")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_purchasereturn") && (
                <MenuItem
                  active={menu_status === "Purchasereturn_history"}
                  icon={<HistoryIcon />}
                  component={<Link to="/Purchasereturn_history" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_purchasereturnhistory")}
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              active={
                menu_status === "sale" ||
                menu_status === "sale_history" ||
                menu_status === "sale_order" ||
                menu_status === "sale_order_history" ||
                menu_status === "salereturn" ||
                menu_status === "salereturn_history"
              }
              label={t("side_bar_sale")}
              icon={<LocalAtmIcon />}
            >
              {current_user?.permissions?.includes("add_sale") && (
                <MenuItem
                  active={menu_status === "sale"}
                  icon={<LocalAtmIcon />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/sale" />}
                >
                  {" "}
                  {t("side_bar_sale")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_sale") && (
                <MenuItem
                  active={menu_status === "sale_history"}
                  icon={<HistoryIcon />}
                  component={<Link to="/sale_history" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_salehistory")}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("add_salereturn") && (
                <MenuItem
                  active={menu_status === "salereturn"}
                  icon={<ShoppingBasketIcon />}
                  component={<Link to="/sale_return" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_salereturn")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_salereturn") && (
                <MenuItem
                  active={menu_status === "salereturn_history"}
                  icon={<HistoryIcon />}
                  component={<Link to="/sale_return_history" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_salereturnhistory")}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("add_saleorder") && (
                <MenuItem
                  active={menu_status === "sale_order"}
                  icon={<LocalAtmIcon />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/sale_order" />}
                >
                  {" "}
                  {t("side_bar_sale_order")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_saleorder") && (
                <MenuItem
                  active={menu_status === "sale_order_history"}
                  icon={<HistoryIcon />}
                  component={<Link to="/sale_order_history" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("side_bar_sale_orderhistory")}
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              active={
                menu_status === "quotation" ||
                menu_status === "quotation_history"
              }
              label={t("side_bar_quotation")}
              icon={<FontAwesomeIcon icon={faBook} />}
            >
              {current_user?.permissions?.includes("add_quotation") && (
                <MenuItem
                  active={menu_status === "quotation"}
                  icon={<FontAwesomeIcon icon={faBook} />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/quotation" />}
                >
                  {" "}
                  {t("side_bar_quotation")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_quotation") && (
                <MenuItem
                  active={menu_status === "quotation_history"}
                  icon={<HistoryIcon />}
                  component={<Link to="/quotation_history" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {t("side_bar_quotationhistory")}
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              active={
                menu_status === "delivery_notes" ||
                menu_status === "delivery_notes_history"
              }
              label={t("side_bar_delivery_notes")}
              icon={<FontAwesomeIcon icon={faTruck} />}
            >
              {current_user?.permissions?.includes("add_delivery_notes") && (
                <MenuItem
                  active={menu_status === "delivery_notes"}
                  icon={<FontAwesomeIcon icon={faTruck} />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/delivery_notes" />}
                >
                  {" "}
                  {t("side_bar_delivery_notes")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_delivery_notes") && (
                <MenuItem
                  active={menu_status === "delivery_notes_history"}
                  icon={<HistoryIcon />}
                  component={<Link to="/delivery_notes_history" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {t("side_bar_delivery_noteshistory")}
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              active={
                menu_status === "voucher_customer" ||
                menu_status === "voucher_supplier"
              }
              label={t("side_bar_voucher")}
              icon={<FontAwesomeIcon icon={faReceipt} />}
            >
              {current_user?.permissions?.includes("view_voucher_customer") && (
                <MenuItem
                  active={menu_status === "voucher_customer"}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                  component={<Link to="/voucher_customer" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("customer_voucher")}{" "}
                </MenuItem>
              )}

              {current_user?.permissions?.includes("view_voucher_supplier") && (
                <MenuItem
                  active={menu_status === "voucher_supplier"}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                  component={<Link to="/voucher_supplier" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("supplier_voucher")}{" "}
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              active={
                menu_status === "payment_customer" ||
                menu_status === "payment_supplier" ||
                menu_status === "payment"
              }
              label={t("side_bar_paymnt")}
              icon={<AccountBalanceWalletIcon />}
            >
              {current_user?.permissions?.includes("view_payment_customer") && (
                <MenuItem
                  active={menu_status === "payment_customer"}
                  icon={<FontAwesomeIcon icon={faMoneyCheckDollar} />}
                  component={<Link to="/payment_customer" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("customer_payment")}{" "}
                </MenuItem>
              )}

              {current_user?.permissions?.includes("view_payment_supplier") && (
                <MenuItem
                  active={menu_status === "payment_supplier"}
                  icon={<FontAwesomeIcon icon={faCreditCard} />}
                  component={<Link to="/payment_salesman" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("saleperson_payment")}{" "}
                </MenuItem>
              )}

              {current_user?.permissions?.includes("view_payment") && (
                <MenuItem
                  active={menu_status === "payment"}
                  icon={<AccountBalanceWalletIcon />}
                  component={<Link to="/payment_supplier" />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                >
                  {" "}
                  {t("supplier_payment")}{" "}
                </MenuItem>
              )}
            </SubMenu>

            {current_user?.permissions?.includes("view_expenses") && (
              <MenuItem
                active={menu_status === "expenses"}
                icon={<ShoppingBasketIcon />}
                component={<Link to="/expenses" />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
              >
                {" "}
                {t("expenses")}{" "}
              </MenuItem>
            )}

            <SubMenu
              active={
                menu_status === "summary_report" ||
                menu_status === "sale_report" ||
                menu_status === "purchase_report" ||
                menu_status === "sale_order_report" ||
                menu_status === "balance_report"
              }
              label={t("side_bar_report")}
              icon={<AssessmentIcon />}
            >
              {current_user?.permissions?.includes("view_sale_report") && (
                <MenuItem
                  active={menu_status === "sale_report"}
                  icon={<ReceiptIcon />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/sale_report" />}
                >
                  {" "}
                  {t("side_bar_salereport")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_purchase_report") && (
                <MenuItem
                  active={menu_status === "purchase_report"}
                  icon={<ReceiptIcon />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/purchase_report" />}
                >
                  {" "}
                  {t("side_bar_purchasereport")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes(
                "view_sale_order_report"
              ) && (
                <MenuItem
                  active={menu_status === "sale_order_report"}
                  icon={<ReceiptIcon />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/sale_order_report" />}
                >
                  {" "}
                  {t("side_bar_sale_orderreport")}{" "}
                </MenuItem>
              )}
              {current_user?.permissions?.includes("view_summary_report") && (
                <MenuItem
                  active={menu_status === "summary_report"}
                  icon={<ReceiptIcon />}
                  rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                  component={<Link to="/summary_report" />}
                >
                  {" "}
                  {t("side_bar_summary")}{" "}
                </MenuItem>
              )}
              <MenuItem
                active={menu_status === "balance_report"}
                icon={<ReceiptIcon />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#333" }}
                component={<Link to="/balance-report" />}
              >
                {" "}
                {t("balance")}{" "}
              </MenuItem>
            </SubMenu>
          </Menu>
        </Sidebar>
      )}

      <div className="content w-100 ">
        <div className="header ">
          {user && (
            <Header
              togglefun={toggleSidebar}
              collapsefun={collapseSidebar}
              broken={broken}
              statefun={() => setcheckcollapse(!checkcollapse)}
            />
          )}
        </div>

        {user ? (
          <div
            style={{
              minHeight: "108vh",
              backgroundColor: "rgb(241, 245, 245)",
            }}
          >
            <Outlet />
          </div>
        ) : (
          <div
            style={{
              minHeight: "125vh",
              backgroundColor: "rgb(241, 245, 245)",
            }}
          >
            <Outlet />
          </div>
        )}

        <div className="footer">{user && <Footer />}</div>
      </div>
    </div>
  );
}

export default Layout;
