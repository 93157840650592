import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../sale/sale.css";
import Button from "react-bootstrap/Button";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Select from "react-select";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";

import TextField from "@mui/material/TextField";
import Red_toast from "../alerts/red_toast";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faListOl } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";

import Tooltip from "@material-ui/core/Tooltip";
import Customerform from "../customers/customerform";
import Productform from "../products/productform";
import Saleform from "../sale/saleform";
import Notesmodel from "../sale/notemodel";

import Save_button from "../buttons/save_button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Delivery_notes_Edit(props) {
  const navigate = useNavigate();
  const user = props.state.setuser.user;
  const { t } = useTranslation();
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const check_status = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;

  const table_data = props.state.Setproducthistory.product_history;
  const id_data = props.state.Setsavedata.save_data;
  const settable_data = props.Setproduct_history;
  const [deliveryman, setdeliveryman] = useState("");

  const [saleman, setsaleman] = useState("");

  const [all_product_option, setall_product_option] = useState([]);
  const [all_product, setall_product] = useState([]);
  const [all_suppliers, setall_suppliers] = useState([]);
  const [placeholder, setplaceholder] = useState(t("search_by_name"));
  const [name_color, setname_color] = useState("blue");
  const [barcode_color, setbarcode_color] = useState("lightgray");
  const [code_color, setcode_color] = useState("lightgray");
  const [text, settext] = useState("");
  const [url, seturl] = useState("");
  const [status, setstatus] = useState(false);
  const [amount_received, setamount_received] = useState(0);

  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [invoice, setinvoice] = useState("");
  const [date, setdate] = useState(curdate);
  const [validdate, setvaliddate] = useState(null);
  const [counter, setcounter] = useState(1);
  const [supplier, setsupplier] = useState("");
  const [vat_perc, setvat_perc] = useState(0);
  const [notes, setnotes] = useState("");
  const [showmodelsuplier, setshowmodelsuplier] = useState(false);
  const [showmodelproduct, setshowmodelproduct] = useState(false);
  const [showmodel, setshowmodel] = useState(false);
  const [notemodel, setnotemodel] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [showpayment_model, setshowpayment_model] = useState(false);
  const [print, setprint] = useState(false);
  const [suppliers_data, setsuppliers_data] = useState([]);
  const [data, setdata] = useState("");
  const [text_product, settext_product] = useState("");

  useEffect(() => {
    if (invoice && supplier && table_data.length > 0) {
      setprint(true);
    } else {
      setprint(false);
    }
  }, [invoice, supplier, table_data]);

  useEffect(() => {
    settable_data({ type: "Set_product_history", data: [] });
    const fetchhistory = async () => {
      const response = await fetch(
        `${route}/api/delivery-notes/${id_data[0].id}/`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );

      const json = await response.json();

      if (response.ok) {
        var count = 0;

        setdate(json.date);
        setinvoice(json.delivery_number);
        setsupplier({
          value: json.customer_info.id,
          label: json.customer_info.name,
        });

        setnotes(json.remarks);

        const filter_data = json.details.map((item) => {
          count += 1;
          return {
            id: item.id,
            product: item.product,
            prod_id: count,
            name: item.product_name,
            code: item.product_code,
            quantity: item.quantity,
          };
        });
        setcounter(count + 1);

        settable_data({ type: "Set_product_history", data: filter_data });
      } else {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchhistory();
    }
  }, []);

  useEffect(() => {
    settable_data({ type: "Set_product_history", data: [] });
    const fetchProducts = async () => {
      const response = await fetch(
        `${route}/api/products/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setall_product(json);
        const pro = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_product_option(pro);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [user, selected_branch, check_status]);

  useEffect(() => {
    const fetchSuppliers = async () => {
      const response = await fetch(
        `${route}/api/parties/?branch_id=${selected_branch.id}&party_type=Customer`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setsuppliers_data(json);
        const supp = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_suppliers(supp);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchSuppliers();
    }
  }, [user, selected_branch, check_status]);

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selected_branch) {
      const data_details = table_data.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
        };
      });

      setisloading(true);

      const response = await fetch(
        `${route}/api/delivery-notes/${id_data[0].id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access}`,
          },
          body: JSON.stringify({
            customer: supplier.value,
            remarks: notes,
            date: date,
            delivery_number: invoice,
            details: data_details,
          }),
        }
      );
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok) {
        setisloading(false);
        navigate("/delivery_notes_history");
      }
    }
  };
  const handlePrint = async () => {
    if (selected_branch) {
      const data_details = table_data.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
        };
      });

      const response = await fetch(
        `${route}/api/delivery-notes/${id_data[0].id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access}`,
          },
          body: JSON.stringify({
            customer: supplier.value,
            remarks: notes,
            date: date,
            delivery_number: invoice,
            details: data_details,
          }),
        }
      );
      const json = await response.json();

      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok) {
        localStorage.setItem("data", JSON.stringify(json));
        settable_data({ type: "Set_product_history", data: [] });
        if (selected_branch.invoice_type === "Version 01") {
          window.open("/invoice/delivery_notes", "_blank");
        } else if (selected_branch.invoice_type === "Version 02") {
          window.open("/invoice_2/delivery_notes", "_blank");
        } else if (selected_branch.invoice_type === "Version 03") {
          window.open("/invoice_3/delivery_notes", "_blank");
        } else if (selected_branch.invoice_type === "Version 04") {
          window.open("/invoice_5/delivery_notes", "_blank");
        }
        navigate("/delivery_notes_history");
      }
    }
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="action text-center">
        <IconButton
          onClick={() => {
            settable_data({ type: "Delete_product_history", data: row });
          }}
        >
          <DeleteRoundedIcon color="error" fontSize="medium" />
        </IconButton>
      </div>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const name_column_formater = (cell, row) => {
    return <div style={{ width: "18vw" }}>{cell}</div>;
  };

  const columns = [
    {
      dataField: "row_number",
      text: "#",
      headerFormatter: headerstyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: "code",
      text: t("code"),
      headerFormatter: headerstyle,
      editable: false,
    },
    {
      dataField: "name",
      text: t("name"),
      sort: true,
      headerFormatter: headerstyle,
      formatter: name_column_formater,
      editable: false,
    },
    { dataField: "quantity", text: t("qty"), headerFormatter: headerstyle },

    {
      dataField: "Edit",
      text: t("action"),
      formatter: linkFollow,
      headerFormatter: headerstyle,
      editable: false,
    },
  ];

  const handlesearch_by_barcode = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: item.barcode,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_barcode"));
    setbarcode_color("blue");
    setname_color("lightgray");
    setcode_color("lightgray");
  };
  const handlesearch_by_code = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: `${item.code} | ${item.name} `,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_code"));
    setbarcode_color("lightgray");
    setname_color("lightgray");
    setcode_color("blue");
  };

  const handlesearch_by_name = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_name"));
    setbarcode_color("lightgray");
    setname_color("blue");
    setcode_color("lightgray");
  };

  const handlebarcodeinput = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      console.log(e.target.value);
      const filter_data = all_product.filter((item) => {
        if (item.barcode === e.target.value) {
          return item;
        }
      });
      if (filter_data.length > 0) {
        handleproduct_selection({
          value: filter_data[0].id,
          label: filter_data[0].name,
        });
      }
      settext_product("");
    }
  };

  const handleproduct_selection = (selected_option) => {
    const item_present = table_data.filter((item) => {
      return item.product === selected_option.value;
    });

    if (item_present.length === 0) {
      var filter_data = all_product.filter((item) => {
        if (item.id === selected_option.value) {
          return item;
        }
      });

      filter_data = filter_data.map((item) => {
        return {
          product: item.id,
          prod_id: counter,
          name: item.name,
          code: item.code,
          quantity: 1,
          price: item.price,
          sub_total: item.price,

          vat_amount: item.price / 100,
          discount: 0,
          total: item.price + item.price / 100,
        };
      });
      settable_data({ type: "Create_product_history", data: filter_data[0] });
      setcounter(counter + 1);
    } else {
      const item = item_present[0];
      item.quantity = item.quantity + 1;
      item.sub_total = item.quantity * item.price;
      item.vat_amount = (item.sub_total / 100) * item.vat_percentage;
      item.total = item.sub_total + item.vat_amount - item.discount;
      settable_data({ type: "Update_product_history", data: item });
    }
  };

  const handlecellchange = (oldValue, newValue, row, column) => {
    console.log("row", row);
    if (column.dataField === "quantity") {
      var new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: newValue,
              price: item.price,
              sub_total: item.price * newValue,
              vat_percentage: item.vat_percentage,
              vat_amount: ((item.price * newValue) / 100) * item.vat_percentage,
              discount: item.discount,
              total:
                item.price * newValue +
                ((item.price * newValue) / 100) * item.vat_percentage -
                item.discount,
            };
      });
      settable_data({ type: "Set_product_history", data: new_data });
    } else if (column.dataField === "vat_percentage") {
      console.log("vat_percentage");
      new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: item.quantity,
              price: item.price,
              sub_total: item.sub_total,
              vat_percentage: newValue,
              vat_amount: (item.sub_total / 100) * newValue,
              discount: item.discount,
              total:
                item.sub_total +
                (item.sub_total / 100) * newValue -
                item.discount,
            };
      });
      settable_data({ type: "Set_product_history", data: new_data });
    } else if (column.dataField === "discount") {
      console.log("discount");
      new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: item.quantity,
              price: item.price,
              sub_total: item.sub_total,
              vat_percentage: item.vat_percentage,
              vat_amount: item.vat_amount,
              discount: newValue,
              total: item.sub_total + item.vat_amount - newValue,
            };
      });
      console.log(new_data);
      settable_data({ type: "Set_product_history", data: new_data });
    } else if (column.dataField === "price") {
      new_data = table_data.map((item) => {
        return item.product !== row.product
          ? item
          : {
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: item.quantity,
              price: newValue,
              sub_total: newValue * item.quantity,
              vat_percentage: item.vat_percentage,
              vat_amount:
                ((newValue * item.quantity) / 100) * item.vat_percentage,
              discount: item.discount,
              total:
                newValue * item.quantity +
                ((newValue * item.quantity) / 100) * item.vat_percentage -
                item.discount,
            };
      });

      settable_data({ type: "Set_product_history", data: new_data });
    }
  };

  return (
    <div className="p-3">
      <h1 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "normal" }}>
        {t("edit_delivery")}
      </h1>
      <ToolkitProvider
        keyField="prod_id"
        data={table_data}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div className="card">
            <form onSubmit={handleSubmit}>
              <div className="card-header  d-flex justify-content-end">
                <Save_button isloading={isloading} />
                {print ? (
                  <Button
                    className="ms-2"
                    variant="outline-success"
                    onClick={handlePrint}
                  >
                    <PrintRoundedIcon /> {t("print")}
                  </Button>
                ) : (
                  <Button className="ms-2" variant="outline-success" disabled>
                    <PrintRoundedIcon /> {t("print")}
                  </Button>
                )}
              </div>

              <div className="card-body ">
                <div className="row mt-1">
                  <div className="col-6 mb-2 col-md-2">
                    <TextField
                      type="date"
                      className="form-control   mb-3"
                      id="outlined-basic"
                      label={t("date")}
                      InputLabelProps={{ shrink: true }}
                      defaultValue={date}
                      value={date}
                      onChange={(e) => {
                        setdate(e.target.value);
                      }}
                      size="small"
                    />
                  </div>

                  <div className="col-6 mb-2 col-md-2">
                    <TextField
                      className="form-control   mb-3"
                      id="outlined-basic"
                      label={t("delivery_number")}
                      value={invoice}
                      onChange={(e) => {
                        setinvoice(e.target.value);
                      }}
                      size="small"
                      required
                    />
                  </div>

                  <div className="col-6 mb-2 col-md-3">
                    <InputGroup>
                      <Select
                        className={
                          supplier
                            ? selected_branch.language === "English"
                              ? "form-control selector customer"
                              : "form-control selector customer_ar"
                            : "form-control selector"
                        }
                        styles={selectStyles}
                        options={all_suppliers}
                        placeholder="Customers"
                        value={supplier}
                        onChange={(e) => {
                          setsupplier(e);
                        }}
                        required
                      ></Select>

                      <IconButton
                        className="p-0 ps-1 pe-1"
                        style={{
                          backgroundColor: "#0d6efd",
                          borderRadius: "0",
                        }}
                        onClick={() => {
                          settext(t("side_bar_customers"));
                          seturl(
                            `${route}/api/parties/?branch_id=${selected_branch.id}&party_type=Customer`
                          );
                          setshowmodel(!showmodel);
                          setdata(suppliers_data);
                        }}
                      >
                        <VisibilityIcon
                          style={{ color: "white", height: "fit-content" }}
                          fontSize="medium"
                        />
                      </IconButton>
                      <IconButton
                        className="p-0 ps-1 pe-1"
                        style={{
                          backgroundColor: "slategray",
                          borderRadius: "0",
                        }}
                        onClick={() => setshowmodelsuplier(!showmodelsuplier)}
                      >
                        <AddIcon
                          style={{ color: "white", height: "fit-content" }}
                          fontSize="medium"
                        />
                      </IconButton>
                    </InputGroup>
                  </div>

                  <div className="col-6 mb-2 col-md-1 ms-sm-3 p-0">
                    <Button
                      type="button"
                      variant="outline-dark"
                      onClick={() => setnotemodel(!notemodel)}
                    >
                      {" "}
                      {t("notes")}
                    </Button>
                  </div>
                </div>

                <div className="row mt-2 d-sm-flex justify-content-center align-items-center">
                  <div className="col-md-6 d-sm-flex align-items-center">
                    <div className="text-center me-3">
                      <Tooltip title={t("search_by_barcode")}>
                        <IconButton onClick={handlesearch_by_barcode}>
                          <FontAwesomeIcon
                            color={barcode_color}
                            icon={faBarcode}
                            size="lg"
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={t("search_by_code")}>
                        <IconButton onClick={handlesearch_by_code}>
                          <FontAwesomeIcon
                            color={code_color}
                            icon={faListOl}
                            size="lg"
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={t("search_by_name")}>
                        <IconButton onClick={handlesearch_by_name}>
                          <FontAwesomeIcon
                            color={name_color}
                            icon={faTag}
                            size="lg"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="col-md-8">
                      <InputGroup>
                        {barcode_color === "lightgray" ? (
                          <Select
                            className="form-control selector"
                            placeholder={placeholder}
                            options={all_product_option}
                            onChange={handleproduct_selection}
                          ></Select>
                        ) : (
                          <TextField
                            className="form-control"
                            id="outlined-basic"
                            label={t("search_by_barcode")}
                            onKeyDown={handlebarcodeinput}
                            value={text_product}
                            onChange={(e) => settext_product(e.target.value)}
                            size="small"
                          />
                        )}

                        <IconButton
                          className="p-0 ps-1 pe-1"
                          style={{
                            backgroundColor: "#0d6efd",
                            borderRadius: "0",
                          }}
                          onClick={() => {
                            settext(t("side_bar_product"));
                            seturl(
                              `${route}/api/products/?branch_id=${selected_branch.id}`
                            );
                            setshowmodel(!showmodel);
                            setdata(all_product);
                          }}
                        >
                          <VisibilityIcon
                            style={{ color: "white", height: "fit-content" }}
                            fontSize="medium"
                          />
                        </IconButton>
                        <IconButton
                          className="p-0 ps-1 pe-1"
                          style={{
                            backgroundColor: "slategray",
                            borderRadius: "0",
                          }}
                          onClick={() => setshowmodelproduct(!showmodelproduct)}
                        >
                          <AddIcon
                            style={{ color: "white", height: "fit-content" }}
                            fontSize="medium"
                          />
                        </IconButton>
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <hr />
                <div style={{ height: "55vh", overflow: "auto" }}>
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    bootstrap4
                    condensed
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      afterSaveCell: handlecellchange,
                    })}
                    rowClasses="custom_row_class"
                  />
                </div>
              </div>

              <ToastContainer
                autoClose={1000}
                hideProgressBar={true}
                theme="dark"
              />
            </form>
          </div>
        )}
      </ToolkitProvider>

      {showmodelsuplier && (
        <Customerform
          show={showmodelsuplier}
          onHide={() => setshowmodelsuplier(false)}
          user={user}
          route={route}
          callback={dispatch}
          selected_branch={selected_branch}
          check="true"
        />
      )}

      {showmodelproduct && (
        <Productform
          show={showmodelproduct}
          onHide={() => setshowmodelproduct(false)}
          user={user}
          route={route}
          callback={dispatch}
          selected_branch={selected_branch}
          check="true"
        />
      )}

      {showmodel && (
        <Saleform
          show={showmodel}
          onHide={() => setshowmodel(false)}
          user={user}
          route1={url}
          callback={settable_data}
          text={text}
          counter={counter}
          setcounter={setcounter}
          setsupplier={setsupplier}
          table_data={table_data}
          vat_perc={vat_perc}
          selected_branch={selected_branch}
          route={route}
          data_={data}
        />
      )}
      {notemodel && (
        <Notesmodel
          show={notemodel}
          onHide={() => setnotemodel(false)}
          notes={notes}
          setnotes={setnotes}
        />
      )}
    </div>
  );
}

export default Delivery_notes_Edit;
