import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Branch_manage from "../../Container/assignpermissionContainer";
import Assign_role from "../../Container/assign_roleContainer";

import { ToastContainer } from "react-toastify";

function User_page({ current_user }) {
  const [activeTab, setActiveTab] = useState("Role");

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      {current_user?.profile?.user_type !== "user" ? (
        <div>
          <Tabs
            defaultActiveKey={activeTab}
            transition={true}
            onSelect={handleTabSelect}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Role" title="Assign Roles">
              {activeTab === "Role" && <Assign_role />}
            </Tab>

            <Tab eventKey="Group" title="Roles & Permissions">
              {activeTab === "Group" && <Branch_manage />}
            </Tab>
          </Tabs>
          <ToastContainer
            autoClose={2000}
            hideProgressBar={true}
            theme="dark"
          />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}

export default User_page;
