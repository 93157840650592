import React from 'react'
import './dashboard.css'
import { Link } from 'react-router-dom'

function Widget({name,icon_widget,link,color,text,text_2}) {
  return (
   
        <div className='card' >
              {!text ? <Link to={link}><div className='widget card-body p-3 d-flex flex-column align-items-center justify-content-start' style={{backgroundColor:`${color}`}}>

                <div className='icon text-white d-flex justify-content-center' >
                  {icon_widget}
                </div>

                <div className=''>
                  <h6 className='text-white m-0  bordered-0' style={{textDecoration:'none',fontSize:'1rem', fontWeight:'normal'}}>{name}</h6>
                </div>
                

              </div></Link>:

              <div className='card-body p-3 d-flex flex-column align-items-center justify-content-start' style={{backgroundColor:`${color}`}}>
                <h1 className='text-center text-dark' style={{fontSize:'1rem', fontWeight:'normal'}}>{text}</h1>
                <h2 className='text-dark'>{text_2}</h2>

              </div>
              }

        </div>
   
  )
}

export default Widget