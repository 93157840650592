import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./customer.css";

import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@material-ui/core";
import Red_toast from "../alerts/red_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import { useTranslation } from "react-i18next";
import Select from "../alerts/select";

function Customerform({
  show,
  onHide,
  user,
  route,
  callback,
  selected_branch,
  check,
}) {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const [picture, setpicture] = useState("");
  const [Fileurl, setFileurl] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [vat_number, setvat_number] = useState("");
  const [address, setaddress] = useState("");

  const [additional_number, setadditional_number] = useState("");
  const [building_number, setbuilding_number] = useState("");
  const [street_number, setstreet_number] = useState("");
  const [district, setdistrict] = useState("");
  const [isloading, setisloading] = useState(false);

  const [city, setcity] = useState("");
  const [allcity, setallcity] = useState([]);

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/city/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setallcity(
          json.map((item) => {
            return { value: item.id, label: item.name };
          })
        );
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selected_branch) {
      setisloading(true);
      const formData = new FormData();

      formData.append("name", name);
      formData.append("contact", contact);
      formData.append("email", email);
      formData.append("city", city?.value);
      formData.append("vat_number", vat_number);
      formData.append("address", address);

      formData.append("additional_number", additional_number);
      formData.append("building_number", building_number);
      formData.append("street_number", street_number);
      formData.append("district", district);
      formData.append("branch", selected_branch.id);
      formData.append("picture", picture);
      formData.append("party_type", "Customer");

      const response = await fetch(`${route}/api/parties/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok && check === "false") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        setname("");
        setcontact("");
        setemail("");
        setvat_number("");
        setaddress("");
        setFileurl("");
        setpicture("");
        setcity("");
        setadditional_number("");
        setbuilding_number("");
        setstreet_number("");
        setdistrict("");
      } else if (response.ok && check === "false") {
        callback({ type: "Create_table_history", data: json });
        setisloading(false);
        success_toast();
        onHide();
      }
    }
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];

    setpicture(file);

    const reader = new FileReader();
    reader.onload = () => {
      setFileurl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <FontAwesomeIcon className="me-2" icon={faUserPlus} />{" "}
          {t("add_customer")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!selected_branch && (
          <div className="text-center text-danger mb-2">
            Please Select Branch!
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className=" d-flex flex-column align-items-center">
              <Avatar
                src={Fileurl}
                className="mb-3"
                style={{ width: "100px", height: "100px" }}
                alt="image"
              />
              <input
                onChange={handleimageselection}
                id="select-file"
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
              />
              <Button onClick={onButtonClick} shadow>
                {t("choose_file")}
              </Button>
            </div>
          </div>
          <div className=" d-flex justify-content-between align-items-center mt-3">
            <div className="col-md-6 pe-md-3">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("name")}
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
                size="small"
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type="email"
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("email")}
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>

          <div className=" d-flex justify-content-between align-items-center ">
            <div className="col-md-6 pe-md-3">
              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("phone")}
                value={contact}
                onChange={(e) => {
                  setcontact(e.target.value);
                }}
                size="small"
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("vat_number")}
                value={vat_number}
                onChange={(e) => {
                  setvat_number(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>

          <div className=" d-flex justify-content-between align-items-center ">
            <div className="col-md-6 pe-md-3">
              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("additional_number")}
                value={additional_number}
                onChange={(e) => {
                  setadditional_number(e.target.value);
                }}
                size="small"
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("building_number")}
                value={building_number}
                onChange={(e) => {
                  setbuilding_number(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>

          <div className=" d-flex justify-content-between align-items-center ">
            <div className="col-md-6 pe-md-3">
              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("street_number")}
                value={street_number}
                onChange={(e) => {
                  setstreet_number(e.target.value);
                }}
                size="small"
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("district")}
                value={district}
                onChange={(e) => {
                  setdistrict(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>

          <div className=" d-flex justify-content-between align-items-center">
            <div className="col-md-6 pe-md-3">
              <Select
                options={allcity}
                placeholder={t("city")}
                value={city}
                funct={(e) => {
                  setcity(e);
                }}
              />
            </div>
            <div className="col-md-6">
              <TextField
                multiline
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("address")}
                value={address}
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Customerform;
