import React,{useState} from 'react'
import { IconButton,Avatar } from '@material-ui/core';
import cellEditFactory from 'react-bootstrap-table2-editor';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import SaveIcon from '@material-ui/icons/Save';
import { ToastContainer } from 'react-toastify';

import PaymentIcon from '@material-ui/icons/Payment';
import TextField from '@mui/material/TextField';
import MenuItem  from '@mui/material/MenuItem';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search , CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


function Groupform({show,onHide,user,route,callback}) {
    
    const [type,settype] = useState('')
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;


    function beforeSaveCell(oldValue, newValue, row, column, done) {
        
      }
    

  

    const handleSubmit=async(e)=>{
        e.preventDefault()
        // if (selected_branch){

          const response= await fetch('https://zamzam.einvoice.club/api/parties/',{
            method: "POST",
            headers: { 
              "Content-Type": "application/json",
              Authorization : `Bearer ${user.access}`
             },
            body: JSON.stringify({ 
                
            }),
        })
        const json=await response.json();
        
        if (!response.ok){
            
            console.log(json.error)
        }

        if (response.ok){
         

        }

        


    }


    const headerstyle = (column , colIndex,  {sortElement})=>{
        return(
          <div className='d-flex justify-content-between align-items-center' style={{minHeight:'2.5rem'}}>
            {column.text}
            {sortElement}
          </div>
        )
      }
  
      const Action = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span className='action d-flex '>
                <IconButton className='border border-danger rounded me-2' onClick={async() => {
                    const response = fetch(`https://zamzam.einvoice.club/api/parties/${row.id}/`,{
                      method:'DELETE',
                      headers:{
                        Authorization:`Bearer ${user.access}`
                      },
                    }) 
   
                    
    
                }}>
                    <DeleteRoundedIcon className='m-1' color='error' fontSize="small"/>
                </IconButton>
  
                
        </span>
        );
      };
  
      const d_types=[
        {id:'1' , type:'card'}
      ]
       
      const columns = [
         
          { dataField: 'group', text: 'Group Name',sort: true,headerFormatter:headerstyle},
          { dataField: 'permission_level', text: 'Permission level',sort: true,headerFormatter:headerstyle},
          
          {
              dataField: "action",
              text: "Action",
              formatter: Action,
              
            }
        ];
  
      const customTotal = (from, to, size) => (
          <span className="react-bootstrap-table-pagination-total ms-2">
              Showing { from } to { to } of { size } Results
          </span>
      );
  
      const options = {
          paginationSize: 4,
          pageStartIndex: 1,
          firstPageText: 'First',
          showTotal: true,
          paginationTotalRenderer: customTotal,
          disablePageTitle: true,
          sizePerPageList: [{
              text: '10', value: 10
          }, {
              text: '20', value: 20
          }, {
              text: 'All', value: 30
          }] // A numeric array is also available. the purpose of above example is custom the text
      };
      
      const rowstyle = { height:'10px'}



    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='d-flex align-items-md-center'>
          <PaymentIcon className='me-2'/> 
            Add Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        
        <form onSubmit={handleSubmit}>
            <div className='d-flex justify-content-center mt-2'>
                <TextField className='me-2' id="outlined-basic"  label='Group Name'  value={type} onChange={(e)=>{settype(e.target.value)}} size='small'/>  
                <Button  type='submit' variant='outline-primary'  ><SaveIcon/>  Save</Button>
            </div>
        </form>
        <hr/>

        <ToolkitProvider
                keyField="id"
                data={ d_types }
                columns={ columns }
                search
                exportCSV
                
                
                >
                {
                    props => (
                    <div>
                        
                        <div className='d-flex justify-content-between '>

                            <ExportCSVButton { ...props.csvProps } className='csvbutton border  bg-secondary text-light'>Export CSV</ExportCSVButton>
                            <SearchBar { ...props.searchProps }  />
                        </div>
                        
                        <hr/>
                        <BootstrapTable
                        { ...props.baseProps }
                        pagination={paginationFactory(options)}
                        rowStyle={ rowstyle}
                        striped
                        cellEdit={ cellEditFactory({
                            mode: 'dbclick',
                            beforeSaveCell
                          }) }
                        bootstrap4
                        condensed
                        rowClasses="custom-row-class"
                        wrapperClasses="table-responsive"
                        />
                    </div>
                    )
                }
            </ToolkitProvider>

        <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark"/>
          
        </Modal.Body>
      </Modal>
    );
  }


export default Groupform


    