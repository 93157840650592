import React, { useState, useEffect, useRef } from "react";
import Select from "../alerts/select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";

import ApartmentIcon from "@material-ui/icons/Apartment";

import { Avatar } from "@material-ui/core";
import Red_toast from "../alerts/red_toast";
import Update_button from "../buttons/update_button";

import { useTranslation } from "react-i18next";

function Productupdate({
  show,
  onHide,
  data,
  user,
  route,
  fun,
  callback,
  selected_branch,
}) {
  const inputFile = useRef(null);
  const { t } = useTranslation();
  const [File, setFile] = useState("");
  const [Fileurl, setFileurl] = useState(data.picture);
  const [category, setcategory] = useState({
    value: data.category,
    label: data.category_name,
  });
  const [subcategory, setsubcategory] = useState({
    value: data.sub_category,
    label: data.sub_category_name,
  });
  const [name, setname] = useState(data.name);
  const [code, setcode] = useState(data.code);
  const [barcode, setbarcode] = useState(data.barcode);
  const [quantity, setquantity] = useState(data.quantity);
  const [price, setprice] = useState(data.price);
  const [isloading, setisloading] = useState(false);
  const [all_categories, setall_categories] = useState([]);
  const [all_subcategories, setall_subcategories] = useState([]);

  useEffect(() => {
    setisloading(true);

    const fetchsubcategories = async () => {
      const response = await fetch(
        `${route}/api/subcategories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const conversion_sub = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setall_subcategories(conversion_sub);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
        setisloading(false);
      }
    };

    const fetchcategories = async () => {
      const response = await fetch(
        `${route}/api/categories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const conversion = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setall_categories(conversion);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
        setisloading(false);
      }
    };

    if (user) {
      fetchcategories();
      fetchsubcategories();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisloading(true);
    const formData = new FormData();

    formData.append("name", name);
    formData.append("code", code);
    formData.append("barcode", barcode);
    formData.append("price", price);
    formData.append("quantity", quantity);
    if (File) {
      formData.append("picture", File);
    }

    formData.append("category", category.value);
    formData.append("sub_category", subcategory.value);

    const response = await fetch(`${route}/api/products/${data.id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }

    if (response.ok) {
      setisloading(false);
      callback({ type: "Update_table_history", data: json });
      onHide();
      fun("Update");
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];

    setFile(file);

    const reader = new FileReader();
    reader.onload = () => {
      setFileurl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          {t("edit_product")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-2 d-flex flex-column align-items-center">
              <Avatar
                src={Fileurl}
                className="mb-3"
                style={{ width: "100px", height: "100px" }}
                alt="image"
                variant="rounded"
              />
              <input
                onChange={handleimageselection}
                id="select-file"
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
              />
              <Button onClick={onButtonClick} shadow>
                {t("choose_file")}
              </Button>
            </div>

            <div className="col-md-5">
              <Select
                options={all_categories}
                placeholder={t("side_bar_category")}
                value={category}
                funct={(e) => setcategory(e)}
                required={true}
              ></Select>

              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("name")}
                value={name}
                onChange={(e) => setname(e.target.value)}
                size="small"
              />
              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("barcode")}
                value={barcode}
                onChange={(e) => setbarcode(e.target.value)}
                size="small"
              />
              <TextField
                type="number"
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("price")}
                value={price}
                onChange={(e) => setprice(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-5">
              <Select
                options={all_subcategories}
                placeholder={t("side_bar_subcategory")}
                value={subcategory}
                funct={(e) => setsubcategory(e)}
              ></Select>

              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("code")}
                value={code}
                onChange={(e) => setcode(e.target.value)}
                size="small"
              />

              <TextField
                type="number"
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("quantity")}
                value={quantity}
                onChange={(e) => setquantity(e.target.value)}
                size="small"
                required
              />
            </div>
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Update_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Productupdate;
