import React, { useState, useEffect } from "react";
import Widget from "./widget";
import "./dashboard.css";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { Chart } from "react-google-charts";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";

function Dashboard(props) {
  const { t } = useTranslation();
  const [date, setdate] = useState(new Date().toISOString().substring(0, 10));
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const dispatch = props.Settable_history;

  const [data1, setdata1] = useState({
    sale: 0,
    sale_return: 0,
    purchase: 0,
    purchase_return: 0,
    payment_paid: 0,
    payment_received: 0,
    net_sale: 0,
    net_purchase: 0,
    expense: 0,
  });

  const [data_weekly, setdata_weekly] = useState([]);

  const [data_yearly, setdata_yearly] = useState([]);
  const [purchase_yearly, setpurchase_yearly] = useState([]);
  const [expense_yearly, setexpense_yearly] = useState([]);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "dashboard" });
  }, []);

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/sales/today/?branch_id=${selected_branch?.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );

      const json = await response.json();

      if (response.ok) {
        setdata1(json);
      }
    };

    const fetchweekly = async () => {
      const response = await fetch(
        `${route}/api/last-week-sale/?branch_id=${selected_branch?.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );

      const json = await response.json();

      if (response.ok) {
        const optimize = json.dates.map((item, index) => {
          const fixed_sale = Math.abs(
            parseFloat(json.total_sales[index].toFixed(2))
          );
          const fixed_invoice = Math.abs(
            parseFloat(json.total_invoices[index].toFixed(2))
          );
          return [item, fixed_sale, fixed_sale, fixed_invoice, fixed_invoice];
        });
        optimize.splice(0, 0, [
          "Element",
          "Total Sales",
          { type: "string", role: "annotation" },
          "Total Invoices",
          { type: "string", role: "annotation" },
        ]);

        setdata_weekly(optimize);
      }
    };

    const fetchyearly = async () => {
      const response = await fetch(
        `${route}/api/last-year-sale/?branch_id=${selected_branch?.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );

      const json = await response.json();

      if (response.ok) {
        const optimize = json.months.map((item, index) => {
          const fixed_sale = Math.abs(
            parseFloat(json.total_sales[index].toFixed(2))
          );
          const fixed_returns = Math.abs(
            parseFloat(json.total_return[index].toFixed(2))
          );

          return [item, fixed_sale, fixed_sale, fixed_returns, fixed_returns];
        });
        optimize.splice(0, 0, [
          "Element",
          "Total Sales",
          {
            type: "string",
            role: "annotation",
            textStyle: {
              color: "black",
              fontSize: 12,
            },
          },
          "Total Returns",
          { type: "string", role: "annotation" },
        ]);

        setdata_yearly(optimize);
      }
    };

    const fetchpurchase = async () => {
      const response = await fetch(
        `${route}/api/last-year-purchase/?branch_id=${selected_branch?.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );

      const json = await response.json();

      if (response.ok) {
        const optimize = json.months.map((item, index) => {
          const fixed_returns = Math.abs(
            parseFloat(json.total_purchases[index].toFixed(2))
          );
          const fixedpayments = Math.abs(
            parseFloat(json.total_return[index].toFixed(2))
          );

          return [
            item,

            fixed_returns,
            fixed_returns,
            fixedpayments,
            fixedpayments,
          ];
        });
        optimize.splice(0, 0, [
          "Element",
          "Total Purchase",
          {
            type: "string",
            role: "annotation",
            textStyle: {
              color: "black",
              fontSize: 12,
            },
          },

          "Total Returns",
          { type: "string", role: "annotation" },
        ]);

        setpurchase_yearly(optimize);
      }
    };

    const fetchexpenses = async () => {
      const response = await fetch(
        `${route}/api/last-year-expense/?branch_id=${selected_branch?.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );

      const json = await response.json();

      if (response.ok) {
        const optimize = json.months.map((item, index) => {
          const fixed_sale = Math.abs(
            parseFloat(json.total_expenses[index].toFixed(2))
          );

          return [item, fixed_sale, fixed_sale];
        });
        optimize.splice(0, 0, [
          "Element",
          "Total Expenses",
          {
            type: "string",
            role: "annotation",
            textStyle: {
              color: "black",
              fontSize: 12,
            },
          },
        ]);

        setexpense_yearly(optimize);
      }
    };

    if (user) {
      fetchWorkouts();
      fetchyearly();
      fetchweekly();
      fetchpurchase();
      fetchexpenses();
    }
  }, [selected_branch]);

  const options = {
    annotations: {
      textStyle: {
        color: "black",
      },
    },
    chartArea: { width: "90%" },
    series: {
      0: { targetAxisIndex: 0, type: "bars" },
      1: { targetAxisIndex: 1, type: "bars" },
    },
    hAxis: {
      gridlines: {
        count: 5, // Set the count to the desired number of vertical gridlines
      },
    },
  };
  const optionsyearly = {
    chartArea: { width: "90%" },

    legend: { position: "top", alignment: "start" },
    hAxis: {
      gridlines: {
        count: 5, // Set the count to the desired number of vertical gridlines
      },
    },
  };

  return (
    <>
      {current_user?.permissions?.includes("view_dashboard") ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("side_bar_dashboard")}
          </h1>

          <div className="row  p-2 pb-0">
            <div className="col-6 col-sm-2">
              <Widget
                name={t("side_bar_purchase")}
                icon_widget={<ShoppingCartIcon fontSize="inherit" />}
                link="/purchase"
                color="#17a2b8"
              />
            </div>

            <div className="col-6 col-sm-2 mb-1">
              <Widget
                name={t("side_bar_sale")}
                icon_widget={<LocalAtmIcon fontSize="inherit" />}
                link="/sale"
                color="#ffc107"
              />
            </div>
            <div className="col-6 col-sm-2 mb-1">
              <Widget
                name={t("side_bar_paymnt")}
                icon_widget={<ApartmentIcon fontSize="inherit" />}
                link="/payment_customer"
                color="#28a745"
              />
            </div>

            <div className="col-6 col-sm-2 mb-1">
              <Widget
                name={t("expenses")}
                icon_widget={<AssessmentIcon fontSize="inherit" />}
                link="/expenses"
                color="#dc3545"
              />
            </div>

            <div className="col-6 col-sm-2  mb-1">
              <Widget
                name={t("side_bar_salereport")}
                icon_widget={<FontAwesomeIcon icon={faTag} size="inherit" />}
                link="/sale_report"
                color="royalblue"
              />
            </div>

            <div className="col-6 col-sm-2 mb-1">
              <Widget
                name={t("side_bar_purchasereport")}
                icon_widget={<ReceiptIcon fontSize="inherit" />}
                link="/purchase_report"
                color="#17a2b8"
              />
            </div>
          </div>

          <div className=" row me-1   mt-3">
            <div className="col-sm-6">
              <div className="card">
                <div className=" card-header bg-white">
                  {t("last_week_sale")}
                </div>
                <div className=" card-body">
                  <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="300px"
                    data={data_weekly}
                    options={options}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-6   p-2 ps-3">
              <div className="row">
                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Sale")}
                    text_2={parseFloat(data1.sale.toFixed(2))}
                  />
                </div>
                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Sale Return")}
                    text_2={parseFloat(data1.sale_return.toFixed(2))}
                  />
                </div>

                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Net Sale")}
                    text_2={parseFloat(data1.net_sale.toFixed(2))}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Purchase")}
                    text_2={parseFloat(data1.purchase.toFixed(2))}
                  />
                </div>
                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Purchase Return")}
                    text_2={parseFloat(data1.purchase_return.toFixed(2))}
                  />
                </div>

                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Net Purchase")}
                    text_2={parseFloat(data1.net_purchase.toFixed(2))}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Expenses")}
                    text_2={parseFloat(data1.expense.toFixed(2))}
                  />
                </div>
                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Vendor Balance")}
                    text_2={parseFloat(data1.payment_paid.toFixed(2))}
                  />
                </div>
                <div className="col-6 col-sm-4">
                  <Widget
                    text={t("Customer Balance")}
                    text_2={parseFloat(data1.payment_received.toFixed(2))}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-header bg-white">{t("yearly_sale")}</div>
            <div className="card-body " style={{ height: "25rem" }}>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="350px"
                data={data_yearly}
                options={optionsyearly}
              />
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-header bg-white">{t("yearly_purchase")}</div>
            <div className="card-body " style={{ height: "25rem" }}>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="350px"
                data={purchase_yearly}
                options={optionsyearly}
              />
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-header bg-white">{t("yearly_expense")}</div>
            <div className="card-body " style={{ height: "25rem" }}>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="350px"
                data={expense_yearly}
                options={optionsyearly}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}

export default Dashboard;
