import React, { useState, useEffect, useRef } from "react";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
import { Avatar } from "@material-ui/core";
import Red_toast from "../alerts/red_toast";
import Update_button from "../buttons/update_button";

function Branchupdate({ show, onHide, data, user, route, fun, callback }) {
  const inputFile = useRef(null);
  const [File, setFile] = useState("");
  const [Fileurl, setFileurl] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [address, setaddress] = useState("");
  const [links, setlinks] = useState("");
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    setname(data.name);

    setemail(data.email);
    setFileurl(data.logo);
    setcontact(data.contact);
    setaddress(data.address);
    setlinks(data.social_links);
    console.log(data.social_links);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisloading(true);
    const formData = new FormData();

    formData.append("name", name);
    formData.append("contact", contact);
    formData.append("email", email);

    formData.append("address", address);
    formData.append("social_links", links);
    formData.append("logo", File);

    const response = await fetch(`${route}/api/branch/${data.id}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }

    if (response.ok) {
      setisloading(false);
      callback({ type: "Update_table_history", data: json });
      fun("Update");
      onHide();
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];

    setFile(file);

    const reader = new FileReader();
    reader.onload = () => {
      setFileurl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <AddShoppingCartIcon className="me-2" />
          Edit Branch
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <Avatar
                src={Fileurl}
                className="mb-3"
                style={{ width: "100px", height: "100px" }}
                alt="image"
              />
              <input
                onChange={handleimageselection}
                id="select-file"
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
              />
              <Button onClick={onButtonClick} shadow>
                Choose file
              </Button>
            </div>

            <div className="col-md-8">
              <TextField
                className="form-control   mb-3"
                id="outlined-basic"
                label="Name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
                size="small"
                required
              />
              <TextField
                type="email"
                className="form-control   mb-3"
                id="outlined-basic"
                label="Email"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                size="small"
              />

              <TextField
                className="form-control  mb-3"
                id="outlined-basic"
                label="Contact"
                value={contact}
                onChange={(e) => {
                  setcontact(e.target.value);
                }}
                size="small"
              />
              <TextField
                multiline
                className="form-control   mb-3"
                id="outlined-basic"
                label="Address"
                value={address}
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                size="small"
              />

              <TextField
                multiline
                className="form-control  mb-3"
                id="outlined-basic"
                label="Social Links"
                value={links}
                onChange={(e) => {
                  setlinks(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>

          <hr></hr>
          <div className="d-flex flex-row-reverse mt-2 me-2">
            <Update_button isloading={isloading} />
          </div>
        </form>
        <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
      </Modal.Body>
    </Modal>
  );
}

export default Branchupdate;
