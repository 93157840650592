import { connect } from "react-redux";
import Sale_Order_Report from "../Components/reports/sale_order_report";
import { Settablehistory } from "../Service/Actions/action";

const dispatchpart=dispatch=>({
    Settable_history:data=>dispatch(Settablehistory(data))
})
    
const getpart=state=>({
        state
    })
    
export default connect(getpart,dispatchpart)(Sale_Order_Report)