import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Button from "react-bootstrap/Button";
import { Avatar } from "@material-ui/core";
import "./sale.css";
import { ToastContainer } from "react-toastify";
import went_wrong_toast from "../alerts/went_wrong_toast";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import custom_toast from "../alerts/custom_toast";
import Red_toast from "../alerts/red_toast";
import { useTranslation } from "react-i18next";

function Saleform({
  show,
  onHide,
  user,
  route1,
  callback,
  text,
  counter,
  setcounter,
  setsupplier,
  setdeliveryman,
  setsaleman,
  table_data,
  vat_perc,
  selected_branch,
  route,
  data_,
}) {
  const { t } = useTranslation();
  const [isloading, setisloading] = useState(false);
  const [data, setdata] = useState(data_);
  const { SearchBar } = Search;
  const [callonce, setcallonce] = useState(false);
  const [category, setcategory] = useState({ value: "all", label: "All" });
  const [allcategory, setallcategory] = useState([]);
  const [subcategory, setsubcategory] = useState({
    value: "all",
    label: "All",
  });
  const [allsubcategory, setallsubcategory] = useState([]);

  const [callagain, setcallagain] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      const response = await fetch(
        `${route}/api/categories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        optimize.splice(0, 0, { value: "all", label: "All" });
        setallcategory(optimize);
      }
    };

    const fetchsubcategory = async () => {
      const response = await fetch(
        `${route}/api/subcategories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        optimize.splice(0, 0, { value: "all", label: "All" });
        setallsubcategory(optimize);
        setcallonce(true);
      }
    };

    if (text === "Products") {
      fetchCategory();
      fetchsubcategory();
    }
  }, []);

  useEffect(() => {
    const fetchWorkouts = async () => {
      var url = `${route1}`;

      if (category.value != "all") {
        url = `${url}&category_id=${category.value}`;
      }
      if (subcategory.value != "all") {
        url = `${url}&subcategory_id=${subcategory.value}`;
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        setdata(json);
      }
      if (!response.ok) {
        went_wrong_toast();
        setisloading(false);
      }
    };

    if (user && text === "Products" && callonce) {
      fetchWorkouts();
    }
  }, [callagain]);

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const loadimage = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="d-flex justify-content-center">
        <Avatar src={cell} alt="logo" />
      </div>
    );
  };

  if (
    text === "Suppliers" ||
    text === "Sale Persons" ||
    text === "Delivery Persons" ||
    text === "Customers"
  ) {
    var columns = [
      {
        dataField: "id",
        text: "Id",
        hidden: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "picture",
        text: t("logo"),
        formatter: loadimage,
        headerFormatter: headerstyle,
      },

      {
        dataField: "name",
        text: t("name"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "email",
        text: t("email"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "contact",
        text: t("phone"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "vat_number",
        text: t("vat_number"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "address",
        text: t("address"),
        sort: true,
        headerFormatter: headerstyle,
      },
    ];

    var selectRow = {
      mode: "radio",
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          if (text === "Suppliers") {
            setsupplier({ value: row.id, label: row.name });
            onHide();
          } else if (text === "Sales_man") {
            setsaleman({ value: row.id, label: row.name });
            onHide();
          } else if (text === "Delivery_man") {
            setdeliveryman({ value: row.id, label: row.name });
            onHide();
          }
        }
      },
    };
  } else {
    columns = [
      {
        dataField: "id",
        text: "Id",
        hidden: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "picture",
        text: t("logo"),
        formatter: loadimage,
        headerFormatter: headerstyle,
      },
      {
        dataField: "category_name",
        text: t("category"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "sub_category_name",
        text: t("subcategory"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "name",
        text: t("name"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "code",
        text: t("code"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "barcode",
        text: t("barcode"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "quantity",
        text: t("quantity"),
        sort: true,
        headerFormatter: headerstyle,
      },
      {
        dataField: "price",
        text: t("price"),
        sort: true,
        headerFormatter: headerstyle,
      },
    ];

    selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          const item_present = table_data.filter((item) => {
            return item.product === row.id;
          });

          if (item_present.length === 0) {
            var filter_data = data.filter((item) => {
              if (item.id === row.id) {
                return item;
              }
            });

            // if (filter_data[0].quantity>0){
            filter_data = filter_data.map((item) => {
              return {
                product: item.id,
                prod_id: counter,
                name: item.name,
                code: item.code,
                quantity: 1,
                price: item.price,
                sub_total: item.price,
                vat_percentage: vat_perc,
                vat_amount: (item.price / 100) * vat_perc,
                discount: 0,
                total: item.price + (item.price / 100) * vat_perc,
              };
            });

            callback({ type: "Create_product_history", data: filter_data[0] });
            custom_toast("Item added");
            setcounter(counter + 1);
            // else{
            //   Red_toast(`${filter_data[0].name} out of Stock!`)
            // }
          } else {
            // if (item_present[0].quantity>0){
            const item = item_present[0];
            item.quantity = item.quantity + 1;
            item.sub_total = item.quantity * item.price;
            item.vat_amount = (item.sub_total / 100) * item.vat_percentage;
            item.total = item.sub_total + item.vat_amount - item.discount;
            callback({ type: "Update_product_history", data: item });
            custom_toast("Item added");
            // else{
            //   Red_toast(`${item_present[0].name} out of Stock!`)
            // }
          }
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        if (isSelect) {
          var num = counter;

          rows.forEach((item) => {
            const item_present = table_data.filter((prod) => {
              return prod.product === item.id;
            });

            if (item_present.length === 0) {
              var filter_data = data.filter((row) => {
                if (item.id === row.id) {
                  return item;
                }
              });

              // if (filter_data[0].quantity>0){
              filter_data = filter_data.map((item) => {
                return {
                  product: item.id,
                  prod_id: num,
                  name: item.name,
                  code: item.code,
                  quantity: 1,
                  price: item.price,
                  sub_total: item.price,
                  vat_percentage: vat_perc,
                  vat_amount: (item.price / 100) * vat_perc,
                  discount: 0,
                  total: item.price + (item.price / 100) * vat_perc,
                };
              });

              num = num + 1;
              callback({
                type: "Create_product_history",
                data: filter_data[0],
              });
              // else{
              //   Red_toast(`${filter_data[0].name} out of Stock!`)
              // }
            } else {
              // if (item_present[0].quantity>0){
              const item = item_present[0];
              item.quantity = item.quantity + 1;
              item.sub_total = item.quantity * item.price;
              item.vat_amount = (item.sub_total / 100) * item.vat_percentage;
              item.total = item.sub_total + item.vat_amount - item.discount;
              callback({ type: "Update_product_history", data: item });
              // else{
              //   Red_toast(`${item_present[0].name} out of Stock!`)
              // }
            }
          });
          setcounter(num);
          custom_toast("Items added");
        }
      },
    };
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const rowstyle = { height: "10px" };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  useEffect(() => {
    setcallagain(!callagain);
  }, [category, subcategory]);

  const handlecategory = (e) => {
    setcategory(e);
  };

  const handlesubcategory = (e) => {
    setsubcategory(e);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          {text}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV
        >
          {(props) => (
            <div>
              {text === "Products" ? (
                <div className="d-flex  justify-content-between align-items-center mt-3">
                  <div className=" col-sm-6 d-flex">
                    <div className="d-flex flex-column me-3 form-control  selector mb-3">
                      <label>{t("category")}</label>
                      <Select
                        className=" form-control  selector"
                        styles={selectStyles}
                        options={allcategory}
                        placeholder={t("side_bar_catgory")}
                        value={category}
                        onChange={handlecategory}
                      ></Select>
                    </div>
                    <div className="d-flex flex-column me-3 form-control  selector mb-3">
                      <label>{t("subcategory")}</label>
                      <Select
                        className=" form-control  selector"
                        styles={selectStyles}
                        options={allsubcategory}
                        placeholder={t("side_bar_subcatgory")}
                        value={subcategory}
                        onChange={handlesubcategory}
                      ></Select>
                    </div>
                  </div>
                  <SearchBar {...props.searchProps} />
                </div>
              ) : (
                <div className="d-flex flex-row-reverse justify-content-between align-items-center mt-3">
                  <SearchBar {...props.searchProps} />
                </div>
              )}

              {isloading && (
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              <hr />
              <div style={{ height: "60vh", overflow: "auto" }}>
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  rowStyle={rowstyle}
                  striped
                  bootstrap4
                  condensed
                  selectRow={selectRow}
                  wrapperClasses="table-responsive"
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </Modal.Body>
    </Modal>
  );
}

export default Saleform;
