import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import "./expense.css";
import TextField from "@mui/material/TextField";
import Select from "../alerts/select";
import PaymentIcon from "@material-ui/icons/Payment";
import Red_toast from "../alerts/red_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import { useTranslation } from "react-i18next";
function Expenseform({
  show,
  onHide,
  user,
  route,
  callback,
  selected_branch,
  text,
  current_user,
  setloadagain,
}) {
  const [expense_type_list, setexpense_type_list] = useState([]);
  const { t } = useTranslation();
  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [date, setdate] = useState(curdate);
  const [expense_type, setexpense_type] = useState("");
  const [amount, setamount] = useState("");
  const [remarks, setremarks] = useState("");
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const fetchexpense_listlist = async () => {
      const response = await fetch(
        `${route}/api/expensetypes/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const options = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setexpense_type_list(options);
      }
    };

    if (user) {
      fetchexpense_listlist();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selected_branch) {
      setisloading(true);
      const formdata = new FormData();

      formdata.append("date", date);
      formdata.append("type", expense_type.value);
      formdata.append("amount", amount);

      formdata.append("remarks", remarks);
      formdata.append("branch", selected_branch.id);
      formdata.append("user", current_user.id);

      const response = await fetch(`${route}/api/expenses/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formdata,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok) {
        setisloading(false);
        setexpense_type("");

        setamount("");
        setremarks("");
        setdate(curdate);
        success_toast();
        setloadagain();
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <PaymentIcon className="me-2" />
          {t("addexpenses")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <Select
                options={expense_type_list}
                placeholder={t("expensetype")}
                value={expense_type}
                funct={(e) => {
                  setexpense_type(e);
                }}
                required={true}
              ></Select>

              <TextField
                type="number"
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("amount")}
                value={amount}
                onChange={(e) => setamount(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type="date"
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("date")}
                value={date}
                onChange={(e) => setdate(e.target.value)}
                size="small"
              />
              <TextField
                multiline
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("remarks")}
                value={remarks}
                onChange={(e) => setremarks(e.target.value)}
                size="small"
                required
              />
            </div>
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Expenseform;
