import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Avatar } from "@material-ui/core";
import "./company.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";

import TextField from "@mui/material/TextField";
import Red_toast from "../alerts/red_toast";
import success_toast from "../alerts/success_toast";
import Save_button from "../buttons/save_button";
import Select from "../alerts/select";
import { useTranslation } from "react-i18next";

function Companyform({
  show,
  onHide,
  user,
  route,
  callback,
  selected_branch,
  current_user,
  setuser,
}) {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const [picture, setpicture] = useState("");
  const [Fileurl, setFileurl] = useState("");
  const [name, setname] = useState("");
  const [arabic_name, setarabic_name] = useState("");
  const [article_number, setarticle_number] = useState("");

  const [cr_number, setcr_number] = useState("");
  const [cr_number_arabic, setcr_number_arabic] = useState("");
  const [vat_number, setvat_number] = useState("");

  const [vat_number_arabic, setvat_number_arabic] = useState("");
  const [contact, setcontact] = useState("");
  const [contact_arabic, setcontact_arabic] = useState("");

  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [address_arabic, setaddress_arabic] = useState("");

  const [vat_percentage, setvat_percentage] = useState("");
  const [language, setlanguage] = useState("");
  const [discount_type, setdiscount_type] = useState("");
  const [company_type, setcompany_type] = useState("");
  const [invoice_type, setinvoice_type] = useState("");

  const [isloading, setisloading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setisloading(true);
    const formData = new FormData();

    formData.append("name", name);
    formData.append("arabic_name", arabic_name);

    formData.append("article_number", article_number);
    formData.append("cr_number", cr_number);
    formData.append("cr_number_arabic", cr_number_arabic);
    formData.append("vat_number", vat_number);
    formData.append("vat_number_arabic", vat_number_arabic);
    formData.append("contact", contact);
    formData.append("contact_arabic", contact_arabic);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("address_arabic", address_arabic);
    formData.append("logo", picture);
    formData.append("vat_percentage", vat_percentage);

    formData.append("user", [current_user.username]);
    formData.append("vat_percentage", vat_percentage);

    if (language) {
      formData.append("language", language.value);
    } else {
      formData.append("language", language);
    }
    if (discount_type) {
      formData.append("discount_type", discount_type.value);
    } else {
      formData.append("discount_type", discount_type);
    }

    if (company_type) {
      formData.append("company_type", company_type.value);
    } else {
      formData.append("company_type", company_type);
    }
    if (invoice_type) {
      formData.append("invoice_type", invoice_type.value);
    } else {
      formData.append("invoice_type", invoice_type);
    }

    const response = await fetch(`${route}/api/branch/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }

    if (response.ok) {
      callback({ type: "Create_table_history", data: json });
      setuser({
        type: "SetCurrentUser",
        data: {
          ...current_user,
          branch_list: [
            ...current_user.branch_list,
            { id: json.id, name: json.name },
          ],
        },
      });
      setisloading(false);
      success_toast();
      setname("");
      setarabic_name("");
      setarticle_number("");
      setcr_number_arabic("");
      setcr_number("");
      setvat_number("");
      setvat_number_arabic("");
      setcontact("");
      setcontact_arabic("");
      setemail("");
      setaddress("");
      setaddress_arabic("");
      setvat_percentage("");
      setlanguage("");
      setinvoice_type("");
      setdiscount_type("");
      setcompany_type("");
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const handleimageselection = (event) => {
    const file = event.target.files[0];

    setpicture(file);

    const reader = new FileReader();
    reader.onload = () => {
      setFileurl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const language_option = [
    { value: "English", label: "English" },
    { value: "Arabic", label: "Arabic" },
  ];
  const discount_option = [
    { value: "Before VAT", label: "Before VAT" },
    { value: "After VAT", label: "After VAT" },
  ];

  const invoice_option = [
    { value: "Version 01", label: "Version 01" },
    { value: "Version 02", label: "Version 02" },
    { value: "Version 03", label: "Version 03" },
    { value: "Version 04", label: "Version 04" },
    { value: "Version 05", label: "Version 05" },
  ];

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <FontAwesomeIcon icon={faBuildingColumns} className="me-2" />
          {t("add_company")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Tabs
            defaultActiveKey="Profile"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Profile" title="Profile">
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    className="form-control   mb-3"
                    id="outlined-basic"
                    label={t("name")}
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("cr_number")}
                    value={cr_number}
                    onChange={(e) => {
                      setcr_number(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control   mb-3"
                    id="outlined-basic"
                    label={t("vat_number")}
                    value={vat_number}
                    onChange={(e) => {
                      setvat_number(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("artical_number")}
                    value={article_number}
                    onChange={(e) => {
                      setarticle_number(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("contact")}
                    value={contact}
                    onChange={(e) => {
                      setcontact(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    multiline
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("address")}
                    value={address}
                    onChange={(e) => {
                      setaddress(e.target.value);
                    }}
                    size="small"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    className="form-control   mb-3"
                    id="outlined-basic"
                    label={t("arabic_name")}
                    value={arabic_name}
                    onChange={(e) => {
                      setarabic_name(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("arabic_cr_number")}
                    value={cr_number_arabic}
                    onChange={(e) => {
                      setcr_number_arabic(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control   mb-3"
                    id="outlined-basic"
                    label={t("arabic_vat_number")}
                    value={vat_number_arabic}
                    onChange={(e) => {
                      setvat_number_arabic(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("email")}
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("arabic_contact")}
                    value={contact_arabic}
                    onChange={(e) => {
                      setcontact_arabic(e.target.value);
                    }}
                    size="small"
                  />
                  <TextField
                    multiline
                    className="form-control  mb-3"
                    id="outlined-basic"
                    label={t("arabic_address")}
                    value={address_arabic}
                    onChange={(e) => {
                      setaddress_arabic(e.target.value);
                    }}
                    size="small"
                  />
                </div>
              </div>
            </Tab>

            <Tab eventKey="Others" title="Others">
              <div className="d-flex flex-column align-items-center">
                <Avatar
                  src={Fileurl}
                  className="mb-3"
                  style={{ width: "100px", height: "100px" }}
                  alt="image"
                  variant="rounded"
                />
                <input
                  onChange={handleimageselection}
                  id="select-file"
                  type="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                <Button className="mb-3" onClick={onButtonClick} shadow>
                  {t("choose_file")}
                </Button>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    type="number"
                    className="form-control   mb-3"
                    id="outlined-basic"
                    label={t("vat%")}
                    value={vat_percentage}
                    onChange={(e) => {
                      setvat_percentage(e.target.value);
                    }}
                    size="small"
                  />

                  <Select
                    options={discount_option}
                    placeholder={t("discount_type")}
                    value={discount_type}
                    funct={(e) => {
                      setdiscount_type(e);
                    }}
                  ></Select>
                  <Select
                    options={invoice_option}
                    placeholder={t("invoice_type")}
                    value={invoice_type}
                    funct={(e) => {
                      setinvoice_type(e);
                    }}
                  ></Select>
                </div>
                <div className="col-md-6">
                  <Select
                    options={language_option}
                    placeholder={t("language")}
                    value={language}
                    funct={(e) => {
                      setlanguage(e);
                    }}
                  ></Select>

                  <Select
                    placeholder={t("company_type")}
                    value={company_type}
                    funct={(e) => {
                      setcompany_type(e);
                    }}
                  ></Select>
                </div>
              </div>
            </Tab>
          </Tabs>
          <hr></hr>
          <div className="d-flex flex-row-reverse mt-2 me-2">
            <Save_button isloading={isloading} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Companyform;
