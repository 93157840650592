import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import "./expense.css";
import { IconButton, Avatar } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Select from "../alerts/select";
import Expenseform from "./expenseform";
import Expenseupdate from "./updateexpenseform";
import Typeform from "./typeform";
import PaymentIcon from "@material-ui/icons/Payment";
import TextField from "@mui/material/TextField";
import Alert_before_delete from "../../Container/alertContainer";
import { ToastContainer } from "react-toastify";
import custom_toast from "../alerts/custom_toast";
import Spinner from "react-bootstrap/Spinner";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addMonths,
  addYears,
  isSameDay,
} from "date-fns";

export default function Expenses(props) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const all_products = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [showmodel, setshowmodel] = useState(false);
  const [showtypemodel, setshowtypemodel] = useState(false);
  const [data, setdata] = useState("");
  const [showmodelupdate, setshowmodelupdate] = useState(false);
  const [loadagain, setloadagain] = useState(false);

  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");
  const [isloading, setisloading] = useState(false);

  const [start_date, setstart_date] = useState(
    addMonths(new Date(), -1).toISOString().substring(0, 10)
  );
  const [end_date, setend_date] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [alltypes, setalltypes] = useState([]);
  const [typetriger, settypetriger] = useState(false);
  const [type, settype] = useState({ value: "all", label: "All" });
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [date_range, setdate_range] = useState([
    {
      startDate: addMonths(new Date(), -1),
      endDate: new Date(),
      key: "selection",
      showDateDisplay: "false",
    },
  ]);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "expenses" });
  }, []);

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch(
        `${route}/api/expensetypes/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const make_option = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        make_option.splice(0, 0, { value: "all", label: "All" });
        setalltypes(make_option);
      }
    };

    fetchWorkouts();
  }, [selected_branch, typetriger]);

  useEffect(() => {
    setisloading(true);
    const fetchWorkouts = async () => {
      var url = `${route}/api/expenses/?branch_id=${selected_branch.id}&start_date=${start_date}&end_date=${end_date}`;

      if (
        date_range[0].endDate.getFullYear() -
          date_range[0].startDate.getFullYear() ===
        10
      ) {
        var url = `${route}/api/expenses/?branch_id=${selected_branch.id}`;
      }
      if (type.value !== "all") {
        url = `${url}&type_id=${type.value}`;
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        dispatch({ type: "Set_table_history", data: json });
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, [selected_branch, loadagain, date_range, type]);

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };
  const handleconfirm = (row) => {
    dispatch({ type: "Delete_table_history", data: { id: row } });
    custom_toast("Delete");
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex ">
        <IconButton
          className="border border-danger rounded me-2"
          onClick={() => {
            setrow_id(row.id);
            seturl_to_delete(`${route}/api/expenses/${row.id}/`);
            setdelete_user(true);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="small" />
        </IconButton>

        <IconButton
          style={{ border: "1px solid #003049", borderRadius: "5px" }}
          onClick={() => {
            setdata(row);
            setshowmodelupdate(true);
          }}
        >
          <EditOutlinedIcon
            className="m-1"
            style={{ color: "#003049" }}
            fontSize="small"
          />
        </IconButton>
      </span>
    );
  };

  const columns = [
    {
      dataField: "date",
      text: t("date"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "type_name",
      text: t("expensetype"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "sale_person_name",
      text: t("side_bar_salepersons"),
      sort: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "amount",
      text: t("amount"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "remarks",
      text: t("remarks"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "action",
      text: t("action"),
      formatter: Action,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: all_products.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const rowstyle = { height: "10px" };

  const makepdf = () => {
    const body = all_products.map((item, index) => {
      return [index + 1, item.date, item.type_name, item.amount, item.remarks];
    });
    body.splice(0, 0, ["#", "Date", "Type", "Amount", "Remarks"]);

    const documentDefinition = {
      content: [
        { text: "Expenses", style: "header" },
        { text: `Project Name: ${selected_branch.name}`, style: "body" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 10, x2: 510, y2: 10, lineWidth: 1 },
          ],
        },

        {
          table: {
            headerRows: 1,
            widths: [30, "*", "*", "*", "*"],
            body: body,
          },
          style: "tableStyle",
        },
      ],
      defaultStyle: {
        font: "ArabicFont",
      },
      styles: {
        tableStyle: {
          width: "100%", // Set the width of the table to 100%
          marginTop: 20,
          font: "ArabicFont",
        },

        header: {
          fontSize: 22,
          bold: true,
          alignment: "center",
        },
        body: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          marginBottom: 10,
        },
      },
    };
    return documentDefinition;
  };

  const download = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).download("Expenses.pdf");
  };

  const print = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).print();
  };

  const trigger_loadagain = () => {
    setloadagain(!loadagain);
  };

  const handleSelect = (item) => {
    const get_date = item.selection;

    setdate_range([get_date]);
    setstart_date(get_date.startDate.toISOString().substring(0, 10));
    setend_date(get_date.endDate.toISOString().substring(0, 10));
    if (
      get_date.startDate.toISOString().substring(0, 10) !==
      get_date.endDate.toISOString().substring(0, 10)
    ) {
      setshow(!show);
    }
  };

  const handleselectiochange = (e) => {
    setshow(!show);
    setTarget(e.target);
  };

  return (
    <>
      {current_user?.permissions?.includes("view_expenses") ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("expenses")}
          </h1>
          <div className="card me-3">
            <div className="card-header bg-white  d-flex justify-content-between">
              <h3 style={{ fontSize: "1.3rem", fontWeight: "normal" }}>
                {t("expenseslist")}
              </h3>
              <div>
                <Button
                  type="button"
                  className="mb-2 me-2"
                  variant="outline-success"
                  onClick={() => setshowtypemodel(true)}
                >
                  <PaymentIcon /> {t("add_paymenttype")}
                </Button>
                <Button
                  type="button"
                  className="mb-2"
                  variant="outline-success"
                  onClick={() => setshowmodel(!showmodel)}
                >
                  <PaymentIcon /> {t("addexpenses")}
                </Button>
              </div>
            </div>

            <div className="card-body pt-0">
              <ToolkitProvider
                keyField="id"
                data={all_products}
                columns={columns}
                search
                exportCSV
              >
                {(props) => (
                  <div>
                    <div>
                      <div className="col-sm-4 mt-3 d-flex">
                        <div className="col-sm-6  me-3">
                          {date_range[0].endDate.getFullYear() -
                            date_range[0].startDate.getFullYear() ===
                          10 ? (
                            <TextField
                              ref={ref}
                              type="button"
                              className="form-control  mb-3"
                              label={t("date")}
                              value="From Start"
                              onClick={handleselectiochange}
                              size="small"
                            />
                          ) : (
                            <TextField
                              ref={ref}
                              type="button"
                              className="form-control  mb-3 "
                              label={t("date")}
                              value={`${date_range[0].startDate
                                .toLocaleString("en-GB")
                                .substring(0, 10)} - ${date_range[0].endDate
                                .toLocaleString("en-GB")
                                .substring(0, 10)}`}
                              onClick={handleselectiochange}
                              size="small"
                            />
                          )}
                          <Overlay
                            show={show}
                            target={target}
                            placement="bottom-start"
                            container={ref}
                          >
                            <Popover
                              id="popover-contained"
                              className="pop_over"
                            >
                              <Popover.Body>
                                <div>
                                  <DateRangePicker
                                    onChange={handleSelect}
                                    showSelectionPreview={true}
                                    showCalendarPreview={false}
                                    dragSelectionEnabled={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={date_range}
                                    direction="horizontal"
                                    preventSnapRefocus={true}
                                    calendarFocus="backwards"
                                    staticRanges={[
                                      ...defaultStaticRanges,
                                      {
                                        label: "Last Year",
                                        range: () => ({
                                          startDate: startOfYear(
                                            addYears(new Date(), -1)
                                          ),
                                          endDate: endOfYear(
                                            addYears(new Date(), -1)
                                          ),
                                        }),
                                        isSelected(range) {
                                          const definedRange = this.range();
                                          return (
                                            isSameDay(
                                              range.startDate,
                                              definedRange.startDate
                                            ) &&
                                            isSameDay(
                                              range.endDate,
                                              definedRange.endDate
                                            )
                                          );
                                        },
                                      },
                                      {
                                        label: "This Year",
                                        range: () => ({
                                          startDate: startOfYear(new Date()),
                                          endDate: endOfDay(new Date()),
                                        }),
                                        isSelected(range) {
                                          const definedRange = this.range();
                                          return (
                                            isSameDay(
                                              range.startDate,
                                              definedRange.startDate
                                            ) &&
                                            isSameDay(
                                              range.endDate,
                                              definedRange.endDate
                                            )
                                          );
                                        },
                                      },
                                      {
                                        label: "From Start",
                                        range: () => ({
                                          startDate: startOfYear(
                                            addYears(new Date(), -10)
                                          ),
                                          endDate: endOfDay(new Date()),
                                        }),
                                        isSelected(range) {
                                          const definedRange = this.range();
                                          return (
                                            isSameDay(
                                              range.startDate,
                                              definedRange.startDate
                                            ) &&
                                            isSameDay(
                                              range.endDate,
                                              definedRange.endDate
                                            )
                                          );
                                        },
                                      },
                                    ]}
                                  />
                                </div>
                              </Popover.Body>
                            </Popover>
                          </Overlay>
                        </div>
                        <div className="form-control  selector ">
                          <Select
                            options={alltypes}
                            placeholder={t("expensetype")}
                            value={type}
                            funct={(e) => {
                              settype(e);
                            }}
                          ></Select>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                          <ExportCSVButton
                            {...props.csvProps}
                            className="csvbutton  border bg-secondary text-light me-2"
                          >
                            Export CSV
                          </ExportCSVButton>
                          <Button
                            type="button"
                            className="p-1 ps-3 pe-3 me-2"
                            variant="outline-primary"
                            onClick={download}
                          >
                            <PictureAsPdfIcon /> PDF
                          </Button>
                          <Button
                            type="button"
                            className="p-1 ps-3 pe-3"
                            variant="outline-success"
                            onClick={print}
                          >
                            <PrintIcon /> Print
                          </Button>
                        </div>
                        <SearchBar {...props.searchProps} />
                      </div>
                    </div>
                    {isloading && (
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    )}

                    <hr />
                    <div style={{ zoom: ".9" }}>
                      <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        rowStyle={rowstyle}
                        striped
                        bootstrap4
                        condensed
                        wrapperClasses="table-responsive"
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>

          {showmodel && (
            <Expenseform
              show={showmodel}
              onHide={() => setshowmodel(false)}
              user={user}
              route={`${route}`}
              callback={dispatch}
              selected_branch={selected_branch}
              text="Customers"
              current_user={current_user}
              setloadagain={trigger_loadagain}
            />
          )}

          {showtypemodel && (
            <Typeform
              show={showtypemodel}
              onHide={() => setshowtypemodel(false)}
              user={user}
              route={`${route}`}
              selected_branch={selected_branch}
              typetriger={typetriger}
              settypetriger={settypetriger}
            />
          )}

          {showmodelupdate && (
            <Expenseupdate
              show={showmodelupdate}
              onHide={() => setshowmodelupdate(false)}
              data={data}
              user={user}
              route={`${route}`}
              fun={custom_toast}
              callback={dispatch}
              selected_branch={selected_branch}
              text="Customers"
              setloadagain={trigger_loadagain}
            />
          )}
          {delete_user && (
            <Alert_before_delete
              show={delete_user}
              onHide={() => setdelete_user(false)}
              url={url_to_delete}
              dis_fun={handleconfirm}
              row_id={row_id}
            />
          )}
          <ToastContainer
            autoClose={1000}
            hideProgressBar={true}
            theme="dark"
          />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}
