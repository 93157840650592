import React, { useState, useRef, useEffect } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Avatar } from "@material-ui/core";
import "../Pages/home.css";
import "./header.css";
import Dropdown from "react-bootstrap/Dropdown";
import jwtDecode from "jwt-decode";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "./ArabicFont";
import Userupdate from "./users/userupdateform";
import { ToastContainer } from "react-toastify";
import custom_toast from "./alerts/custom_toast";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["Arabic-Font.ttf"] = font;

function Header(props) {
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const dispatch = props.Setinfo_ofuser;
  const RemoveUser_fun = props.RemoveUser;
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const [data, setdata] = useState("");
  const [showmodelupdate, setshowmodelupdate] = useState(false);
  const ref = useRef(null);

  const decodedToken = jwtDecode(user.access);
  const userId = decodedToken.user_id;

  pdfMake.fonts = {
    ArabicFont: {
      normal: "Arabic-Font.ttf",
      bold: "Arabic-Font.ttf",
      italics: "Arabic-Font.ttf",
      bolditalics: "Arabic-Font.ttf",
    },
  };

  useEffect(() => {
    const getuser = async () => {
      const response = await fetch(
        `${route}/api/users/${userId}/assign-branches/`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();
      if (!response.ok) {
      }

      if (response.ok) {
        getpermission(json);

        if (!selected_branch && json.branch_list.length > 0) {
          getproject(json);
        } else if (selected_branch) {
          if (selected_branch.language === "English") {
            i18next.changeLanguage("en");
          } else if (selected_branch.language === "Arabic") {
            i18next.changeLanguage("ar");
          }
        }
      }
    };
    if (user) {
      getuser();
    }
  }, [user]);

  const getproject = async (input) => {
    var url = `${route}/api/branch/${input.branch_list[0].id}/`;

    const response = await fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.access}` },
    });
    const json = await response.json();
    if (!response.ok) {
    }

    if (response.ok) {
      localStorage.setItem("selected_branch", JSON.stringify(json));
      dispatch({ type: "Set_Branch_first", data: json });
      if (json.language === "English") {
        i18next.changeLanguage("en");
      } else if (json.language === "Arabic") {
        i18next.changeLanguage("ar");
      }
    }
  };

  const getpermission = async (input) => {
    var url = `${route}/api/user-permissions/${input.id}/`;

    const response = await fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.access}` },
    });
    const json = await response.json();
    if (!response.ok) {
    }

    if (response.ok) {
      dispatch({
        type: "SetCurrentUser",
        data: { ...input, permissions: json.permissions },
      });
    }
  };

  const handlestate = (event) => {
    setshow(!show);
    setTarget(event.target);
  };

  const handlecollapsefun = () => {
    props.collapsefun();
    props.statefun();
  };

  const handledropdown = async (e) => {
    const obj = current_user.branch_list.filter((item) => {
      return item.id === e.id;
    });
    const response = await fetch(`${route}/api/branch/${obj[0].id}/`, {
      headers: { Authorization: `Bearer ${user.access}` },
    });
    const json = await response.json();
    if (!response.ok) {
    }

    if (response.ok) {
      localStorage.setItem("selected_branch", JSON.stringify(json));
      dispatch({ type: "Set_Branch_first", data: json });
      if (json.language === "English") {
        i18next.changeLanguage("en");
      } else if (json.language === "Arabic") {
        i18next.changeLanguage("ar");
      }
    }
  };

  const handlesignout = (e) => {
    e.preventDefault();
    dispatch({ type: "Set_Branch_first", data: null });
    RemoveUser_fun();
  };

  const handleclick = (e) => {
    e.preventDefault();
    setdata(current_user);
    setshowmodelupdate(true);
    setshow(!show);
  };

  return (
    <div>
      <div className="d-flex border-bottom justify-content-between header">
        {props.broken ? (
          <IconButton onClick={() => props.togglefun()}>
            <MenuIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handlecollapsefun}>
            <MenuIcon />
          </IconButton>
        )}
        <div className="d-flex align-items-center  ">
          {selected_branch && <span>{selected_branch.name}</span>}
          <Dropdown className="me-3">
            <Dropdown.Toggle split variant="" id="dropdown-split-basic" />

            <Dropdown.Menu>
              {current_user &&
                current_user.branch_list?.map((item) => (
                  <Dropdown.Item onClick={() => handledropdown(item)}>
                    {item.name}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>

          <div
            ref={ref}
            onClick={handlestate}
            className="d-flex align-items-center header_right pb-1 me-3 ps-1"
          >
            <Avatar className="mt-1" />
            <h6 className="mt-2 me-5 ms-4">
              {current_user && current_user.username}
            </h6>
          </div>
        </div>

        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref}
          containerPadding={20}
        >
          <Popover
            style={{ width: "500px" }}
            id="popover-contained"
            className="pop_over"
          >
            <Popover.Header className="bg-primary pop_over_header">
              <Avatar style={{ width: "50px", height: "50px" }} />
              <p className="mt-2 text-white">
                {current_user && current_user.username}
              </p>
            </Popover.Header>
            <Popover.Body>
              <div className="row">
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    style={{ width: "180px" }}
                    className="border me-3"
                    onClick={handleclick}
                  >
                    {t("profile")}
                  </button>

                  <button
                    type="button"
                    className="border"
                    style={{ width: "180px" }}
                    onClick={handlesignout}
                  >
                    {t("signout")}
                  </button>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
      {showmodelupdate && (
        <Userupdate
          show={showmodelupdate}
          onHide={() => setshowmodelupdate(false)}
          data={data}
          user={user}
          route={route}
          fun={custom_toast}
          callback={dispatch}
        />
      )}
      <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Header;
