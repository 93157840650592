import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import numberToWords from "number-to-words";
import "./sale_invoice.css";
import { toArabicWord } from "number-to-arabic-words/dist/index-node.js";

function Invoice_06(props) {
  const { name } = useParams();
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("selected_branch"))
  );
  const response = JSON.parse(localStorage.getItem("data"));
  const [data, setdata] = useState(response);
  var quantity = 0;
  const optimize = response.details.map((item, index) => {
    item["sr"] = index + 1;
    if (name === "delivery_notes") {
      quantity = quantity + item.quantity;
    }
    return item;
  });
  if (name === "delivery_notes") {
    optimize.push({
      sr: "",
      id: "",
      product_name: "",
      product_code: "",
      quantity: `Total : ${quantity}`,
      delivery_note: "",
      product: "",
    });
  }

  const [details, setdetails] = useState(optimize);

  const name_column_formater = (cell, row) => {
    return <div style={{ width: "35vw" }}>{cell}</div>;
  };

  const centerformatter = (cell, row) => {
    return <div className="text-center">{cell}</div>;
  };
  const headerstyle = (column, colIndex) => {
    const englishHeader = column.text.english;
    const arabicHeader = column.text.arabic;
    if (englishHeader === "Description") {
      return (
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ backgroundColor: "rgb(196, 193, 193)" }}
        >
          <div>{englishHeader}</div>
          <div>{arabicHeader}</div>
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: "rgb(196, 193, 193)" }}>
        <div>{englishHeader}</div>
        <div>{arabicHeader}</div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "sr",
      text: { english: "SR", arabic: "رقم" },
      headerFormatter: headerstyle,
    },

    {
      dataField: "product_name",
      text: { english: "Description", arabic: "خدمات" },
      formatter: name_column_formater,
      headerFormatter: headerstyle,
    },
    {
      dataField: "price",
      text: { english: "Amount", arabic: "الببلغ" },
      headerFormatter: headerstyle,
      formatter: centerformatter,
    },
    {
      dataField: "vat_percentage",
      text: { english: "VAT %", arabic: " قيمة المضافة" },
      headerFormatter: headerstyle,
      formatter: centerformatter,
    },
    {
      dataField: "vat_amount",
      text: { english: "VAT Amount", arabic: `  قيمة الضريبة` },
      headerFormatter: headerstyle,
      formatter: centerformatter,
    },
    {
      dataField: "total",
      text: { english: "Total", arabic: `مجموع` },
      headerFormatter: headerstyle,
      formatter: centerformatter,
    },
  ];

  const columns_1 = [
    {
      dataField: "sr",
      text: { english: "SR", arabic: "رقم" },
      headerFormatter: headerstyle,
    },

    {
      dataField: "product_name",
      text: { english: "Description", arabic: "خدمات" },
      formatter: name_column_formater,
      headerFormatter: headerstyle,
    },
    {
      dataField: "quantity",
      text: { english: "QTY", arabic: "الكمية" },
      headerFormatter: headerstyle,
    },
  ];

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  return (
    <div className=" w-100 " ref={componentRef}>
      {data && (
        <div className="container_1 p-4">
          <div className="row ">
            <div className="col-6 ps-5 text-start">
              <img
                src={company.logo}
                className="ps-3"
                alt="logo"
                width={"200"}
                height={"100"}
              ></img>
            </div>

            <div className="col-6  text-start">
              <h6 className="m-0" style={{ fontSize: 18 }}>
                <strong>{company.arabic_name}</strong>
              </h6>
              <h6 className="m-0" style={{ fontSize: 18 }}>
                <strong>{company.name}</strong>
              </h6>

              <p className="m-0" style={{ fontSize: 16 }}>
                {company.address}
              </p>
            </div>
          </div>

          {name === "sales" && (
            <div className="d-flex justify-content-between mt-1 p-2 ms-1 me-1">
              <div className=" text-start">
                <p style={{ fontSize: 16 }} className="m-0">
                  To: {data.customer_info.name}
                </p>
                <p style={{ fontSize: 16 }} className="m-0">
                  Address: {data.customer_info.address}
                </p>
                <br />

                <p style={{ fontSize: 16 }} className="m-0">
                  Tel: {data.customer_info.contact}
                </p>
                <p style={{ fontSize: 16 }} className="m-0">
                  Client VAT ID: {data.customer_info.vat_number}
                </p>
              </div>
              <div className=" text-end">
                <p className="m-0" style={{ fontWeight: "bold", fontSize: 30 }}>
                  INVOICE
                </p>

                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Invoice No : </span>{" "}
                  <span>{data.invoice.split("-")[1]}</span>
                </p>
                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Invoice Date :</span> <span>{data.date}</span>
                </p>
                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Sale Method : </span>
                  <span>{data.payment_type} </span>
                </p>
                <p style={{ fontSize: 20 }} className="m-0">
                  <span>VAT ID : </span> <span>{company.vat_number} </span>
                </p>
              </div>
            </div>
          )}

          {name === "purchases" && (
            <div className="d-flex justify-content-between mt-1 p-2 ms-1 me-1">
              <div className=" text-start">
                <h6 className="m-0">Supplier Details : تفاصيل العميل</h6>
                <p className="m-0">{data.supplier_name}</p>
              </div>
              <div className=" text-end">
                <p className="m-0" style={{ fontWeight: "bold", fontSize: 30 }}>
                  INVOICE
                </p>

                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Invoice No : </span>{" "}
                  <span>{data.invoice.split("-")[1]}</span>
                </p>
                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Invoice Date :</span> <span>{data.date}</span>
                </p>
                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Sale Method : </span>
                  <span>{data.payment_type} </span>
                </p>
                <p style={{ fontSize: 20 }} className="m-0">
                  <span>VAT ID : </span> <span>{company.vat_number} </span>
                </p>
              </div>
            </div>
          )}

          {name === "quotation" && (
            <div className="d-flex justify-content-between mt-1 p-2 ms-1 me-1">
              <div className=" text-start">
                <p style={{ fontSize: 16 }} className="m-0">
                  To: {data.customer_info.name}
                </p>
                <p style={{ fontSize: 16 }} className="m-0">
                  Address: {data.customer_info.address}
                </p>
                <br />

                <p style={{ fontSize: 16 }} className="m-0">
                  Tel: {data.customer_info.contact}
                </p>
                <p style={{ fontSize: 16 }} className="m-0">
                  Client VAT ID: {data.customer_info.vat_number}
                </p>
              </div>
              <div className=" text-end">
                <p className="m-0" style={{ fontWeight: "bold", fontSize: 30 }}>
                  INVOICE
                </p>

                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Invoice No : </span>{" "}
                  <span>{data.invoice.split("-")[1]}</span>
                </p>
                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Invoice Date :</span> <span>{data.date}</span>
                </p>
                <p style={{ fontSize: 20 }} className=" m-0">
                  <span>Sale Method : </span>
                  <span>{data.payment_type} </span>
                </p>
                <p style={{ fontSize: 20 }} className="m-0">
                  <span>VAT ID : </span> <span>{company.vat_number} </span>
                </p>
              </div>
            </div>
          )}

          {name === "sale_order" && (
            <div
              className="d-flex justify-content-between mt-1 p-2 ms-1 me-1"
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              <div className=" text-left">
                <h6 className="m-0">Customer Details : تفاصيل العميل</h6>
                <p className="m-0">{data.customer_info.name}</p>
                <p className="m-0">VAT No: {data.customer_info.vat_number}</p>
                <p className="m-0">Contact: {data.customer_info.contact}</p>
                <p className="m-0">Address: {data.customer_info.address}</p>
              </div>
              <div className=" text-left">
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">order no:</span>
                  <span className="me-3">{data.order_number}</span>
                  <span> : رقم الأمر</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Order Date:</span>
                  <span className="me-3">{data.date}</span>
                  <span> : تاريخ الطلب</span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Delivery Date:</span>
                  <span className="me-3">{data.delivery_date} </span>
                  <span> : تاريخ التسليم </span>
                </p>
                <p className="d-flex justify-content-between m-0">
                  <span className="me-3">Payment Terms:</span>
                  <span className="me-3">{data.payment_terms} </span>
                  <span> :شروط الدفع </span>
                </p>
              </div>
            </div>
          )}

          {name === "sales" && (
            <h5
              className="text-center mt-2 ms-1 me-1"
              style={{ backgroundColor: "rgb(196, 193, 193)" }}
            >
              TAX INVOICE / فاتورة ضريبية
            </h5>
          )}
          {name === "purchases" && (
            <h5
              className="text-center mt-4 ms-1 me-1"
              style={{ backgroundColor: "rgb(196, 193, 193)" }}
            >
              Purchase / الشراء
            </h5>
          )}
          {name === "quotation" && (
            <h5
              className="text-center mt-4 ms-1 me-1"
              style={{ backgroundColor: "rgb(196, 193, 193)" }}
            >
              Quotation / عرض السعر
            </h5>
          )}
          {name === "sale_order" && (
            <h5
              className="text-center mt-4 ms-1 me-1"
              style={{ backgroundColor: "rgb(196, 193, 193)" }}
            >
              Sale Order / امر البيع
            </h5>
          )}
          {name === "delivery_notes" && (
            <h4
              className=" m-2 text-center"
              style={{ backgroundColor: "rgb(196, 193, 193)" }}
            >
              Delivery Notes - مذكرات التسليم
            </h4>
          )}

          {name !== "delivery_notes" ? (
            <div className="m-1 mt-4 " style={{ minHeight: "5in" }}>
              <BootstrapTable
                keyField="id"
                data={details}
                columns={columns}
                bordered={true}
                bootstrap4
                condensed
                classes="table_class_06"
              />
            </div>
          ) : (
            <div className="m-1 mt-4 " style={{ minHeight: "5in" }}>
              <BootstrapTable
                keyField="id"
                data={details}
                columns={columns_1}
                bordered={true}
                bootstrap4
                condensed
                classes="table_class_06"
              />
            </div>
          )}

          {name !== "delivery_notes" && (
            <div className="mt-1 mb-4 d-flex justify-content-end">
              <div
                style={{ backgroundColor: "rgb(216, 211, 211)" }}
                className="col-6 "
              >
                <div className="d-flex justify-content-between  firstcolumn">
                  <p className="m-0 col-4 ps-2">Taxable Amount </p>
                  <p className="m-0 col-4 "> المبلغ خاضع للضريبة</p>
                  <p className="m-0 col-4 ps-1 pe-2 thirdcolumn">
                    {data.sub_total}
                  </p>
                </div>

                <div className="d-flex justify-content-between  firstcolumn">
                  <p className="m-0 col-4 ps-2">VAT Amount</p>
                  <p className="m-0 col-4 "> قيمة الضريبة المضافة</p>
                  <p className="m-0 col-4 ps-1 pe-2 thirdcolumn">
                    {data.vat_amount}
                  </p>
                </div>

                <div className="d-flex justify-content-between firstcolumn">
                  <p className="m-0 col-4 ps-2">Total Amount</p>
                  <p className="m-0 col-4"> الاجمالي المبلغ فاتورة</p>
                  <p className="m-0 col-4 ps-1 pe-2 thirdcolumn">
                    {data.total}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="mt-4 d-flex justify-content-between">
            <div className="col-10 pe-2">
              <p className="m-0 ptag">Bank Name: SNB البنك الأهلي السعودي</p>
              <p className="m-0 ptag">
                Account Name (in Arabic): مؤسسة نوير فواز غالب السبيعي للمقاولات
                العامة
              </p>
              <p className="m-0 ptag">
                Account Name (in English): Nawir Fawaz Ghaleb Al Subaie General
                Contracting Est
              </p>
              <p className="m-0 ptag">Account No: 01400022999407</p>
              <p className="m-0 ptag">Swift Code:</p>
              <p className="m-0 ptag">IBAN: SA0910000001400022999407</p>
            </div>

            <div className="p-2 col-2 text-center ">
              <div>
                <QRCode
                  value={data.qr_code}
                  bgColor={"#FFFFFF"}
                  fgColor={"#000000"}
                  size={100}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Invoice_06;
