import React, { useState, useEffect } from "react";
import "./branchmanagement.css";
import Select from "react-select";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer } from "react-toastify";

import Update_button from "../buttons/update_button";
import success_toast from "../alerts/success_toast";
import Red_toast from "../alerts/red_toast";
import Groupform from "./Groupform";
import { useTranslation } from "react-i18next";

export default function Branch_manage(props) {
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const current_user = props.state.Setcurrentinfo.current_user;
  const dispatch = props.Settable_history;
  const [selected_user, setselected_user] = useState({ id: "", username: "" });
  const [selected, setSelected] = useState([]);
  const [allbranches, setallbranches] = useState([]);
  const [showmodel, setshowmodel] = useState(false);
  const [all_users, setall_users] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [status, setstatus] = useState(false);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "company_management" });
    const fetchallbraches = async () => {
      const response = await fetch(
        `${route}/api/user-branches/?user_id=${current_user?.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();
      if (response.ok) {
        setallbranches(json);
      }
    };

    if (user) {
      fetchallbraches();
    }
  }, [current_user]);

  useEffect(() => {
    const fetchalluser = async () => {
      const response = await fetch(
        `${route}/api/subusers-branches/?user_id=${current_user?.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setall_users(json);
      }
    };

    if (user) {
      fetchalluser();
    }
  }, [current_user, status]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setisloading(true);

    const response = await fetch(
      `${route}/api/users/${selected_user.id}/assign-branches/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify({
          branch_list: selected,
        }),
      }
    );
    const json = await response.json();

    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }

    if (response.ok) {
      setisloading(false);
      success_toast();
      setstatus(!status);
    }
  };

  const handleselect = (selected_option) => {
    setselected_user({ id: selected_option.value, username: selected_option });
    var user_braches = all_users.filter((item) => {
      if (item.id === selected_option.value) {
        return item;
      }
    });
    user_braches = user_braches[0].branch_list;

    user_braches = user_braches.map((item) => {
      return item;
    });
    setSelected(user_braches);
  };

  const userlist = all_users.map((item) => {
    return {
      value: item.id,
      label: item.username,
    };
  });

  const branchlist = allbranches.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const handelboxselection = (value) => {
    setSelected(value);
  };

  return (
    <>
      {current_user?.profile?.user_type !== "user" ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("side_bar_companymanagement")}
          </h1>
          <div className="card">
            <div className="card-header bg-white  d-flex justify-content-between">
              <h3>{t("side_bar_company")} </h3>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <h5>{t("users")} </h5>
                  </label>
                  <Select
                    className="form-control selector"
                    options={userlist}
                    value={selected_user.username}
                    onChange={handleselect}
                  ></Select>
                </div>
              </div>

              <form onSubmit={handlesubmit}>
                <div className="row mt-5">
                  <label>
                    <h5>{t("companies")}</h5>
                  </label>
                  <DualListBox
                    options={branchlist}
                    selected={selected}
                    onChange={handelboxselection}
                    optionLabel="newLabel"
                    icons={{
                      moveLeft: <FontAwesomeIcon icon={faAngleLeft} />,
                      moveAllLeft: <FontAwesomeIcon icon={faAnglesLeft} />,
                      moveRight: (
                        <FontAwesomeIcon icon={faAngleLeft} rotation={180} />
                      ),
                      moveAllRight: (
                        <FontAwesomeIcon icon={faAnglesLeft} rotation={180} />
                      ),
                    }}
                    canFilter
                    showHeaderLabels
                  ></DualListBox>

                  <hr className="mt-5" />
                  <div className="d-flex justify-content-end">
                    <Update_button isloading={isloading} />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer
            autoClose={2000}
            hideProgressBar={true}
            theme="dark"
          />
          {showmodel && (
            <Groupform show={showmodel} onHide={() => setshowmodel(false)} />
          )}
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}
