import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "./product.css";
import Select from "../alerts/select";
import { IconButton, Avatar } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer } from "react-toastify";
import custom_toast from "../alerts/custom_toast";
import Red_toast from "../alerts/red_toast";
import Spinner from "react-bootstrap/Spinner";
import Alert_before_delete from "../../Container/alertContainer";
import Productform from "./productform";
import Productupdate from "./updateproductform";
import ApartmentIcon from "@material-ui/icons/Apartment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";

export default function Product(props) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const all_products = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [showmodel, setshowmodel] = useState(false);
  const [data, setdata] = useState("");
  const [showmodelupdate, setshowmodelupdate] = useState(false);

  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");
  const [isloading, setisloading] = useState(false);

  const [category, setcategory] = useState({ value: "all", label: "All" });
  const [allcategory, setallcategory] = useState([]);
  const [subcategory, setsubcategory] = useState({
    value: "all",
    label: "All",
  });
  const [allsubcategory, setallsubcategory] = useState([]);

  const [callagain, setcallagain] = useState(false);

  useEffect(() => {
    setisloading(true);
    dispatch({ type: "Set_menuitem", data: "products" });
    const fetchWorkouts = async () => {
      var url = `${route}/api/products/?branch_id=${selected_branch.id}`;
      if (category.value != "all") {
        url = `${url}&category_id=${category.value}`;
      }
      if (subcategory.value != "all") {
        url = `${url}&subcategory_id=${subcategory.value}`;
      }
      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        dispatch({ type: "Set_table_history", data: json });
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
        setisloading(false);
      }
    };

    if (user) {
      fetchWorkouts();
    }
  }, [selected_branch, callagain]);

  useEffect(() => {
    const fetchCategory = async () => {
      const response = await fetch(
        `${route}/api/categories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        optimize.splice(0, 0, { value: "all", label: "All" });
        setallcategory(optimize);
      }
    };

    const fetchsubcategory = async () => {
      const response = await fetch(
        `${route}/api/subcategories/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        optimize.splice(0, 0, { value: "all", label: "All" });
        setallsubcategory(optimize);
      }
    };

    if (user) {
      fetchCategory();
      fetchsubcategory();
    }
  }, [selected_branch]);

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const handleconfirm = (row) => {
    dispatch({ type: "Delete_table_history", data: { id: row } });
    custom_toast("Delete");
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex">
        <IconButton
          className="border border-danger rounded me-2"
          onClick={() => {
            setrow_id(row.id);
            seturl_to_delete(`${route}/api/products/${row.id}/`);
            setdelete_user(true);
          }}
        >
          <DeleteRoundedIcon className="m-1" color="error" fontSize="medium" />
        </IconButton>

        <IconButton
          className=""
          style={{ border: "1px solid #003049", borderRadius: "5px" }}
          onClick={() => {
            setdata(row);
            setshowmodelupdate(true);
          }}
        >
          <EditOutlinedIcon
            className="m-1"
            style={{ color: "#003049" }}
            fontSize="medium"
          />
        </IconButton>
      </span>
    );
  };

  const loadimage = (cell, row, rowIndex, formatExtraData) => {
    console.log(cell);
    return <Avatar src={cell} alt="logo" />;
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      headerFormatter: headerstyle,
      csvExport: false,
    },
    {
      dataField: "picture",
      text: t("pic"),
      formatter: loadimage,
      headerFormatter: headerstyle,
      csvExport: false,
    },
    {
      dataField: "category_name",
      text: t("category"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "sub_category_name",
      text: t("subcategory"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "name",
      text: t("name"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "code",
      text: t("code"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "barcode",
      text: t("barcode"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "quantity",
      text: t("quantity"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "price",
      text: t("price"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "action",
      text: t("action"),
      formatter: Action,
      headerFormatter: headerstyle,
      csvExport: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ms-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: all_products.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const makepdf = () => {
    const body = all_products.map((item, index) => {
      return [
        index + 1,
        item.category_name,
        item.sub_category_name,
        item.name,
        item.code,
        item.barcode,
        item.quantity,
        item.price,
      ];
    });
    body.splice(0, 0, [
      "#",
      "Category",
      "Sub Category",
      "Name",
      "Code",
      "Barcode",
      "Qty.",
      "Price",
    ]);

    const documentDefinition = {
      content: [
        { text: "Products", style: "header" },
        { text: `Project Name: ${selected_branch.name}`, style: "body" },
        {
          canvas: [
            { type: "line", x1: 0, y1: 10, x2: 760, y2: 10, lineWidth: 1 },
          ],
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [30, "*", "*", "*", "*", "*", "*", "*"],
            body: body,
          },
          style: "tableStyle",
        },
      ],

      defaultStyle: {
        font: "ArabicFont",
      },
      styles: {
        tableStyle: {
          width: "100%", // Set the width of the table to 100%
          marginTop: 20,
          font: "ArabicFont",
        },

        header: {
          fontSize: 22,
          bold: true,
          alignment: "center",
        },
        body: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          marginBottom: 10,
        },
      },

      pageOrientation: "landscape",
    };
    return documentDefinition;
  };

  const download = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).download("Products.pdf");
  };

  const print = () => {
    const documentDefinition = makepdf();
    pdfMake.createPdf(documentDefinition).print();
  };

  const rowstyle = { height: "10px" };

  useEffect(() => {
    setcallagain(!callagain);
  }, [category, subcategory]);

  const handlecategory = (e) => {
    setcategory(e);
  };

  const handlesubcategory = (e) => {
    setsubcategory(e);
  };
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const rowClasses = (row, rowIndex) => {
    let classes = null;
    if (row.quantity === 0) {
      classes = "low_quantity";
    }

    return classes;
  };

  return (
    <>
      {current_user?.permissions?.includes("view_products") ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("side_bar_product")}
          </h1>
          <div className="card">
            <div className="card-header bg-white d-flex justify-content-between">
              <h1
                className="mb-3"
                style={{ fontSize: "1.3rem", fontWeight: "normal" }}
              >
                {t("product_list")}
              </h1>
              <div>
                <Button
                  type="button"
                  className="mb-2"
                  variant="outline-success"
                  onClick={() => setshowmodel(!showmodel)}
                >
                  <ApartmentIcon />
                  {t("add_product")}
                </Button>
              </div>
            </div>

            <div className="card-body pt-0">
              <ToolkitProvider
                keyField="id"
                data={all_products}
                columns={columns}
                search
                exportCSV
              >
                {(props) => (
                  <div>
                    <div className=" col-sm-6 d-flex mt-3">
                      <div className=" me-3 form-control  selector mb-2 ">
                        <Select
                          options={allcategory}
                          placeholder="Categories"
                          value={category}
                          funct={handlecategory}
                        ></Select>
                      </div>
                      <div className="me-3 form-control  selector mb-2">
                        <Select
                          options={allsubcategory}
                          placeholder="Sub Catgories"
                          value={subcategory}
                          funct={handlesubcategory}
                        ></Select>
                      </div>
                    </div>

                    <div className="d-sm-flex justify-content-between align-items-end">
                      <div>
                        <ExportCSVButton
                          {...props.csvProps}
                          className="csvbutton  border bg-secondary text-light me-2 mb-2"
                        >
                          Export CSV
                        </ExportCSVButton>
                        <Button
                          type="button"
                          className="p-1 ps-3 pe-3 me-2 mb-2"
                          variant="outline-primary"
                          onClick={download}
                        >
                          <PictureAsPdfIcon /> PDF
                        </Button>
                        <Button
                          type="button"
                          className="p-1 ps-3 pe-3 mb-2"
                          variant="outline-success"
                          onClick={print}
                        >
                          <PrintIcon /> Print
                        </Button>
                      </div>
                      <SearchBar {...props.searchProps} />
                    </div>

                    {isloading && (
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    )}

                    <hr />
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      rowStyle={rowstyle}
                      striped
                      bootstrap4
                      condensed
                      rowClasses={rowClasses}
                      wrapperClasses="table-responsive"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>

          {showmodel && (
            <Productform
              show={showmodel}
              onHide={() => setshowmodel(false)}
              user={user}
              route={route}
              callback={dispatch}
              selected_branch={selected_branch}
              check="false"
            />
          )}
          {showmodelupdate && (
            <Productupdate
              show={showmodelupdate}
              onHide={() => setshowmodelupdate(false)}
              data={data}
              user={user}
              route={route}
              fun={custom_toast}
              callback={dispatch}
              selected_branch={selected_branch}
            />
          )}
          {delete_user && (
            <Alert_before_delete
              show={delete_user}
              onHide={() => setdelete_user(false)}
              url={url_to_delete}
              dis_fun={handleconfirm}
              row_id={row_id}
            />
          )}
          <ToastContainer
            autoClose={1000}
            hideProgressBar={true}
            theme="dark"
          />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}
