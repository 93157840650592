import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import "./report.css";
import { useReactToPrint } from "react-to-print";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer } from "react-toastify";
import Red_toast from "../alerts/red_toast";
import Spinner from "react-bootstrap/Spinner";
import Select from "../alerts/select";
import { useTranslation } from "react-i18next";

export default function Balance_Report(props) {
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const { t } = useTranslation();
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const [show_company, setshow_company] = useState(false);
  const dispatch = props.Settable_history;
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const [details, setdetails] = useState([]);

  const [allcity, setallcity] = useState([]);
  const [city, setcity] = useState({
    value: "all",
    label: "All",
  });
  const [report_type, setreport_type] = useState({
    value: "customer",
    label: "Customer Balance",
  });

  const [isloading, setisloading] = useState(false);
  const [showreport, setshowreport] = useState(false);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "balance_report" });
    const fetchCity = async () => {
      const response = await fetch(
        `${route}/api/city/?branch_id=${selected_branch.id}`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const make_option = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        make_option.splice(0, 0, { value: "all", label: "All" });
        setallcity(make_option);
      }
      if (!response.ok) {
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }
    };

    if (user) {
      fetchCity();
    }
  }, [selected_branch]);

  const headerstyle = (column, colIndex) => {
    const englishHeader = column.text.english;
    const arabicHeader = column.text.arabic;

    return (
      <div>
        <div>{englishHeader}</div>
        <div>{arabicHeader}</div>
      </div>
    );
  };

  const fix_formatter = (cell, row) => {
    return <div>{parseFloat(cell).toFixed(2)}</div>;
  };

  const footerFormatter = (column, colIndex, { text }) => {
    if (text !== "Total") {
      return (
        <span style={{ fontSize: "large" }}>{Number(text).toFixed(2)}</span>
      );
    } else {
      return <span style={{ fontSize: "large" }}>{text}</span>;
    }
  };

  const columns = [
    {
      dataField: "party__name",
      text: { english: "Party", arabic: "  حزب" },
      headerFormatter: headerstyle,
      footer: "Total",
    },
    {
      dataField: "balance",
      text: { english: "Balance", arabic: "كمية" },
      headerFormatter: headerstyle,
      formatter: fix_formatter,
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      footerFormatter: footerFormatter,
    },
  ];

  const rowstyle = { height: "10px" };

  const componentRef = useRef();

  useEffect(() => {
    if (show_company) {
      handleprint();
    }
  }, [show_company]);

  const print = (e) => {
    e.preventDefault();
    setshow_company(true);
  };

  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print_class",

    onAfterPrint: () => {
      setshow_company(false);
    },
  });

  const handlegenerate = async (e) => {
    e.preventDefault();
    setisloading(true);
    setshowreport(true);
    if (report_type.value === "customer") {
      var url = `${route}/api/party-balance-report/?branch_id=${selected_branch.id}&party_type=Customer`;
      if (city.value !== "all") {
        url = `${url}&city_id=${city.value}`;
      }
    } else if (report_type.value === "supplier") {
      var url = `${route}/api/party-balance-report/?branch_id=${selected_branch.id}&party_type=Supplier`;
      if (city.value !== "all") {
        url = `${url}&city_id=${city.value}`;
      }
    } else if (report_type.value === "saleman") {
      var url = `${route}/api/sale-person-balance-report/?branch_id=${selected_branch.id}`;
    }

    const response = await fetch(`${url}`, {
      headers: { Authorization: `Bearer ${user.access}` },
    });
    const json = await response.json();

    if (response.ok) {
      setdetails(json);
      setisloading(false);
    }
    if (!response.ok) {
      setisloading(false);
      var error = Object.keys(json);
      if (error.length > 0) {
        Red_toast(`${json[error[0]]}`);
      }
    }
  };
  const changedate = (date) => {
    const date_list = date.split("-");
    return `${date_list[2]}/${date_list[1]}/${date_list[0]}`;
  };

  return (
    <>
      {current_user?.permissions?.includes("view_summary_report") ? (
        <div className="p-3">
          <h1
            className="mb-3"
            style={{ fontSize: "1.8rem", fontWeight: "normal" }}
          >
            {t("balance")}
          </h1>
          <div className="card">
            <div className="card-body pt-0">
              <ToolkitProvider
                keyField="party_name"
                data={details}
                columns={columns}
                search
                exportCSV
              >
                {(props) => (
                  <div>
                    <div className=" col-sm-12 d-sm-flex justify-content-start align-items-start mt-3">
                      <div className=" me-3 col-sm-2   selector  mb-3">
                        <Select
                          options={[
                            { value: "customer", label: "Customer Balance" },
                            { value: "supplier", label: "Supplier Balance" },
                            { value: "saleman", label: "Saleman Balance" },
                          ]}
                          placeholder={t("report_type")}
                          value={report_type}
                          funct={(e) => {
                            setreport_type(e);
                          }}
                        ></Select>
                      </div>

                      {(report_type.value === "supplier" ||
                        report_type.value === "customer") && (
                        <div className="me-3 col-sm-2   selector  mb-3">
                          <Select
                            options={allcity}
                            placeholder={t("city")}
                            value={city}
                            funct={(e) => {
                              setcity(e);
                            }}
                          ></Select>
                        </div>
                      )}

                      <Button
                        type="button"
                        className="mb-3 me-3"
                        variant="outline-success"
                        size="small"
                        onClick={handlegenerate}
                      >
                        {t("generate")}
                      </Button>
                    </div>
                    {isloading && (
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    )}

                    {showreport && (
                      <>
                        <div className="d-sm-flex justify-content-between align-items-center mt-2">
                          <div>
                            <ExportCSVButton
                              {...props.csvProps}
                              className="csvbutton me-3 border bg-secondary text-light"
                            >
                              Export CSV
                            </ExportCSVButton>
                            <Button
                              type="button"
                              className="mt-3 mb-3 me-3"
                              variant="outline-success"
                              size="small"
                              onClick={print}
                            >
                              {t("print")}
                            </Button>
                          </div>
                          <SearchBar {...props.searchProps} />
                        </div>

                        <hr />
                        <div ref={componentRef}>
                          {show_company && (
                            <>
                              <p className="m-0">
                                Date:{" "}
                                {changedate(
                                  new Date().toISOString().substring(0, 10)
                                )}
                              </p>
                              <div className="text-center">
                                <strong className="mb-3">
                                  {report_type.label} Report
                                </strong>
                              </div>
                              <h6 className="d-flex justify-content-between mb-3">
                                <p className="m-0">
                                  <strong className="me-3">Branch: </strong>
                                  {selected_branch.name}
                                </p>

                                {(report_type.value === "customer" ||
                                  report_type.value === "supplier") && (
                                  <p className="m-0">
                                    <strong className="me-3">City: </strong>
                                    {city.label}
                                  </p>
                                )}
                              </h6>
                            </>
                          )}

                          <div style={{ zoom: ".8" }}>
                            <BootstrapTable
                              {...props.baseProps}
                              rowStyle={rowstyle}
                              striped
                              bootstrap4
                              condensed
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>

          <ToastContainer
            autoClose={1000}
            hideProgressBar={true}
            theme="dark"
          />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: "bold",
            opacity: "0.7",
          }}
        >
          No Page Found
        </div>
      )}
    </>
  );
}
