import React, { useState } from "react";

import Select from "../alerts/select";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import Red_toast from "../alerts/red_toast";
import ApartmentIcon from "@material-ui/icons/Apartment";

import Update_button from "../buttons/update_button";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

function UpdatePaymentform({
  show,
  onHide,
  data,
  user,
  route,
  fun,
  callback,
  selected_branch,
  customers,
  text,
  setloadagain,
}) {
  const { t } = useTranslation();
  const [party, setparty] = useState({
    value: data.party,
    label: data.party_name,
  });
  const [amount, setamount] = useState(data.amount);
  const [description, setdescription] = useState(data.description);
  const [type, settype] = useState({
    value: data.payment_type,
    label: data.payment_type,
  });
  const [isloading, setisloading] = useState(false);
  const [date, setdate] = useState(data.date);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selected_branch && !isloading) {
      setisloading(true);
      const formData = new FormData();

      formData.append("description", description);
      formData.append("amount", amount);
      formData.append("payment_type", type.value);
      formData.append("party", party.value);
      formData.append("date", date);

      const response = await fetch(`${route}${data.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${user.access}`,
        },
        body: formData,
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        var error = Object.keys(json);
        if (error.length > 0) {
          Red_toast(`${json[error[0]]}`);
        }
      }

      if (response.ok) {
        setisloading(false);
        callback({ type: "Update_table_history", data: json });
        onHide();
        fun("Update");
        setloadagain();
      }
    }
  };

  const type_option = [
    { value: "debit", label: "Debit" },
    { value: "credit", label: "Credit" },
  ];

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        >
          <ApartmentIcon className="me-2" />
          {t("edit_payment")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <Select
                options={customers}
                placeholder={text}
                value={party}
                funct={(e) => {
                  setparty(e);
                }}
                required={true}
              ></Select>

              <TextField
                type="number"
                className="form-control  mb-3"
                id="outlined-basic"
                label={t("amount")}
                value={amount}
                onChange={(e) => setamount(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type="date"
                className="form-control   mb-3"
                id="outlined-basic"
                label={t("date")}
                value={date}
                onChange={(e) => setdate(e.target.value)}
                size="small"
              />
              <Select
                options={type_option}
                placeholder={t("type")}
                value={type}
                funct={(e) => {
                  settype(e);
                }}
              ></Select>
            </div>
          </div>
          <div className="mb-3">
            <TextField
              multiline
              className="form-control   mb-3"
              id="outlined-basic"
              label={t("description")}
              value={description}
              onChange={(e) => setdescription(e.target.value)}
              size="small"
              required
            />
          </div>

          <hr></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Update_button isloading={isloading} />
          </div>
        </form>
        <ToastContainer autoClose={5000} hideProgressBar={true} theme="dark" />
      </Modal.Body>
    </Modal>
  );
}

export default UpdatePaymentform;
